import React, {useEffect, useRef, useState} from 'react';
import {GoogleApiWrapper} from 'google-maps-react';
import LocationPicker from "location-picker";
import Icofont from "react-icofont";
import GooglePlacesAutocomplete, {geocodeByAddress, getLatLng, geocodeByLatLng} from 'react-google-places-autocomplete';

const MapContainer =({ onChange = () => {}, onChangeAddress = () => {}, initLocation, height, id, disabled = false, showSearch = false}) => {
  const lp = useRef();
  const [value, setValue] = useState(null);
  const locatRef = useRef({});
  useEffect(() => {
    if(initLocation){
      const locat = { lat: Number(initLocation.lat), lng: Number(initLocation.lng) }
      geocodeByLatLng(locat)
          .then(results => {
            setValue({
              value: results[0],
              label: results[0].formatted_address,
              ...locat
            })
            onChangeAddress(results[0].formatted_address);
          })
          .catch(error => console.error(error));
    }

    if(!initLocation){
     setTimeout(() => {
       lp.current.setCurrentPosition()
     }, 500)
    }
  }, [initLocation]);

  useEffect(() => {
    let options = {
      zoom: 15,
      zoomControl: true,
      disableDefaultUI: true,
    }

    if(initLocation){
      options = {
        ...options,
        center: {
          lat: Number(initLocation.lat),
          lng: Number(initLocation.lng),
        },
      }
    }

    if(disabled){
      options = {
        ...options,
        draggable: false,
        zoomControl: false,
        scrollwheel: false,
        disableDoubleClickZoom: true,
      }
    }

    if(disabled){
      document.getElementById(id).addEventListener(
          'click',
          (e) => {
            e.stopPropagation();
          },
          true
      );
    }

    lp.current = new LocationPicker(id,{
      setCurrentPosition: !initLocation,
    }, options);

    const lister = window.google.maps.event.addListener(lp.current.map, 'idle', function (event) {
      let location = lp.current.getMarkerPosition();
      const locat = { lat: Number(location.lat), lng: Number(location.lng) }
      if(locat.lat !== locatRef.current.lat && locat.lng !== locatRef.current.lng){
        geocodeByLatLng(locat)
            .then(results => {
              setValue({
                value: results[0],
                label: results[0].formatted_address,
                ...locat
              })
              onChangeAddress(results[0].formatted_address)
              onChange({...location, address: results[0].formatted_address})
            })
            .catch(error => console.error(error));
      }

      locatRef.current = { lat: Number(location.lat), lng: Number(location.lng) }

    });

    return () => {
      window.google.maps.event.removeListener(lister)
    }
  }, [id, initLocation])

  return <div style={{position: 'relative'}}>

    {
      showSearch ? (
          <div className={'search-in-map'}>
            <GooglePlacesAutocomplete
                apiKey="AIzaSyCkxkHGSSdW5V6rAuCZynQ3Cst0RF8kgZM"
                selectProps={{
                  value,
                  onChange: (value) => {
                    geocodeByAddress(value.label)
                        .then(results => {
                          return getLatLng(results[0])
                        })
                        .then(({ lat, lng }) => {
                          setValue({
                            ...value,
                            lat, lng
                          })
                          lp.current.setLocation(lat, lng);
                          onChange({lat, lng, address: value.label})
                        });
                  },
                }}
            />
          </div>
      ) : <></>
    }

    {
      !disabled ? (
          <button className={'center-map-btn'} onClick={(e) => {
            e.preventDefault()
            lp.current.setCurrentPosition()
          }}><Icofont icon="icofont-focus" /></button>
      ) : <></>
    }

    <div id={id} style={{height: height ? height : ''}}>

    </div>
  </div>
};

const debug = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';

let opt = { }

if(process.env.REACT_APP_WEBSITE_NAME === 'CARRYOUT'){
  opt = {
    apiKey: 'AIzaSyCkxkHGSSdW5V6rAuCZynQ3Cst0RF8kgZM'
  }
}

if(process.env.REACT_APP_WEBSITE_NAME === 'FLYORDER'){
  opt = {
    apiKey: 'AIzaSyCkxkHGSSdW5V6rAuCZynQ3Cst0RF8kgZM'
  }
}

export {geocodeByLatLng}
export default GoogleApiWrapper(opt)(MapContainer)
