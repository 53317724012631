import {MAIN_INFO} from '../constants/main-information';

const initState = {
  settings: {
    status: 'WAIT',
    error: null,
    data: null,
    lang:{
      code: 'en',
      dir: 'ltr'
    },
  },
  cssVarsReady: false,
  cities: {
    status: 'WAIT',
    error: null,
    data: null
  },
  regions:{
    status: 'WAIT',
    error: null,
    data: null
  },
  citiesRegions: [],
  country: {}
}

const MainInformation = (state = initState, action) => {
  switch (action.type) {
    case MAIN_INFO.GET_SETTINGS_STATUS:
      return {
        ...state,
        settings: {
          ...state.settings,
          status: action.payload
        }
      }
    case MAIN_INFO.SET_SETTINGS:
      const oldCountry = localStorage.getItem('country')
      return {
        ...state,
        settings: {
          ...state.settings,
          data: action.payload
        },
        country: oldCountry ? JSON.parse(oldCountry) : action.payload.countries[0]
      }
    case MAIN_INFO.SET_BEST_TO_SETTINGS:
      return {
        ...state,
        settings: {
          ...state.settings,
          data: {...state.settings.data, ...action.payload}
        },
      }
    case MAIN_INFO.SET_COUNTRY:
      return {
        ...state,
        country: action.payload
      }
    case MAIN_INFO.GET_SETTINGS_ERROR:
      return {
        ...state,
        settings: {
          ...state.settings,
          error: action.payload
        }
      }

    case MAIN_INFO.SET_SETTING_LANG:
      return {
        ...state,
        settings: {
          ...state.settings,
          lang: action.payload
        }
      }

    case MAIN_INFO.SET_CSS_VARS_READY:
      return {
        ...state,
        cssVarsReady: action.payload
      }

    case MAIN_INFO.GET_CITIES_STATUS:
      return {
        ...state,
        cities: {
          ...state.cities,
          status: action.payload
        }
      }
    case MAIN_INFO.SET_CITIES:
      return {
        ...state,
        cities: {
          ...state.cities,
          data: action.payload
        }
      }
    case MAIN_INFO.GET_CITIES_ERROR:
      return {
        ...state,
        cities: {
          ...state.cities,
          error: action.payload
        }
      }


    case MAIN_INFO.GET_REGIONS_STATUS:
      return {
        ...state,
        regions: {
          ...state.regions,
          status: action.payload
        }
      }
    case MAIN_INFO.SET_REGIONS:
      return {
        ...state,
        regions: {
          ...state.regions,
          data: action.payload
        }
      }
    case MAIN_INFO.SET_CITIES_REGIONS:
      return {
        ...state,
        citiesRegions: action.payload
      }
    case MAIN_INFO.GET_REGIONS_ERROR:
      return {
        ...state,
        regions: {
          ...state.regions,
          error: action.payload
        }
      }

    default:
      return state;
  }
}

export default MainInformation
