import React, { useState, useEffect, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import {Button} from 'react-bootstrap';
import Icofont from 'react-icofont';
import {CART} from "../../redux/constants/cart";
import {useDispatch} from "react-redux";

const CheckoutItem = (props) => {
  const [state, setState] = useState({
    quantity: 0,
    show: props.show || true,
  });

  const dispatch = useDispatch();

  useEffect(() => {
    setState((s) => ({
      ...s,
      quantity: props.qty
    }));
  }, [props.qty])

  const updateQuantity = useCallback((qty) => {
    dispatch({type: CART.UPDATE_QTY, payload: {order_product_id: props.id, quantity: qty}});
  }, [dispatch, props.id])


  const removeItem = useCallback(() => {
    setState((s) => ({
      ...s,
      show: false
    }));
    dispatch({type: CART.REMOVE_ITEM, payload: {order_product_id: props.id}});
  }, [dispatch, props.id])


  const incrementItem = () => {
    const newQty = state.quantity + 1
    setState((s) => ({
      ...s,
      quantity: newQty
    }));
    updateQuantity(newQty)
  }

  const decreaseItem = () => {
    const newQty = state.quantity - 1
    setState((s) => ({ ...s, quantity: newQty }));
    if(newQty === 0){
      removeItem()
    }else {
      updateQuantity(newQty)
    }
  }

  return (
    <div className="gold-members p-2 border-bottom">
           <span className="count-number float-right">
               <Button variant="outline-secondary" onClick={() => {decreaseItem()}} className="btn-sm left dec"> <Icofont icon="minus" /> </Button>
               <input className="count-number-input" type="text" value={state.quantity} readOnly/>
               <Button variant="outline-secondary" onClick={() => {incrementItem()}} className="btn-sm right inc"> <Icofont icon="icofont-plus" /> </Button>
           </span>
      <p className="text-gray mb-0 float-right ml-2 mr-2">
        {props.priceUnit} {Number(props.price).toFixed(2)}
      </p>
      <div className="media">
        <div className="media-body">
          <p className="mt-1 mb-0 text-black">{props.itemName}
            {
              props.item.size ? (
                <p>
                  {props.item.size.name}
                </p>
              ) : ''
            }
          </p>
        </div>
      </div>
    </div>
  )
}



CheckoutItem.propTypes = {
  itemName: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  priceUnit: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  qty: PropTypes.number.isRequired,
  show: PropTypes.bool.isRequired,
  minValue: PropTypes.number,
  maxValue: PropTypes.number,
};
CheckoutItem.defaultProps = {
  show: true,
  priceUnit:'$'
}



export default CheckoutItem;
