import React, {useState} from 'react';
import {Image, Badge, Button,Media} from 'react-bootstrap';
import PropTypes from 'prop-types';
import ProductModal from "../modals/ProductModal";
import {Trans} from "react-i18next";
import {CART} from "../../redux/constants/cart";
import {useDispatch} from "react-redux";
import {addToWishList} from "../../redux/sagas/cart";
import login from "../Login";

const QuickBite = (props) => {
  const dispatch = useDispatch();
  const [state, setState] = useState({
    quantity: props.qty || 0,
    show: props.show || true,
    max:props.maxValue || 5,
    min:props.minValue || 0,
    showModal: false,
    error: null
  })


  const openModal = () => {
    setState((s) => ({
      ...s,
      showModal: true
    }));
  }

  const closeModal = () => {
    setState((s) => ({
      ...s,
      showModal: false
    }));
  }

  const addItemToCart = (val) => {
    setState((s) => ({...s,  ...val, error: null}));
    dispatch({type: CART.ADD_TO_CART, payload: val, callback: (res) => {
        closeModal()
        setState((s) => ({...s, error: null}));
      }, callbackError: (err) => {
        setState((s) => ({...s, error: Object.values(err.data.errors).join(', ')}));
      }})
  }

  return (
    <>
      <div className={"p-3 border-bottom "+props.itemClass} style={{cursor: 'pointer'}} onClick={openModal}>
          <span className="float-right">
            <Button disabled={!props.available} variant='outline-secondary' onClick={props.available ? openModal : () => {}} size="sm">
              {
                props.available ? (<Trans>Menu.Add</Trans>) : (<Trans>sold out</Trans>)
              }
            </Button>
          </span>
        <Media>
          <Image className={"mr-3 " +props.imageClass} src={props.image} alt={props.imageAlt} />
          <Media.Body>
            <h6 className="mb-1 quick-bite-title">
              {props.title}
            </h6>

            {
                props.description && (
                    <p className="text-gray mb-1 p-one-line">{props.description}</p>
                )
            }

            {
              props.calories && (
                    <p className="text-gray mb-1"><i className="fa fa-fire" aria-hidden="true" /> {props.calories}</p>
                )
            }

            <p className="text-gray mb-0">
              {
                props.is_sale && props.productSizes?.length === 1  ?
                    <>
                      <span>{`${props.sale_price} ${props.priceUnit}`} |</span>
                      <span className={'sale-price'}>
                        <i />
                        {`${props.price} ${props.priceUnit}`}
                      </span>
                    </> : ''
              }

                {
                    props.productSizes?.length > 1 ? (<Trans>Price on Selection</Trans>) : ''
                }
                {!props.is_sale && props.productSizes?.length === 1  ? `${props.price} ${props.priceUnit}` : ''}</p>

          </Media.Body>
        </Media>
      </div>
      <ProductModal {...props} show={state.showModal} onHide={() => {
        setState({
          showModal: false
        });
        props.callback()
      }} addItemToCart={addItemToCart} is_wishlist={props.is_wishlist} available={props.available} error={state.error} />
    </>
  );
}


QuickBite.propTypes = {
  itemClass:PropTypes.string,
  title: PropTypes.string.isRequired,
  imageAlt: PropTypes.string,
  image: PropTypes.string,
  imageClass: PropTypes.string,
  showBadge: PropTypes.bool,
  badgeVariant: PropTypes.string,
  badgeText: PropTypes.string,
  price: PropTypes.any.isRequired,
  priceUnit: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  qty: PropTypes.number,
  minValue: PropTypes.number,
  maxValue: PropTypes.number,
  callback: PropTypes.func
};
QuickBite.defaultProps = {
  itemClass:'gold-members',
  imageAlt:'',
  imageClass:'',
  showBadge: false,
  price: '',
  priceUnit:'$',
  showPromoted: false,
  badgeVariant: 'danger',
  callback: () => {}
}

export default QuickBite;
