import { auth } from './FirebaseAuth';
import interceptor from "../interceptors/default-interceptor";
import {getLangCode} from "../getLangCode";

const FirebaseService = {}

FirebaseService.signInWithPhoneNumber = async (phoneNumber, appVerifier) =>
  await auth.signInWithPhoneNumber(phoneNumber, appVerifier).then(user => user).catch(err => err);

FirebaseService.signOutRequest = async () => {
  await auth.signOut().then(user => user).catch(err => err);
  const link = `${interceptor.conf.baseURL}/api/logout`;
  await fetch(link, {
    headers: {
      'Content-Language': getLangCode(),
      ...interceptor.headers
    }
  })
}

FirebaseService.signUpEmailRequest = async (email, password) =>
  await auth.createUserWithEmailAndPassword(email, password).then(user => user).catch(err => err);

export default FirebaseService
