import React from 'react';
import PropTypes from 'prop-types';
import Icofont from 'react-icofont';

class CartDropdownItem extends React.Component {
	render() {
		return (
			<p className="mb-2">
          <Icofont icon={this.props.icoIcon} className={"mr-1 ml-1 " + this.props.iconClass}/>
          <span className={'text-success d-inline-flex flex-column'}>
            {this.props.title}
            {
              this.props.size || this.props?.options?.length > 0 ? (
                <span className={'my-1 text-secondary'}>{this.props.size} {this.props.options.length > 0 ? ` | ${this.props.options?.map((i) => i.option.name).join(', ')}` : ''}</span>
              ) : ''
            }
          </span>

        <span className="float-right text-secondary">{this.props.price}</span>
      </p>
		);
	}
}

CartDropdownItem.propTypes = {
  title: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
  icoIcon: PropTypes.string.isRequired,
  iconclass: PropTypes.string.isRequired
};

CartDropdownItem.defaultProps = {
  title: '',
  price: '',
  icoIcon: '',
  iconclass: '',
}

export default CartDropdownItem;
