import React, {useRef, useState} from 'react';
import {Form, Modal, Button, Alert, Spinner} from 'react-bootstrap';
import {useDispatch, useSelector} from "react-redux";
import interceptor from '../../services/interceptors/default-interceptor';
import {getLangCode} from "../../services/getLangCode";
import axios from "axios";
import {Trans, useTranslation} from "react-i18next";
import {Rating} from 'react-simple-star-rating'

const RateOrderModal = ({id, ...props}) => {
	const commentRef = useRef();
	const user = useSelector((s) => s.auth.user);
	const [state, setState] = useState({
		error: null,
		loading: false,
		rate: 5
	});
	const {t} = useTranslation();
	const dispatch = useDispatch()

	const edit = (event) => {

		event.preventDefault();
		event.stopPropagation();

		setState((s) => ({...s, error: null, loading: true}))

		const _token = localStorage.getItem('AUTH_TOKEN');
		const link = `${interceptor.conf.baseURL}/api/orders/submit-rate`;

		return axios.post(link, {
			rate: state.rate,
			comment: commentRef.current.value || '',
			order_id: id,
		}, {
			headers: {
				'Content-Language': getLangCode(),
				'Authorization': `Bearer ${_token}`,
				...interceptor.headers
			}
		})
			.then(function (response) {
				props.onHide()
			})
			.catch(function (error) {
				setState((s) => ({...s, error: t('Verify that the data is correct') }))
			});
	}

	return (
		<Modal
			show={props.show}
			onHide={props.onHide}
			size="sm"
			centered
		>
			<Modal.Header closeButton={true}>
				<Modal.Title as='h5' id="edit-profile"><Trans>Rate Order</Trans></Modal.Title>
			</Modal.Header>

			<Modal.Body>
				<Form>
					<div className="form-row">
						<Form.Group className="col-md-12">
								<Form.Label className={'w-100'}><Trans>Rate</Trans></Form.Label>
							<div>
								<Rating
									onClick={(r) => {
										setState((s) => ({...s, rate: r / 100 * 5}))
									}}
									ratingValue={state.rate}
									size={40}
									label
									transition
									fillColor='orange'
									emptyColor='gray'
									className={'rating-order'}
								/>
							</div>

						</Form.Group>

					</div>
					<div className="form-row">
						<Form.Group className="col-md-12">
							<Form.Label><Trans>comment</Trans></Form.Label>
							<Form.Control as={'textarea'} type="text"  placeholder="" ref={commentRef}/>
						</Form.Group>
					</div>
				</Form>
				{
					state.error && (
						<Alert variant="danger" role="alert">
							{state.error }
						</Alert>
					)
				}
			</Modal.Body>

			<Modal.Footer className={'edit-profile-footer'}>
				<Button type='button' onClick={props.onHide} variant="outline-primary" className="d-flex text-center justify-content-center"><Trans>CANCEL</Trans></Button>
				<Button type='button' variant="primary" onClick={edit} className='d-flex text-center justify-content-center'>
					{state.loading ? (
						<>
							<Spinner animation="grow" size="sm" className='mx-1' />
							<Trans>LOADING...</Trans>
						</>
					) : (
						<Trans>SEND</Trans>
					)}
				</Button>
			</Modal.Footer>
		</Modal>
	);
}
export default RateOrderModal;
