import React from 'react';
import {Row,Col,Container} from 'react-bootstrap';
import {Trans} from "react-i18next";
import {useSelector} from "react-redux";
import Icofont from "react-icofont";


const Footer = ({className}) => {

	const {playstore_link, appstore_link} = useSelector((s) => {
		if(s.mainInformation.settings.status !== 'WAIT'){
			return s.mainInformation.settings?.data || {}
		}
		return []
	})



	if(!playstore_link && !appstore_link){
		return (
			<>
				<footer className={`pt-4 pb-4 text-center ${className} footer-1 footer-1-list`}>
					<Container>
						<Row>
							<Col md={12} sm={12} className={'mt-3 powered-by-section'}>
								{
									process.env.REACT_APP_WEBSITE_NAME === 'FLYORDER' ? (
										<p className="mt-0 mb-0 powered-by"> <Icofont icon="icofont-copyright" /> <Trans>footer.powered_by_FlyOrder</Trans>  <a href={'https://flyorder.online/'} target={'_blank'}>FLYORDER </a></p>
									) : ''
								}

								{
									process.env.REACT_APP_WEBSITE_NAME === 'CARRYOUT' ? (<p className="mt-0 mb-0 powered-by">
										{/* <Icofont icon="icofont-copyright" /> <Trans>footer.powered_by_Brown</Trans>*/}
									</p>) : ''
								}
							</Col>
						</Row>
					</Container>
				</footer>
			</>
		);
	}

	return (
		<>
			<footer className={'container footer-1'}>
					<Row>

						<Col md={4} sm={12} className={'mt-3'}>
							{
								playstore_link || appstore_link ? (
									<div className={'store-links'}>
										<p className="mt-0 mb-0 download-app-now"><Trans>footer.DownloadAppNow</Trans></p>
										<div className={'d-flex'}>
											{
												playstore_link ? (
													<a href={playstore_link} target={'_blank'}>
														<img src={'/img/google.png'} alt={playstore_link}/>
													</a>
												) : ''
											}


											{
												appstore_link ? (
													<a href={appstore_link} target={'_blank'}>
														<img src={'/img/apple.png'} alt={appstore_link}/>
													</a>
												) : ''
											}
										</div>


									</div>
								) : ''
							}
						</Col>
						<Col md={4}></Col>
						<Col md={4} sm={12} className={'powered-by-section'}>

							{
								process.env.REACT_APP_WEBSITE_NAME === 'FLYORDER' ? (
									<p className="mt-0 mb-0 powered-by"> <Icofont icon="icofont-copyright" /> <Trans>footer.powered_by_FlyOrder</Trans>  <a href={'https://flyorder.online/'} target={'_blank'}>FLYORDER </a></p>
								) : ''
							}

							{
								process.env.REACT_APP_WEBSITE_NAME === 'CARRYOUT' ? (<p className="mt-0 mb-0 powered-by">
									{/* <Icofont icon="icofont-copyright" /> <Trans>footer.powered_by_Brown</Trans>*/}
								</p>) : ''
							}

						</Col>
					</Row>
			</footer>
		</>
	);
}


Footer.propTypes = {
};

Footer.defaultProps = {
}

export default Footer;
