import {CART} from '../constants/cart';

const initState = {
  status: 'WAIT',
  error: null,
  data: null,
  addToCartStatus: null,
  addToCartError: null,
  coupon: {
    status: null,
    error: null,
    data: null,
  },
  makeOrder: {
    status: null,
    error: null,
    data: null,
  },
  cancelOrder: {
    status: null,
    error: null,
    data: null,
  },
  orders: {
    status: null,
    error: null,
    data: null,
  },
  order: {
    status: null,
    error: null,
    data: null,
  }
}

const Cart = (state = initState, action) => {
  switch (action.type) {
    case CART.GET_CART_STATUS:
      return {
        ...state,
        status: action.payload
      }
    case CART.SET_CART:
      return {
        ...state,
        data: action.payload
      }
    case CART.EMPTYING_CART:
      return {
        ...state,
        status: 'WAIT',
        error: null,
        data: null
      }
    case CART.GET_CART_ERROR:
      return {
        ...state,
        error: action.payload
      }


    case CART.ADD_TO_CART_STATUS:
      return {
        ...state,
        addToCartStatus: action.payload
      }
    case CART.SET_CART_FINISH:
      return {
        ...state,
        data: action.payload
      }
    case CART.ADD_TO_CART_ERROR:
      return {
        ...state,
        addToCartError: action.payload
      }

    case CART.COUPON_VALIDITY_STATUS:
      return {
        ...state,
        coupon:{
          ...state.coupon,
          status: action.payload
        }
      }
    case CART.SET_COUPON:
      return {
        ...state,
        coupon:{
          ...state.coupon,
          data: action.payload
        }
      }
    case CART.COUPON_VALIDITY_ERROR:
      return {
        ...state,
        coupon:{
          ...state.coupon,
          error: action.payload
        }
      }


    case CART.MAKE_ORDER_STATUS:
      return {
        ...state,
        makeOrder:{
          ...state.makeOrder,
          status: action.payload
        }
      }
    case CART.SET_MAKE_ORDER:
      return {
        ...state,
        makeOrder:{
          ...state.makeOrder,
          data: action.payload
        }
      }
    case CART.MAKE_ORDER_ERROR:
      return {
        ...state,
        makeOrder:{
          ...state.makeOrder,
          error: action.payload
        }
      }


    case CART.CANCEL_ORDER_STATUS:
      return {
        ...state,
        cancelOrder:{
          ...state.cancelOrder,
          status: action.payload
        }
      }
    case CART.SET_CANCEL_ORDER:
      return {
        ...state,
        cancelOrder:{
          ...state.cancelOrder,
          data: action.payload
        }
      }
    case CART.CANCEL_ORDER_ERROR:
      return {
        ...state,
        cancelOrder:{
          ...state.cancelOrder,
          error: action.payload
        }
      }

    case CART.GET_ORDERS_STATUS:
      return {
        ...state,
        orders:{
          ...state.orders,
          status: action.payload
        }
      }
    case CART.SET_ORDERS:
      return {
        ...state,
        orders:{
          ...state.orders,
          data: action.payload
        }
      }
    case CART.GET_ORDERS_ERROR:
      return {
        ...state,
        orders:{
          ...state.orders,
          error: action.payload
        }
      }


    case CART.GET_ORDER_STATUS:
      return {
        ...state,
        order:{
          ...state.order,
          status: action.payload
        }
      }
    case CART.SET_ORDER:
      return {
        ...state,
        order:{
          ...state.order,
          data: action.payload
        }
      }
    case CART.GET_ORDER_ERROR:
      return {
        ...state,
        order:{
          ...state.order,
          error: action.payload
        }
      }

    default:
      return state;
  }
}

export default Cart
