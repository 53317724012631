import React, {useRef, useState} from 'react';
import {Alert, Button, Form, Media, Modal, Spinner} from "react-bootstrap";
import {Trans, useTranslation} from "react-i18next";
import Icofont from "react-icofont";
import {Link} from "react-router-dom";
import RateOrderModal from "./RateOrderModal";
import {Rating} from "react-simple-star-rating";
import interceptor from "../../services/interceptors/default-interceptor";
import axios from "axios";
import {getLangCode} from "../../services/getLangCode";
import {useSelector} from "react-redux";


const OrderNotRated = ({orders, show, onHide}) => {
	const commentRef = useRef();
	const user = useSelector((s) => s.auth.user);
	const [state, setState] = useState({
		showRateOrder: false,
		error: null,
		loading: false,
		rate: 5
	})
	const {t} = useTranslation();
	const edit = (event) => {

		event.preventDefault();
		event.stopPropagation();

		setState((s) => ({...s, error: null, loading: true}))


		const _token = localStorage.getItem('AUTH_TOKEN');
		const link = `${interceptor.conf.baseURL}/api/orders/submit-rate`;

		return axios.post(link, {
			rate: state.rate,
			comment: commentRef.current.value || '',
			order_id: orders[0].id,
		}, {
			headers: {
				'Content-Language': getLangCode(),
				'Authorization': `Bearer ${_token}`,
				...interceptor.headers
			}
		})
			.then(function (response) {
				onHide()
			})
			.catch(function (error) {
				setState((s) => ({...s, error: t('Verify that the data is correct') }))
			});
	}

	return (
		<Modal
			show={show}
			onHide={onHide}
			size="md"
			centered
		>
			<Modal.Header closeButton={true}>
				<Modal.Title as='h5' id="edit-profile"><Trans>Rate Order</Trans></Modal.Title>
			</Modal.Header>

			<Modal.Body>
				{
					orders?.length > 0 ? (
						<div className="bg-white card mb-4 order-list shadow-sm">
							<div className="gold-members p-4">
								<Media>
									<Media.Body>
										{orders[0].created_at?
											(
												<>
												 <span className="float-right text-info"><Trans>Delivered on</Trans> {orders[0].created_at}
													 <Icofont icon="check-circled" className="text-success ml-1" />
													</span>
												</>
											)
											:""
										}
										<h6 className="mb-2">
											<Trans>ORDER</Trans> #{orders[0].id}
										</h6>
										{
											orders[0].address ? (
												<p className="text-gray mb-1">
													<Icofont icon="location-arrow" /> {orders[0].address}
												</p>
											) : ('')
										}
										<p className="mb-0 text-black text-primary pt-2">
											<span className="text-black font-weight-bold"> <Trans>Total Paid</Trans>:</span> {orders[0].total_cost}
										</p>
										<hr />
										<Form>
											<div className="form-row">
												<Form.Group className="col-md-12">
													<Form.Label className={'w-100'}><Trans>Rate</Trans></Form.Label>
													<div>
														<Rating
															onClick={(r) => {
																setState((s) => ({...s, rate: r / 100 * 5}))
															}}
															ratingValue={state.rate}
															size={40}
															label
															transition
															fillColor='orange'
															emptyColor='gray'
															className={'rating-order'}
														/>
													</div>

												</Form.Group>

											</div>
											<div className="form-row">
												<Form.Group className="col-md-12">
													<Form.Label><Trans>comment</Trans></Form.Label>
													<Form.Control as={'textarea'} type="text"  placeholder="" ref={commentRef}/>
												</Form.Group>
											</div>
										</Form>
										{
											state.error && (
												<Alert variant="danger" role="alert">
													{state.error }
												</Alert>
											)
										}
									</Media.Body>
								</Media>
							</div>
						</div>
					) : ''
				}
				<Modal.Footer className={'edit-profile-footer'}>
					<Button type='button' onClick={onHide} variant="outline-primary" className="d-flex text-center justify-content-center"><Trans>CANCEL</Trans></Button>
					<Button type='button' variant="primary" onClick={edit} className='d-flex text-center justify-content-center'>
						{state.loading ? (
							<>
								<Spinner animation="grow" size="sm" className='mx-1' />
								<Trans>LOADING...</Trans>
							</>

						) : (
							<Trans>SEND</Trans>
						)}
					</Button>
				</Modal.Footer>
			</Modal.Body>
		</Modal>
	)
}

export default OrderNotRated;
