import React, {useEffect} from 'react';
import {Row, Col, Image} from 'react-bootstrap';
import CouponCard from '../common/CouponCard';
import {useDispatch, useSelector} from "react-redux";
import Skeleton from "react-loading-skeleton";
import {Trans, useTranslation} from "react-i18next";
import {MAIN_INFO} from "../../redux/constants/main-information";
import {AiOutlineHeart} from "react-icons/ai";

const Offers = () => {
	const coupons = useSelector(s => s.mainInformation?.settings?.data?.coupons || null);
	const lang = useSelector(s => s.mainInformation.settings?.lang?.code);
	const currency_name = useSelector((s) => s.mainInformation?.settings?.data?.currency_name);
	const {t} = useTranslation();
	const dispatch = useDispatch()

	useEffect(() => {
		dispatch({type: MAIN_INFO.UPDATE_SETTINGS})
	}, [dispatch])

	return (
		<>
			<div className='p-4 bg-white shadow-sm'>
				<Row>
					<Col md={12}>
						<h4 className="font-weight-bold mt-0 mb-3"><Trans>coupons</Trans></h4>
					</Col>


					{
						coupons?.length === 0 ? (
							<Col md={12} sm={12} className="mb-4 pb-2 empty-section">
								<Image fluid className="float-left mr-3" src="/img/earn-score-icon.png" />
								<p><Trans>There are no coupons</Trans></p>
							</Col>
						) : ''
					}

					{
						coupons && (
							<>
								{
									coupons.map((i) => {
										return (
											<Col md={5}>
												<CouponCard
													coupon={i}
													title={lang === 'ar' ? i.description_ar : i.description_en}
													logoImage= 'img/bank/1.png'
													subTitle={`${t('This offer is available from date')} ${i.start_date} ${t('to date')} ${i.end_date} ${t('for a value')} ${i.value} ${i.type === 'fixed' ? currency_name : '%'} ${i.limit ? `${t('A maximum')}`+i.limit : ''} `}
													couponCode={i.code}
													noBorder={false}
												/>
											</Col>
										)
									})
								}
							</>
						)
					}

					{
						!coupons && (
							<>
								<Col md={12}><Skeleton width={'100%'} height={200}/></Col>
								<Col md={12}><Skeleton width={'100%'} height={200}/></Col>
								<Col md={12}><Skeleton width={'100%'} height={200}/></Col>
							</>
						)
					}

				</Row>
			</div>
		</>
	)
}
export default Offers;
