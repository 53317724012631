import {all, takeEvery, takeLeading, put, fork, call, select, delay} from 'redux-saga/effects';
import {CART} from '../constants/cart';
import CartService from "../../services/cart";

export function* getCart() {
  yield takeLeading(CART.GET_CART, function* ({payload = {}}) {
    try {

      const country = yield call(() => {
        if(localStorage.getItem('country')){
          return JSON.parse(localStorage.getItem('country')).id
        }
        return 1
      })

      const hash_key = yield call(() => localStorage.getItem('hash_key'))
      yield put({type: CART.GET_CART_STATUS, payload: 'WAIT'});

      let p = {country, ...payload};

      if(hash_key){
        p = {
          ...p,
          hash_key
        }
      }

      const res = yield call(CartService.getCart, p)

      yield call(() => {
        localStorage.setItem(CART.HASH_KEY, res.data.hash_key)
      })
      yield put({type: CART.SET_CART, payload: res.data});
      yield put({type: CART.GET_CART_STATUS, payload: 'DONE'})
    } catch (error) {
      yield put({type: CART.GET_CART_STATUS, payload: 'ERROR'})
      yield put({type: CART.GET_CART_ERROR, payload: error})

      if(!error?.response?.data){
        yield put({type: CART.CHANGE_HASH_KEY})
      }

      if(error?.response?.data?.message === "Unauthenticated."){
        localStorage.clear();
        document.location.href="/";
      }
    }
  });
}

export function* changeHashKey() {
  yield takeLeading(CART.CHANGE_HASH_KEY, function* ({payload = {}}) {
    try {
      yield call(() => {
        // localStorage.removeItem(CART.HASH_KEY)
      })
      const country = yield call(() => {
        if(localStorage.getItem('country')){
          return JSON.parse(localStorage.getItem('country')).id
        }
        return 1
      })
      yield put({type: CART.GET_CART_STATUS, payload: 'WAIT'});
      const res = yield call(CartService.getNewCart, {country, ...payload})

      yield call(() => {
        localStorage.setItem(CART.HASH_KEY, res.data.hash_key)
      })
      yield put({type: CART.SET_CART, payload: res.data});
      yield put({type: CART.GET_CART_STATUS, payload: 'DONE'})
    } catch (error) {
      console.log("error", error)
    }
  });
}

export function* updateHashKey() {
  yield takeLeading(CART.UPDATE_HASH_KEY, function* ({payload = {}}) {
    try {
      yield put({type: CART.GET_CART_STATUS, payload: 'WAIT'});
      const country = yield call(() => {
        if(localStorage.getItem('country')){
          return JSON.parse(localStorage.getItem('country')).id
        }
        return 1
      })
      const res = yield call(CartService.activeCart, {country, ...payload})
      yield call(() => {
        localStorage.setItem(CART.HASH_KEY, res.data.hash_key)
      })
      yield put({type: CART.SET_CART, payload: res.data});
      yield put({type: CART.GET_CART_STATUS, payload: 'DONE'})
    } catch (error) {
      yield put({type: CART.GET_CART});
      yield put({type: CART.GET_CART_STATUS, payload: 'ERROR'})
      yield put({type: CART.GET_CART_ERROR, payload: error})

      if(error?.response?.data?.message === "Unauthenticated."){
        localStorage.clear();
        document.location.href="/";
      }
    }
  });
}

export function* addToCart() {
  yield takeLeading(CART.ADD_TO_CART, function* ({payload, callback, callbackError}) {
    const _payload = payload
    if(!_payload.size_id){
      delete _payload.size_id
    }
    const type = localStorage.getItem('search').split('&')[0].split('=')[1]
    try {
      yield put({type: CART.ADD_TO_CART_STATUS, payload: 'WAIT'});
      const hash = yield select((s) => s.cart.data.hash_key)
      const res = yield call(CartService.addToCart, {..._payload, delivery_way_id: type, hash_key: hash})
      yield call(callback, res.data.data)
      yield put({type: CART.SET_CART_FINISH, payload: res.data.data});
      yield put({type: CART.ADD_TO_CART_STATUS, payload: 'DONE'})
    } catch (error) {
      if(error.response.status === 422){
        yield call(callbackError, error.response);
        yield put({type: CART.ADD_TO_CART_STATUS, payload: 'ERROR'})
      }else {
        yield put({type: CART.ADD_TO_CART_STATUS, payload: 'ERROR'})
        yield put({type: CART.ADD_TO_CART_ERROR, payload: error})
        yield call(() => {
          localStorage.removeItem(CART.HASH_KEY)
        })
      }

      if(error?.response?.data?.message === "Unauthenticated."){
        localStorage.clear();
        document.location.href="/";
      }
    }
  });
}

export function* updateQty() {
  yield takeEvery(CART.UPDATE_QTY, function* ({payload}) {
    try {
      const type = localStorage.getItem('search').split('&')[0].split('=')[1]
      yield put({type: CART.ADD_TO_CART_STATUS, payload: 'WAIT'});
      const hash = yield select((s) => s.cart.data.hash_key)
      const res = yield call(CartService.updateQty, {...payload, delivery_way_id: type, hash_key: hash})
      yield put({type: CART.SET_CART_FINISH, payload: res.data});
      yield put({type: CART.ADD_TO_CART_STATUS, payload: 'DONE'})
    } catch (error) {
      yield put({type: CART.ADD_TO_CART_STATUS, payload: 'ERROR'})
      yield put({type: CART.ADD_TO_CART_ERROR, payload: error})

      if(error?.response?.data?.message === "Unauthenticated."){
        localStorage.clear();
        document.location.href="/";
      }
    }
  });
}

export function* removeItem() {
  yield takeEvery(CART.REMOVE_ITEM, function* ({payload}) {
    const type = localStorage.getItem('search').split('&')[0].split('=')[1]
    try {
      yield put({type: CART.ADD_TO_CART_STATUS, payload: 'WAIT'});
      const hash = yield select((s) => s.cart.data.hash_key)
      const res = yield call(CartService.removeItem, {...payload, delivery_way_id: type, hash_key: hash})
      yield put({type: CART.SET_CART_FINISH, payload: res.data});
      yield put({type: CART.ADD_TO_CART_STATUS, payload: 'DONE'})
    } catch (error) {
      yield put({type: CART.ADD_TO_CART_STATUS, payload: 'ERROR'})
      yield put({type: CART.ADD_TO_CART_ERROR, payload: error})

      if(error?.response?.data?.message === "Unauthenticated."){
        localStorage.clear();
        document.location.href="/";
      }
    }
  });
}

export function* CouponValidity() {
  yield takeEvery(CART.COUPON_VALIDITY, function* ({payload}) {
    try {
      const type = localStorage.getItem('search').split('&')[0].split('=')[1]
      yield put({type: CART.COUPON_VALIDITY_STATUS, payload: 'WAIT'});
      const hash = yield select((s) => s.cart.data.hash_key)
      const res = yield call(CartService.CouponValidity, {...payload, delivery_way_id: type, hash_key: hash})

      if(res?.data?.status === 400){
        yield put({type: CART.COUPON_VALIDITY_STATUS, payload: 'ERROR'})
        yield put({type: CART.COUPON_VALIDITY_ERROR, payload:res?.data?.message})
      }else {
        yield put({type: CART.SET_COUPON, payload: res.data});
        yield put({type: CART.COUPON_VALIDITY_STATUS, payload: 'DONE'})
      }
    } catch (e) {
      if(e.response.status === 422){
        yield put({type: CART.COUPON_VALIDITY_STATUS, payload: 'ERROR'})
        yield put({type: CART.COUPON_VALIDITY_ERROR, payload:Object.values(e.response.data.errors)[0]})
      }else {
        yield put({type: CART.COUPON_VALIDITY_STATUS, payload: 'ERROR'})
        yield put({type: CART.COUPON_VALIDITY_ERROR, payload: e.toString()})
      }
      yield delay(5000)
      yield put({type: CART.COUPON_VALIDITY_STATUS, payload: null})
      yield put({type: CART.COUPON_VALIDITY_ERROR, payload: null})

      if(e?.response?.data?.message === "Unauthenticated."){
        localStorage.clear();
        document.location.href="/";
      }
    }
  });
}

export function* MakeOrder() {
  yield takeEvery(CART.MAKE_ORDER, function* ({payload, callback}) {
    try {
      let _payload = payload
      const hash = yield select((s) => s.cart.data.hash_key)
      const code = yield select((s) => s.cart?.coupon?.data?.coupon?.code)

      if(code){
        _payload = {
          ...payload,
          code
        }
      }
      const res = yield call(CartService.MakeOrder, {..._payload, hash_key: hash})
      yield put({type: CART.SET_MAKE_ORDER, payload: res.data});
      yield put({type: CART.MAKE_ORDER_STATUS, payload: 'DONE'});

      if(res?.bill_reference && res.status === 200 && res.success === true){
        callback({status: 'BILL_REFERENCE', data: {ref: res.bill_reference, order_id: res.order_id}})
      }else if(Array.isArray(res) && res[0] === 200){
        callback({status: 'PAYMENT', link: res[1]})
      }else {
        callback({status: 'DONE', id: res.data.id, data: res.data})
      }
      yield put({type: CART.EMPTYING_CART});
      yield put({type: CART.SET_COUPON, payload: null});
      yield put({type: CART.COUPON_VALIDITY_STATUS, payload: null});
    } catch (error) {
      callback({status: 'ERROR'})
      yield put({type: CART.MAKE_ORDER_STATUS, payload: 'ERROR'})
      yield put({type: CART.ADD_TO_CART_ERROR, payload: error})

      // if(res.message === "Unauthenticated."){
      //   localStorage.clear();
      //   document.location.href="/";
      // }
    }
  });
}

export function* cancelOrder() {
  yield takeEvery(CART.CANCEL_ORDER, function* ({payload}) {
    try {
      yield put({type: CART.CANCEL_ORDER_STATUS, payload: 'WAIT'});
      const hash = yield select((s) => s.cart.data.hash_key)
      const res = yield call(CartService.cancelOrder, {...payload, hash_key: hash})
      yield put({type: CART.SET_CANCEL_ORDER, payload: res.data});
      yield put({type: CART.CANCEL_ORDER_STATUS, payload: 'DONE'})
    } catch (error) {
      yield put({type: CART.CANCEL_ORDER_STATUS, payload: 'ERROR'})
      yield put({type: CART.CANCEL_ORDER_ERROR, payload: error})

      if(error?.response?.data?.message === "Unauthenticated."){
        localStorage.clear();
        document.location.href="/";
      }
    }
  });
}

export function* orders() {
  yield takeEvery(CART.GET_ORDERS, function* ({payload}) {
    try {
      yield put({type: CART.GET_ORDERS_STATUS, payload: 'WAIT'});
      const res = yield call(CartService.orders)
      yield put({type: CART.SET_ORDERS, payload: res.data});
      yield put({type: CART.GET_ORDERS_STATUS, payload: 'DONE'})
    } catch (error) {
      yield put({type: CART.GET_ORDERS_STATUS, payload: 'ERROR'})
      yield put({type: CART.GET_ORDERS_ERROR, payload: error})

      if(error?.response?.data?.message === "Unauthenticated."){
        localStorage.clear();
        document.location.href="/";
      }
    }
  });
}

function* updateOrders() {
  yield takeEvery(CART.UPDATE_ORDERS, function* ({payload}) {
    try {
      const res = yield call(CartService.orders)
      yield put({type: CART.SET_ORDERS, payload: res.data});
      yield put({type: CART.GET_ORDERS_STATUS, payload: 'DONE'})
    } catch (error) {
      yield put({type: CART.GET_ORDERS_STATUS, payload: 'ERROR'})
      yield put({type: CART.GET_ORDERS_ERROR, payload: error})

      if(error?.response?.data?.message === "Unauthenticated."){
        localStorage.clear();
        document.location.href="/";
      }
    }
  });
}

function* order() {
  yield takeEvery(CART.GET_ORDER, function* ({payload}) {
    try {
      yield put({type: CART.GET_ORDER_STATUS, payload: 'WAIT'});
      const res = yield call(CartService.order, {id: payload.id})
      yield put({type: CART.SET_ORDER, payload: res.data});
      yield put({type: CART.GET_ORDER_STATUS, payload: 'DONE'})
      payload.callback(res.data)
    } catch (error) {
      yield put({type: CART.GET_ORDER_STATUS, payload: 'ERROR'})
      yield put({type: CART.GET_ORDER_ERROR, payload: error})

      if(error?.response?.data?.message === "Unauthenticated."){
        localStorage.clear();
        document.location.href="/";
      }
    }
  });
}

function* updateOrder() {
  yield takeLeading(CART.UPDATE_ORDER, function* ({payload}) {
    try {
      const res = yield call(CartService.order, {id: payload.id})
      yield put({type: CART.SET_ORDER, payload: res.data});
      yield put({type: CART.GET_ORDER_STATUS, payload: 'DONE'})
      payload.callback(res.data)
    } catch (error) {
      yield put({type: CART.GET_ORDER_STATUS, payload: 'ERROR'})
      yield put({type: CART.GET_ORDER_ERROR, payload: error})

      if(error?.response?.data?.message === "Unauthenticated."){
        localStorage.clear();
        document.location.href="/";
      }
    }
  });
}

export function* addToWishList() {
  yield takeEvery(CART.ADD_TO_WISH_LIST, function* ({payload}) {
    try {
      const res = yield call(CartService.addToWishList, {product_id: payload.id})
    } catch (error) {
      if(error?.response?.data?.message === "Unauthenticated."){
        localStorage.clear();
        document.location.href="/";
      }
    }
  });
}

export function* removeFromWishList() {
  yield takeEvery(CART.REMOVE_TO_WISH_LIST, function* ({payload}) {
    try {
      const res = yield call(CartService.removeFromWishList, {product_id: payload.id})
    } catch (error) {
      if(error?.response?.data?.message === "Unauthenticated."){
        localStorage.clear();
        document.location.href="/";
      }
    }
  });
}

export function* getWishList() {
  yield takeEvery(CART.GET_WISH_LIST, function* ({payload}) {
    try {
      const res = yield call(CartService.getWishList)
      payload.callback(res.data)
    } catch (error) {
      payload.callbackError(error)

      if(error?.response?.data?.message === "Unauthenticated."){
        localStorage.clear();
        document.location.href="/";
      }
    }
  });
}

export function* getOrdersNotRated() {
  yield takeEvery(CART.GET_ORDERS_NOT_RATED, function* ({payload}) {
    try {
      const res = yield call(CartService.getOrdersNotRated)
      payload.callback(res)
    } catch (error) {
      payload.callback(error)
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(getCart),
    fork(addToCart),
    fork(updateQty),
    fork(removeItem),
    fork(CouponValidity),
    fork(MakeOrder),
    fork(cancelOrder),
    fork(orders),
    fork(order),
    fork(updateHashKey),
    fork(addToWishList),
    fork(removeFromWishList),
    fork(getWishList),
    fork(getOrdersNotRated),
    fork(updateOrders),
    fork(updateOrder),
    fork(changeHashKey)
  ]);
}
