import React, {useState, useEffect, useRef, useCallback} from 'react';
import { useHistory} from 'react-router-dom';
import {Row, Col, Container, Form, Alert, Button, Modal, Spinner} from 'react-bootstrap';
import Select2 from 'react-select2-wrapper';
import Icofont from 'react-icofont';
import useQuery from "../../hooks/useQuery";
import CategoriesCarousel from '../common/CategoriesCarousel';
import Map, {geocodeByLatLng} from '../common/Map';
import ACarousel from "../common/ACarousel";
import {useDispatch, useSelector} from "react-redux";
import {Trans, useTranslation} from "react-i18next";
import Footer from "../common/Footer";
import {CART} from "../../redux/constants/cart";
import DropdownCascade from 'react-dropdown-cascade';
import {MAIN_INFO} from "../../redux/constants/main-information";
import {orderByDistance} from "geolib";
import { Style } from 'react-style-tag';
const serialize = function(obj) {
	let str = [];
	for (let p in obj)
		if (obj.hasOwnProperty(p)) {
			str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
		}
	return str.join("&");
}

const MapModal = ({show, onHide, initLocation, onChange}) => {
	const [location, setLocation] = useState({});

	return (
		<Modal
			show={show}
			onHide={onHide}
			size="md"
			centered
		>
			<Modal.Header closeButton={true}>
				<Modal.Title as='h6'><Trans>Delivery Address</Trans></Modal.Title>
			</Modal.Header>

			<Modal.Body>
				<div className={'search-box-map-modal'}>
					<Map
						id={'search-box-map'}
						initLocation={initLocation}
						onChange={(location) => {
							setLocation(location)
						}}
						showSearch={true}
					/>
				</div>
			</Modal.Body>

			<Modal.Footer className={'search-box-map-modal-footer'}>
				<Button type='button' variant="primary" onClick={() => onChange(location)} className='d-flex text-center justify-content-center'>
					<Trans>View Menu</Trans>
				</Button>
			</Modal.Footer>
		</Modal>
	)
}

const TopSearch = ({mode = 'primary'}) => {
	const dispatch = useDispatch()
	const addressInputRef = useRef()
	const branchActiveLocalStorage = localStorage.getItem('branch_active')
	const [citiesRegions, setCitiesRegions] = useState([]);
	const [coords, setCoords] = useState(null);
	const status = useSelector((s) => s.mainInformation.settings.status);
	const {data} = useSelector((s) => s.mainInformation.settings);
	const {branches} = useSelector((s) => s.branches);
	const content = useSelector((s) => {
		if(s.mainInformation.settings.data && s.mainInformation.settings.data.hasOwnProperty('homepage')){
			return s.mainInformation.settings.data.homepage
		}
		return {}
	})
	const locationTypes = useSelector((s) => s.mainInformation.settings?.data?.location_types[0].id);
	const cities = useSelector((s) => s.mainInformation.cities?.data || [])
	const regions = useSelector((s) => {
		if(!s.mainInformation.regions?.data || Object.values(s.mainInformation.regions?.data).length === 0){
			return false
		}
		return s.mainInformation.regions?.data
	})

	const history = useHistory();
	const query = useQuery();
	const {t} = useTranslation();
	const [areaValue, setAreaValue] = useState(query.get('area') || null);
	const [state, setState] = useState({
		type: query.get('type') || 2,
		branch: query.get('branch') || null,
		userLocation: query.get('lat') && query.get('lng') ? {
			lat: query.get('lat'),
			lng: query.get('lng'),
		} :null,
		initLocation: query.get('lat') && query.get('lng') ? {
			lat: query.get('lat'),
			lng: query.get('lng'),
		} :null,
		showError: false,
		mapOverlayH: 1,
		address: null,
		showMap: false
	})

	useEffect(() => {
		const citiesRegions = [];
		if(cities && regions){
			Object.keys(regions).map((item) => {
				let ids = [];
				let _children = [];

				regions[item].map((ii) => {
					if(!_children.includes(ii.id)){
						ids.push(ii.id)
						_children.push({
							label: ii.name,
							value: ii.id,
							branche: ii.branches[0]?.id
						})
					}
				})

				citiesRegions.push({
					value: item,
					label: item,
					children: _children
				})
			})
			setCitiesRegions(citiesRegions)
		}
	}, [cities, regions, state.type])

	useEffect(() => {
		navigator.geolocation.getCurrentPosition(({coords: {latitude, longitude}}) => {
			if(branches?.data){
				setCoords({latitude, longitude})
			}
		});
	}, [branches]);

	useEffect(() => {
		if(!query.get('type') && localStorage.getItem('search')){
			const search = localStorage.getItem('search');
			let vals = {};
				search.split('&').map((item) => {
					vals[item.split('=')[0]] = item.split('=')[1]
			})
			setState((s) => ({
				...s,
				...vals,
			}))
			if(vals.hasOwnProperty('area')){
				setAreaValue(vals.area)
			}
		}
	}, [])

	useEffect(() => {
		const tout = setTimeout(() => {
			setState((s) => ({
				...s,
				showError: false
			}))
		}, 5000);
		return () => clearTimeout(tout)
	}, [state.showError])

	useEffect(() => {
		if(document.getElementById('map-overlay')){
			setState((s) => ({
				...s,
				mapOverlayHeight: document.getElementById('map-overlay').clientHeight
			}));
		}
	}, [state.type])

	useEffect(() => {
		if(state.address && addressInputRef.current){
			addressInputRef.current.value = state.address;
		}
	}, [state])

	useEffect(() => {
		let lat = null;
		let lng = null;
		if(query.get('lat') && query.get('lng')){
			lat = query.get('lat')
			lng = query.get('lng')
		}

		if(!query.get('lat') && !query.get('lng') && localStorage.getItem(CART.SEARCH)){
			let obj = {}
			localStorage.getItem(CART.SEARCH).split('&').map((i) => {
				obj[i.split('=')[0]] = i.split('=')[1]
			})
			if(obj.hasOwnProperty('lat') && obj.hasOwnProperty('lng')){
				lat = obj.lat
				lng = obj.lng
			}else if(localStorage.getItem('OLD_LAT') || localStorage.getItem('OLD_LNG')){
				lat = Number(localStorage.getItem('OLD_LAT'))
				lng = Number(localStorage.getItem('OLD_LNG'))
			}
		}

		if(lat && lng){
			setState((s) => ({
				...s,
				userLocation: { lat, lng },
				initLocation: { lat, lng }
			}));
		}
	}, [query.get('lat'), query.get('lng'), locationTypes])

	const TOUT = useRef();
	useEffect(() => {

		if(state?.initLocation?.lat && state?.initLocation?.lng && locationTypes != 12){
			function getAddressByLatLng() {
				geocodeByLatLng({ lat: Number(state?.initLocation?.lat), lng: Number(state?.initLocation?.lng) })
					.then((results) => {
						setState((s) => ({...s,
							...s,
							address: results[0].formatted_address,
						}));
					})
					.catch((e) => {

					})
			}

			function getAddress() {
				clearTimeout(TOUT.current)
				TOUT.current = null;
				try {
					getAddressByLatLng()
				}catch (e) {
					if(!TOUT.current){
						TOUT.current = setTimeout(() => {
							getAddress()
						}, 1000)
					}
				}
			}

			getAddress()



		}

	}, [state?.initLocation, locationTypes])

	const search = ({_userLocation, resetAreaValue = false}) => {
		const oldBranchActiveLocalStorage_ = localStorage.getItem('old_branch_active')
		const oldAreaActiveLocalStorage_ = localStorage.getItem('old_area_active')
		const {type, branch, userLocation: _userLocation_} = state;
		let userLocation =  _userLocation || _userLocation_;

		if(!userLocation && localStorage.getItem(CART.SEARCH)){
			let obj = {}
			localStorage.getItem(CART.SEARCH).split('&').map((i) => {
				obj[i.split('=')[0]] = i.split('=')[1]
			})
			if(obj.hasOwnProperty('lat') && obj.hasOwnProperty('lng')){
				userLocation = {
					lat: obj.lat,
					lng: obj.lng
				}
			}
		}

		dispatch({type: MAIN_INFO.UPDATE_BEST, payload: {id: branch}})


		if(Number(type) === 2 && Number(branch) !== Number(oldBranchActiveLocalStorage_)){
			dispatch({type: CART.CHANGE_HASH_KEY})
		}

		if(Number(type) === 2 && Number(oldAreaActiveLocalStorage_) !== Number(areaValue) && areaValue !== 'null' && oldAreaActiveLocalStorage_ !== 'null'){
			dispatch({type: CART.CHANGE_HASH_KEY})
		}


		if(Number(type) === 1 && areaValue !== 'null' && oldAreaActiveLocalStorage_ !== 'null' && Number(type) === 1 && !isNaN(areaValue) && !isNaN(oldAreaActiveLocalStorage_)){
			if(Number(oldAreaActiveLocalStorage_) !== Number(areaValue)){
				dispatch({type: CART.CHANGE_HASH_KEY})
			}
		}



		if(Number(type) === 1 && query.get('lat') && query.get('lng')){
			const x = localStorage.getItem('branch_active')
			if(localStorage.getItem('branch_active') !== 'null' && localStorage.getItem('branch_active') !== localStorage.getItem('old_branch_active') && localStorage.getItem('old_branch_active') !== 'null'){
				localStorage.setItem('old_branch_active', x)
				dispatch({type: CART.CHANGE_HASH_KEY})
			}
		}

		const old_type = localStorage.getItem('old_type')


		if(Number(old_type) === 1 && Number(type) === 2){
			dispatch({type: CART.CHANGE_HASH_KEY})
		}

		if(Number(old_type) === 2 && Number(type) === 1){
			dispatch({type: CART.CHANGE_HASH_KEY})
		}


		localStorage.setItem('old_branch_active', branch)
		localStorage.setItem('old_type', type)
		localStorage.setItem('old_area_active', areaValue)

		if(type == 1 && userLocation && (!areaValue || areaValue == 'null' || resetAreaValue)){
			history.push(`/listing?${serialize({type, lat: userLocation.lat, lng: userLocation.lng})}`);
			localStorage.setItem(CART.SEARCH, `${serialize({type, lat: userLocation.lat, lng: userLocation.lng})}`)
			return;
		}

		if(branch){
			if(type == 2 ){
				setAreaValue(null)
				localStorage.setItem(CART.SEARCH, `${serialize({type, branch, area: null})}`)
				history.push(`/listing?${serialize({type, branch, area: null})}`);
			}else {
				localStorage.setItem(CART.SEARCH, `${serialize({type, branch, area: areaValue})}`)
				history.push(`/listing?${serialize({type, branch, area: areaValue})}`);
			}
			return;
		}

		setState((s) => ({
			...s,
			showError: true
		}))

	}


	return (

			<section
				className={`homepage-search-block position-relative ${mode === 'secondary' ? 'homepage-search-block--small' : ''}`}
				id={'homepage-search-block'}
				style={{
					backgroundImage: `${data ? `url('${window.innerWidth < 991 ? data.background_mobile : data.background_web}')` : ''}`,
					backgroundPosition: `center ${window.innerWidth > 991 ? '120px' : '87px'}`,
					backgroundSize: window.innerWidth > 991 ? `100vw calc(100vh - 120px)` : `100vw calc(100vh - 87px)`,
					backgroundAttachment: 'fixed',
				}}
			>

				<div className="banner-overlay" />
				{
					mode === 'secondary' && state.type == 1 && locationTypes != 12 && (!query.get('area') || query.get('area') == 'null') && (
						<div className={'map-overlay'} id={'map-overlay'}>
							<Map
								disabled={true}
								id={'map-overlay-box'}
								height={state.mapOverlayHeight}
								initLocation={state.userLocation || state.initLocation}
							/>
						</div>
					)
				}
				<Container>
					<Row className="d-flex align-items-center">
						{
							mode === 'secondary' && (
								<Col md={2} />
							)
						}

						{
							mode !== 'secondary' && (
								<Col md={12} className={'mt-5'}>
									{
										status === 'DONE' ? (
											<>
												{
													mode === 'primary' && (
														<div className="mt-5 homepage-search-title">
															{
																content.homepage_slogan_one ? (
																	<h1 className="mb-2 font-weight-normal">
																		{content.homepage_slogan_one}
																	</h1>
																) : ''
															}
															<h5 className="font-weight-normal">{content.homepage_slogan_two}</h5>
														</div>
													)
												}
											</>
										) : ''
									}
								</Col>
							)
						}
						<Col md={8}>
							<div className={`homepage-search-form ${mode === 'secondary' ? 'homepage-search-form-translate' : ''}`}>
								<Form className="form-noborder">
									<div className="form-row">
										<Form.Group className='col-lg-3 col-md-3 col-sm-12'>
											<div className="location-dropdown" dir={'ltr'}>
												<Icofont icon='location-arrow'/>
												<Select2 className="custom-select"
														 value={state.type}
														 onSelect={({params}) => {
															 setState((s) => ({
																 ...s,
																 type: params.data.id,
																 showMap: params.data.id == 1 && locationTypes != 12,
															 }))

															 if(params.data.id == 1 && locationTypes != 12){
																 setState((s) => ({
																	 ...s,
																	 areaValue: null
																 }))
															 }

														 }}
														 onOpen={() => {
															 document.querySelector('.select2-search__field').style.display = 'none'
														 }}
														 data={data?.delivery_ways.map(({name, id}) => ({text: name, id}))}
												/>
											</div>
										</Form.Group>

										<Form.Group className='col-lg-7 col-md-6 col-sm-12'>
											<MapModal
												show={state.showMap}
												initLocation={state.initLocation}
												onHide={() => {
													setState((s) => ({
														...s,
														showMap: false
													}))
												}}
												onChange={(location) => {
													setState((s) => ({
														...s,
														type: 1,
														userLocation: location,
														showMap: false,
														areaValue: null,
														address: location.address
													}))
													setTimeout(() => {
														search({_userLocation: location, resetAreaValue: true})
													}, 100)
												}} />

											{
												(state.type == 1 && locationTypes != 12) ? (
													<div className={'search-box-map search-box-map-address'}>
														<input ref={addressInputRef} onClick={() => {
															setState((s) => ({
																...s,
																type: 1,
																showMap: true
															}))
														}} readOnly={true} onFocus={() => {
															if(window.location.pathname !== '/listing'){
																setState((s) => ({...s, address: null}))
															}
														}} />
													</div>
												) : (<></>)
											}


											{
												state.type == 1 && locationTypes == 12 && !query.get('lat') ? (
													<span className={'cascade-input'}>

													<DropdownCascade
														customStyles={{
															dropdown: {
																style: {
																	margin: 0,
																	width: '100%'
																}
															},
														}}
														separatorIcon={' , '}
														items={citiesRegions}
														onSelect={(value, selectedItems) => {
															setState((s) => ({...s, branch: selectedItems[1]?.branche}));
															setAreaValue(selectedItems[1]?.value)
														}}
														value={areaValue ? Number(areaValue) : null}
													/>
												</span>
												) : (<></>)
											}

											{
												branches && branches.data && state.type == 2 ? (
													<Select2
														value={state.branch}
														onSelect={({params}) => {
															setState((s) => ({
																...s,
																branch: params.data.id
															}))

															if(window.location.pathname === '/'){
																dispatch({type: MAIN_INFO.UPDATE_BEST, payload: {id: params.data.id}})
																localStorage.setItem(CART.SEARCH, `${serialize({type: 2, branch: params.data.id, area: null})}`)
																localStorage.setItem('branch_active', params.data.id)
																localStorage.setItem('OLD_ID', params.data.id)
															}

														}}
														data={
															coords ?
															orderByDistance(coords, branches.data).map(({id, name, is_open, address}) => ({id, text: name, is_open, address})) :
															branches.data.map(({id, name, is_open, address}) => ({id, text: name, is_open, address}))
														}
														options={{
															placeholder: t('search.Select Branch'),
														}}
													/>
												) : (<></>)
											}

										</Form.Group>
										<Form.Group className='col-lg-2 col-md-3 col-sm-12'>
											<Button onClick={search} className="btn btn-primary btn-block btn-lg btn-gradient"><Trans>View Menu</Trans></Button>
										</Form.Group>
									</div>
									<Alert variant="danger" role="alert" show={state.showError}>
										<Trans>search.Choose the delivery method</Trans>
									</Alert>
								</Form>
							</div>

							{
								mode === 'primary' && (
									<>
										<CategoriesCarousel />
									</>
								)
							}
						</Col>



						{
							mode === 'primary' && (
								<Col md={4}>
									<div className="osahan-slider pl-4 pt-3" >
										<ACarousel branch={state.branch || branchActiveLocalStorage} onClick={() => {
											if(!state.branch || !branchActiveLocalStorage){
												setState((s) => ({...s, showError: true}))
											}
										}} />
									</div>
								</Col>
							)
						}

						{
							mode === 'secondary' && (
								<Col md={2} />
							)
						}
					</Row>
				</Container>

				{
					mode !== 'secondary' && (
						<Footer className={'footer-2'} />
					)
				}

			</section>
	)
}

export default TopSearch;
