import React from 'react';
import {Image, Button, Tooltip, OverlayTrigger} from 'react-bootstrap';
import PropTypes from 'prop-types';
import {Trans, useTranslation} from "react-i18next";
import Icofont from "react-icofont";
import {useSelector} from "react-redux";

const CouponCard = (props) => {
  const {t} = useTranslation();
  const currency_name = useSelector((s) => s.mainInformation?.settings?.data?.currency_name);
  function copy() {
    navigator.clipboard.writeText(props.couponCode);
  }

  return (
    <div className={'card bg-white rounded shadow-sm text-white mb-4 p-4 clearfix restaurant-detailed-earn-pts card-icon-overlap border-1'}>
      <div className={'d-flex w-100'}>
        <Image fluid className="float-left mr-3" src="/img/earn-score-icon.png" />
        <div className="float-left w-100">
          <h6 className="float-left pt-0 text-primary mb-1 font-weight-bold d-flex" style={{
            'border': '1px solid #ddd',
            'padding': '5px',
            'borderRadius': '5px',
            'alignItems': 'center',
            'justify-content': 'space-between',
            'minWidth': '140px'
          }}>
            {props.coupon.code}
            <OverlayTrigger
              key={'checkout-btn'}
              placement={'bottom'}
              className={'checkout-btn'}
              overlay={
                <Tooltip id={'checkout-btn'}>
                  <Trans>Copy Code to Clipboard</Trans>
                </Tooltip>
              }
              defaultShow={false}>
              <Button onClick={copy} variant="link" className="card-btn mx-1 p-0"><Icofont icon="ui-copy" /></Button>
            </OverlayTrigger>
          </h6>

          <div className="mb-0 float-left w-100">
            <p  style={{fontSize: '14px', 'justify-content': 'space-between'}} className="mb-1 d-flex"> <strong><Trans>from date</Trans>: </strong> <span>{props.coupon.start_date} </span></p>
            <p  style={{fontSize: '14px', 'justify-content': 'space-between'}} className="mb-1 d-flex"> <strong><Trans>to date</Trans>: </strong> <span>{props.coupon.end_date} </span></p>
            <p  style={{fontSize: '14px'}} className="mb-0 d-flex">
              <strong>{t('for a value')} {props.coupon.value} {props.coupon.type === 'fixed' ? currency_name : '%'} {`${props.coupon.limit ? `${t('A maximum')} ` + props.coupon.limit : ''} `}</strong>
            </p>
          </div>
        </div>
      </div>
    </div>
  )




  return (
    <div className={"card offer-card shadow-sm mb-4" + (props.noBorder ? ' border-0' : '')}>
      <div className="card-body">
        {(props.logoImage || props.couponCode)?
          (<h5 className="card-title">
            {props.logoImage?
              <Image src={props.logoImage} alt={props.imageAlt} className={props.imageclassName} />
              :""
            }
            {props.couponCode?
              props.couponCode
              :""
            }
          </h5>)
          :""
        }
        <h6 className="card-subtitle mb-2 text-block">{props.title}</h6>
        {props.subTitle?
          <p className="card-text">
            {props.subTitle}
          </p>
          :""
        }

        <OverlayTrigger
          key={'checkout-btn'}
          placement={'bottom'}
          className={'checkout-btn'}
          overlay={
            <Tooltip id={'checkout-btn'}>
              <Trans>Copy Code to Clipboard</Trans>
            </Tooltip>
          }
          defaultShow={false}>
          <Button onClick={copy} variant="link" className="card-btn mr-3 p-0"><Trans>Copy</Trans></Button>
        </OverlayTrigger>
      </div>
    </div>
  );
}

CouponCard.propTypes = {

};
CouponCard.defaultProps = {
  logoImage: '',
  subTitle: '',
  imageAlt: '',
  imageclassName: '',
  morelinkUrl: '#',
  moreLinkText: 'KNOW MORE',
  copyBtnText: '',
  couponCode: '',
  noBorder: true,
}

export default CouponCard;
