import React from 'react';
import OwlCarousel from 'react-owl-carousel3';
import ProductBox from '../home/ProductBox';
import {useSelector} from "react-redux";
import {Trans} from "react-i18next";
const CategoriesCarousel = () => {
  const status = useSelector((s) => s.mainInformation?.settings?.status);
  const best = useSelector((s) => s.mainInformation?.settings?.data?.best || []);
  const {data} = useSelector((s) => s.branches.menu);
  const settings = useSelector((s) => s.mainInformation.settings.data);
  const isRtl = document.getElementsByTagName('html')[0].getAttribute('dir') === 'rtl'

  const branchActive = localStorage.getItem('branch_active')
  const branches = useSelector((s) => s?.branches?.branches?.data);

  const options={
    responsive: {
      0:{
        items:3,
      },
      600:{
        items:4,
      },
      1000: {
        items: 5,
      },
      1200: {
        items: 5,
      },
    },
    rtl: isRtl.toString(),
    lazyLoad: false,
    loop: false,
    autoplay: true,
    autoplaySpeed: 1000,
    dots: false,
    autoplayTimeout: 2000,
    nav: true,
    autoplayHoverPause: true,
    navText: ["<i class='fa fa-chevron-left'></i>", "<i class='fa fa-chevron-right'></i>"]
  }

  const isCarouselRtl = (bestItems = []) => {

    if(!isRtl){
      return false;
    }

    const windowWidth = window.innerWidth;
    if(windowWidth < 600){
      return bestItems?.length < 4;
    }

    if(windowWidth < 1000){
      return bestItems?.length < 5;
    }

    if(windowWidth < 1200){
      return bestItems?.length < 5;
    }

    return bestItems?.length < 3;

  }

	return (
    <>
      {
        status === 'DONE' && best.length > 0 && (
          <>
            <h6 className="text-shadow font-weight-normal menu-section-header">
              <span>
                <Trans>search.Main Menu Description</Trans>
              </span>
            </h6>
            <div dir={isCarouselRtl(best) ? 'rtl' : 'ltr'}>
              <OwlCarousel nav loop {...options} className="owl-carousel-category owl-theme">
                {
                  status === 'DONE' ? (
                      <>
                        {
                          best.map((product) => (
                              <div className="item" key={product.id} dir={isRtl ? 'rtl' : 'ltr'}>
                                <ProductBox
                                    is_sale={product.is_sale}
                                    sale_amount={product.sale_amount}
                                    sale_price={product.sale_price}
                                    id={product.id}
                                    boxClass='osahan-category-item'
                                    title={product.name}
                                    counting={product.price}
                                    image={product.image}
                                    images={product.images}
                                    imageClass='img-fluid'
                                    imageAlt={product.name}
                                    price={product.price}
                                    priceUnit={settings ? settings?.currency_name : ''}
                                    description={product.description}
                                    productSizes={product.productSizes}
                                    optionGroups={product.optionGroups}
                                    notice={product.notice}
                                    calories={product.calories}
                                    is_wishlist={product.is_wishlist}
                                    available={!product.sold_out}
                                    is_open={data?.is_open === undefined ? branches?.filter((i) => i.id == branchActive)[0]?.is_open : data?.is_open}
                                />
                              </div>
                          ))
                        }
                      </>
                  ) : ''
                }
              </OwlCarousel>
            </div>
          </>
        )
      }
    </>

	);
}



export default CategoriesCarousel;
