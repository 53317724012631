import {BRANCHES} from '../constants/branches';

const initState = {
  branches: {
    status: 'WAIT',
    error: null,
    data: null
  },
  menu: {
    status: 'WAIT',
    error: null,
    data: {
      categories: []
    }
  },
  deliveryLocations:{
    status: 'WAIT',
    error: null,
    data: null
  }
}

const Branches = (state = initState, action) => {
  switch (action.type) {
    case BRANCHES.GET_BRANCHES_STATUS:
      return {
        ...state,
        branches: {
          ...state.branches,
          status: action.payload
        }
      }
    case BRANCHES.SET_BRANCHES:
      return {
        ...state,
        branches: {
          ...state.branches,
          data: action.payload
        }
      }
    case BRANCHES.GET_BRANCHES_ERROR:
      return {
        ...state,
        branches: {
          ...state.branches,
          error: action.payload
        }
      }


    case BRANCHES.GET_MENU_STATUS:
      return {
        ...state,
        menu: {
          ...state.menu,
          status: action.payload
        }
      }
    case BRANCHES.SET_MENU:
      return {
        ...state,
        menu: {
          ...state.menu,
          data: action.payload
        }
      }
    case BRANCHES.GET_MENU_ERROR:
      return {
        ...state,
        menu: {
          ...state.menu,
          error: action.payload
        }
      }


    case BRANCHES.GET_DELIVERY_LOCATION_STATUS:
      return {
        ...state,
        deliveryLocations: {
          ...state.deliveryLocations,
          status: action.payload
        }
      }
    case BRANCHES.SET_DELIVERY_LOCATION:
      return {
        ...state,
        deliveryLocations: {
          ...state.deliveryLocations,
          data: action.payload
        }
      }
    case BRANCHES.GET_DELIVERY_LOCATION_ERROR:
      return {
        ...state,
        deliveryLocations: {
          ...state.deliveryLocations,
          error: action.payload
        }
      }

    default:
      return state;
  }
}

export default Branches
