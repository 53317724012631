import React, {useState, useImperativeHandle, useEffect, createRef, useRef} from 'react';
import {Form, Modal, Button, ButtonToolbar, ToggleButtonGroup, ToggleButton, Alert, OverlayTrigger, Tooltip} from 'react-bootstrap';
import OwlCarousel from "react-owl-carousel3";
import ProductBox from "../home/ProductBox";
import {Trans, useTranslation} from "react-i18next";
import Icofont from "react-icofont";
import {CART} from "../../redux/constants/cart";
import {useDispatch} from "react-redux";
import Loader from "react-loader-spinner";
import Lightbox from "react-awesome-lightbox";
import "react-awesome-lightbox/build/style.css";

const carouselOptions={
	responsive: {
		0:{
			items: 1,
		},
		764:{
			items: 1,
		},
		765: {
			items: 1,
		},
		1200: {
			items: 1,
		},
	},
	lazyLoad: true,
	loop: true,
	autoplay: true,
	autoplaySpeed: 1000,
	dots: false,
	autoplayTimeout: 5000,
	nav: true,
	navText: ["<i class='fa fa-chevron-left'></i>", "<i class='fa fa-chevron-right'></i>"],
	autoplayHoverPause: false,
}

const InfoModal = ({info}) => {

	const [show, setShow] = useState(false)

	return (
		<>
			<button onClick={() => setShow(true)} className={'info-modal-btn'}>
				<span>
					<Trans>Product Info</Trans>
				</span>
				<Icofont icon="info" />
			</button>
			<Modal
				show={show}
				onHide={() => setShow(false)}
				size="lg"
				centered
				backdropClassName={'info-desc-modal-backdrop'}
				dialogClassName={'info-desc-modal'}
			>
				<Modal.Body>
					{info}
				</Modal.Body>
			</Modal>
		</>

	)
}

const Option = React.forwardRef(({_id, disabled, name, description, minimum_options, maximum_options, priceUnit, options, callback}, ref) => {
	const {t} = useTranslation();
	const [state, setState] = useState({
		options: new Set(),
		priceOption : 0,
	})

	useImperativeHandle(ref, () => ({
		isValid: () => {
			return state.options.size >= minimum_options && state.options.size <= maximum_options
		},
		values: () => {
			return {
				"option_group_id": _id,
				"option_id": [...state.options]
			}
		},
		data: {_id, disabled, name, description, minimum_options, maximum_options, priceUnit, options}
	}));

	return (
		<Form.Group className="mb-2 mt-2 col-md-12">
			<Form.Label className={'w-100'} style={{display: 'flex', justifyContent: 'space-between'}}>
				<h6>
					{name}
					<span className={'badge badge-light'}>
						<span style={{color: '#ff4545'}}>
							(
								{description} {' '}
								{
									minimum_options && maximum_options && minimum_options === maximum_options ? `${t('Menu.Choose')} ${minimum_options} ` : (
										<>
											{
												minimum_options ? `${t('Menu.minimum')} ${minimum_options} ` : ''
											}
											{
												maximum_options ? `${t('Menu.maximum')} ${maximum_options} ` : ''
											}
										</>
									)
								}
							)
						</span>
					</span>
				</h6>
				{
					(minimum_options && maximum_options) ? (
						<div className={'required-badge'}><Trans>required</Trans></div>
					) : ''
				}
			</Form.Label>
			{
				options.map(({id, name, price}) => (
					<OverlayTrigger
						key={id}
						placement={'top'}
						overlay={
							(![...state.options].includes(id) && state.options.size === maximum_options) ? (
								<Tooltip id={id}>
									{description} {' '}
									{
										minimum_options && maximum_options && minimum_options === maximum_options ? `${t('Menu.Choose')} ${minimum_options} ` : (
											<>
												{
													minimum_options ? `${t('Menu.minimum')} ${minimum_options} ` : ''
												}
												{
													maximum_options ? `${t('Menu.maximum')} ${maximum_options} ` : ''
												}
											</>
										)
									}
								</Tooltip>
							) : <div />
						}
					>
						<Form.Group className="checkbox-row" controlId={id} key={id}>
							<Form.Check type="checkbox" label={name} disabled={disabled || (![...state.options].includes(id) && state.options.size === maximum_options)} onClick={() => {
								const _options = state.options
								let _price = 0;
								if(_options.has(id)){
									_options.delete(id);
									_price = state.priceOption - Number(price)
									setState((s) => ({...s, priceOption: _price}))
								}else {
									_options.add(id);
									_price = state.priceOption + Number(price)
									setState((s) => ({...s, priceOption: _price}))
								}
								setState((s) => ({...s, options: _options}));
								setTimeout(() => {
									callback(_price)
								}, 100)
							}} />
							<Form.Text>{price} {priceUnit}</Form.Text>
						</Form.Group>
					</OverlayTrigger>
				))
			}
		</Form.Group>
	)
});

const Description = ({text}) => {
	const textRef = useRef();
	const [expand, setExpand] = useState(false);


	return (
		<div>
			<div ref={textRef} className={ text.replace(/ /g, '').length > 70 ? !expand ? 'p-one-line' : '' : ''} style={{maxWidth:expand ? '100%' : '88%'}}>
				{text}
			</div>
			{
				text.replace(/ /g, '').length > 70 && (
					<a className={'show-more-btn'} href={'#'} onClick={(e) => {
						e.preventDefault();
						setExpand(s => !s)
					}} >
						{!expand ? (
							<Trans>Show More</Trans>
						) : (
							<Trans>Hide More</Trans>
						)}
					</a>
				)
			}
		</div>
	)
}

const ProductModal = ({is_sale, sale_price, errorForLocation = false, available, is_open, error, show, onHide, calories, description, id, image, images = [], notice, optionGroups, price, priceUnit, productSizes, title, addItemToCart, is_wishlist, ...x}) => {
	const {t} = useTranslation();
	const dispatch = useDispatch();
	const [state, setState] = useState({
		size: productSizes ? productSizes[0].id : null,
		sizePrice: price,
		priceOption: {0 : 0},
		optRefs: {},
		isValid: true,
		comment: '',
		quantity: 1,
		isWishlist: false,
		loading: false,
		showImageModal: false,
		showImageModalIndex: 0,
		errMsg: null
	})

	const deliveryMethodNotSelected = localStorage.getItem('OLD_ID') || (localStorage.getItem('OLD_LAT') && localStorage.getItem('OLD_LNG'))

	useEffect(() => {
		if(error){
			setState((s) => ({...s, loading: false}))
		}
	}, [error])

	useEffect(() => {
		setState((s) => ({
			...s,
			isWishlist: is_wishlist
		}))
	}, [is_wishlist])

	useEffect(() => {
		if(show && productSizes?.length > 0){
			setState((s) => ({
				...s,
				size: productSizes[0].id,
				sizePrice: price,
				optRefs: [],
				isValid: true,
				comment: '',
				quantity: 1
			}))
		}
	}, [price, productSizes, show])


	const addItem = () => {
		const minimum = t('Menu.minimum')
		const maximum = t('Menu.maximum')
		const choose = t('Menu.Choose')


		let isValid = true;
		let errMsg = null
	 	 Object.values(state.optRefs).map((ref) => {
			if(!ref.current.isValid()){
				const {description, minimum_options, maximum_options} = ref.current.data;
				isValid = false;
				const maximumText = (maximum_options ? `${maximum + " " + maximum_options}` : '')
				const minimumText = (minimum_options ? `${minimum + " " + minimum_options}` : '')
				errMsg = `
					${description} ${(minimum_options && maximum_options && minimum_options === maximum_options)
						? `${ choose + " " + minimum_options }` : minimumText + " " + maximumText }
				`
			}
		})

		setState((s) => ({...s, isValid, errMsg: errMsg}))

		if(isValid) {
			setState((s) => ({...s,  loading: true}))

			addItemToCart( {
				"product_id": id,
				"size_id": state.size,
				"quantity": state.quantity,
				"option": Object.values(state.optRefs).map((ref) => (
					ref.current.values()
				)).filter(i => i.option_id?.length > 0),
				"comment": state.comment,
			})
		}
	}

	const addItemToWishList = () => {
		dispatch({type: CART.ADD_TO_WISH_LIST, payload: {id}})
		setState((s) => ({...s, isWishlist: true}))
	}

	const removeItemFromWishList = () => {
		dispatch({type: CART.REMOVE_TO_WISH_LIST, payload: {id}})
		setState((s) => ({...s, isWishlist: false}))
	}

	useEffect(() => {
		if(optionGroups && optionGroups.length > 0){
			let _optRefs = {};
			setState((s) => ({...s, optRefs: {}}));
			optionGroups.map(({id}) => {
				_optRefs[id] = createRef()
			})

			setState((s) => ({...s, optRefs: _optRefs}))
		}
	}, [optionGroups, show]);

	useEffect(() => {
		if(show){
			setState((s) => ({...s, loading: false}))
		}
	}, [show]);


	const _images = images.length === 0 ? [{url: image, thumb: image}] : images;

	return (
		<>
			<Modal
				show={show}
				onHide={onHide}
				size="md"
				centered
				className={'product-modal'}
			>
				<Modal.Header closeButton={true} className="p-0 m-0 product-modal-header">
					<div className="product-slider" dir={'ltr'}>


						<button onClick={() => {
							if(state.isWishlist){
								removeItemFromWishList()
							}else {
								addItemToWishList()
							}
						}} className={'wish-list-btn'}>
							<Icofont icon="heart-alt" style={{
								color: state.isWishlist ? 'red' : '#ffffff'
							}} />
						</button>



						<OwlCarousel nav loop {...carouselOptions} className="homepage-ad owl-theme">

							{
								_images?.map((_image, i) => (

									<div className="item" key={i}>

										<div key={_image.url} onClick={() => {
											setState((s) => ({...s, showImageModal: true, showImageModalIndex: i}))
										}}>
											<ProductBox
												image={_image.url}
												imageClass='img-fluid'
												imageAlt='carousel'
												boxClass='product-slider-item'
												disabled={true}
											/>
										</div>


									</div>

								))
							}


						</OwlCarousel>
					</div>

				</Modal.Header>

				<Modal.Title as='h5' className={'product-title'}>
					{title}
				</Modal.Title>

				<p className={'product-info pt-0'}>
					<Description text={description} />
					<span>
							{
								calories && (
									<span> <i className="fa fa-fire" aria-hidden="true" /> {calories} <Trans>Menu.Cal</Trans> </span>
								)
							}
						<span> {notice} </span>
					</span>
				</p>

				<Modal.Body className={'pt-0'}>

					{
						is_sale && (
							<>
								{
									!(productSizes && productSizes.length > 1) ? (
										<div className={'product-modal-price'}>
											{((sale_price * state.quantity) + (Object.values(state.priceOption).reduce((c, p) => c + p) || 0)).toFixed(2)} {priceUnit}
											<span className="mr-1 sale-price" style={{fontSize: '14px'}}>
												<i />
												{(((price * state.quantity) + (Object.values(state.priceOption).reduce((c, p) => c + p) || 0))).toFixed(2)} {priceUnit}
											</span>
										</div>
									) : (
										<div className={'product-modal-price'}>
											{((Number(state.sizePrice) + (Object.values(state.priceOption).reduce((c, p) => c + p) || 0)) * state.quantity).toFixed(2)} {priceUnit}
										</div>
									)
								}
							</>
						)
					}

					{
						!is_sale && (
							<>
								{
									!(productSizes && productSizes.length > 1) ? (
										<div className={'product-modal-price'}>
											{(((price * state.quantity) + (Object.values(state.priceOption).reduce((c, p) => c + p) || 0))).toFixed(2)}  {priceUnit}
										</div>
									) : (
										<div className={'product-modal-price'}>
											{(((Number(state.sizePrice) + (Object.values(state.priceOption).reduce((c, p) => c + p) || 0)) * state.quantity)).toFixed(2)} {priceUnit}
										</div>
									)
								}
							</>
						)
					}


					<Form>
						<div className="form-row">
							{
								productSizes && productSizes.length > 1 ? (
									<Form.Group className="mb-2 col-md-12">
										<Form.Label><h6> <Trans>Menu.the size</Trans> <span className={'badge badge-light'}>( <Trans>Menu.Choose</Trans> 1  )</span></h6></Form.Label>
										<ButtonToolbar>
											<ToggleButtonGroup className="d-flex w-100" type="radio" name="options" defaultValue={productSizes[0].id}>
												{
													productSizes.map(({name, price, id, sale_price, is_sale}) => (
														<ToggleButton disabled={!available} variant='info' value={id} key={id} onClick={(() => {
															if(is_sale){
																setState((s) => ({...s, size: id, sizePrice: Number(sale_price)}))
															}else {
																setState((s) => ({...s, size: id, sizePrice: Number(price)}))
															}

														})}>
															{name}
															{
																is_sale && (
																	<>
																		<div>
																			<span className="mr-1 badge badge-warning">{sale_price} {priceUnit}</span>
																		</div>
																		<div>
																			<span style={{'fontSize': '14px','color': '#fff'}} className="mr-1 sale-price"> <i style={{backgroundColor: '#fff'}} /> {Number(price)} {priceUnit}</span>
																		</div>
																	</>
																)
															}
															{
																!is_sale && (
																	<>
																		<div>
																			<span className="mr-1 badge badge-warning">{price} {priceUnit}</span>
																		</div>
																	</>
																)
															}

														</ToggleButton>
													))
												}
											</ToggleButtonGroup>
										</ButtonToolbar>
									</Form.Group>
								) : ''
							}
							{
								(optionGroups && state.optRefs) ? (
									<>
										{
											optionGroups.map(({id: _id, ...option}, i) => <Option callback={(price) =>{
												setState((s) => ({...s, priceOption: {...s.priceOption, [i]: Number(price)}}))
											}} ref={state.optRefs[_id]} key={_id} _id={_id} {...option} disabled={!available}/> )
										}
									</>
								) : ''
							}
							{
								available && (
									<Form.Group className="mb-2 mt-2 col-md-12">
										<Form.Label><h6><Trans>Menu.Notes</Trans></h6></Form.Label>
										<textarea
											placeholder={t('Menu.Notes')}
											className="form-control"
											style={{ height: '100px' }}
											onKeyUp={(e) => {
												if(e.target && e.target.value){
													const val = e.target.value;
													setState((s) => ({...s, comment: val}))
												}
											}}
										/>
									</Form.Group>
								)
							}
						</div>
					</Form>
				</Modal.Body>
				{
					(available && !errorForLocation && is_open) ? (
						<Modal.Footer>
							<Button type='button' variant="primary" className='d-flex w-50 text-center justify-content-center'
									onClick={addItem}>
								{state.loading ? (
									<>
										<Trans>Loading</Trans>
										<Loader
											style={{margin: '0 10px'}}
											type="Oval"
											color="#fff"
											height={20}
											width={20}
										/>
									</>
								) : (
									<Trans>Menu.Ok</Trans>
								)}

							</Button>
							<span className="count-number float-right">
						 <Button variant="outline-secondary" onClick={() => state.quantity > 1 ? setState((s) => ({...s, quantity: s.quantity - 1})) : null} className="btn-sm left dec"> <Icofont icon="minus" /> </Button>
						 <input className="count-number-input" type="text" value={state.quantity} readOnly/>
						 <Button variant="outline-secondary" onClick={() => setState((s) => ({...s, quantity: s.quantity + 1}))} className="btn-sm right inc"> <Icofont icon="icofont-plus" /> </Button>
					</span>
						</Modal.Footer>
					) : ''
				}

				{
					!!deliveryMethodNotSelected ? (
						<>
							<Alert variant="danger" role="alert" className={'text-center'} show={!is_open}>
								<Trans>This branch is currently closed</Trans>
							</Alert>

							<Alert variant="danger" role="alert" className={'text-center'} show={!available && is_open !== undefined && is_open && is_open !== 0 && !errorForLocation}>
								<Trans>sold out</Trans>
							</Alert>

							<Alert variant="danger" role="alert" className={'text-center'} show={errorForLocation}>
								<Trans>This location is not supported, choose another delivery method and try again</Trans>
							</Alert>

							<Alert variant="danger" className={'text-center'} role="alert" show={!state.isValid}>
								<Trans>Menu.Check the options</Trans> {state.errMsg ? `( ${state.errMsg} )` : ''}
							</Alert>

							{
								(error && error !== '' && error !== undefined) ? (
									<Alert variant="danger" className={'text-center'} role="alert" show={error}>
										{error}
									</Alert>
								) : ('')
							}
						</>
					) : (
						<Alert variant="danger" className={'text-center'} role="alert" show={true}>
							<Trans>Please choose a delivery method first</Trans>
						</Alert>
					)
				}


				{
					state.showImageModal && (
						<>
							<Lightbox
								onClose={() => setState((s) => ({...s, showImageModal: false}))}
								images={images.length > 1 ? images?.map((_image) => ({
									url: _image.url,
									title: _image.url,
								})) : null}

								image={images.length === 1 ? images[0].url : null}

								showTitle={false}
								startIndex={state.showImageModalIndex}
							/>
							<InfoModal info={description} />
						</>
					)
				}
			</Modal>

		</>
	)
}

export default ProductModal;
