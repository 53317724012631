import React, {useEffect} from 'react';
import OrderCard from '../common/OrderCard';
import {CART} from "../../redux/constants/cart";
import {useDispatch, useSelector} from "react-redux";
import {BsMinecartLoaded} from "react-icons/bs";
import Skeleton from "react-loading-skeleton";
import {Trans, useTranslation} from "react-i18next";
import {onMessageListener} from '../../services/auth/FirebaseAuth'
const Orders = () => {

	const dispatch = useDispatch();
	const {status, data, error} = useSelector((s) => (s.cart.orders))
	const {t} = useTranslation();
	useEffect(() => {
		dispatch({type: CART.GET_ORDERS})
	}, []);

	onMessageListener().then((res) => {
		dispatch({type: CART.UPDATE_ORDERS})
	}).catch((e) => { })

	return (
		<>
			<div className='p-4 bg-white shadow-sm'>
				<h4 className="font-weight-bold mt-0 mb-4"><Trans>Past Orders</Trans></h4>
				{
					status === 'DONE' ? (
						<>
							{
								data.length > 0 ? (
									<>
										{data.map((item) => {
											if(item.products.length === 0){
												return
											}
											const products = item.products.map(({price, quantity, product: {name}}) => {
												return `${name} x ${quantity}`
											})
											return (
												<OrderCard
													image={item.products[0].product.image}
													imageAlt={item.products[0].product.image}
													key={item.id}
													orderNumber={item.id}
													orderDate={item.created_at}
													deliveredDate={item?.actions[item?.actions.length -1]}
													orderTitle=""
													address={item.address}
													address_desc={item.address_desc}
													status={item?.actions[item?.actions.length -1]?.status}
													orderProducts={products.toString()}
													orderTotal={item.total_cost}
													helpLink='#'
													rate={item.rate}
													detailLink={`/detail?order=${item.id}`}
												/>
											)
										})}
									</>
								) : (
									<div className="bg-white rounded shadow-sm mb-2 d-flex justify-content-center align-items-center flex-column" style={{paddingTop: 50, paddingBottom: 50}}>
										<p style={{fontSize: 50}}>
											<BsMinecartLoaded />
										</p>
										<p style={{fontSize: 16, textAlign: 'center'}}><Trans>There are no previous Orders</Trans></p>
									</div>
								)
							}
						</>
					) : (
						<>
							<Skeleton width={'100%'} height={200}/>
							<Skeleton width={'100%'} height={200}/>
							<Skeleton width={'100%'} height={200}/>
						</>
					)
				}
			</div>
		</>
	);
}
export default Orders;
