import React, {useEffect, useRef, useState, memo, createRef} from 'react';
import {Link} from 'react-router-dom';
import {Row, Col, Container, Image, Alert, OverlayTrigger, Tooltip, Button} from 'react-bootstrap';
import CheckoutItem from '../common/CheckoutItem';
import QuickBite from '../common/QuickBite';
import Icofont from 'react-icofont';
import TopSearch from "../home/TopSearch";
import {useDispatch, useSelector} from "react-redux";
import {BRANCHES} from "../../redux/constants/branches";
import useQuery from "../../hooks/useQuery";
import {Trans, useTranslation} from "react-i18next";
import { StickyContainer, Sticky } from 'react-sticky';
import Skeleton from "react-loading-skeleton";
import {CART} from "../../redux/constants/cart";
import Loader from "react-loader-spinner";
import {BsMinecartLoaded} from 'react-icons/bs'
import $ from 'jquery'
import MainInformationService from "../../services/main-information";
import BranchesService from "../../services/branches";

const Cart = ({deliveryMin}) => {
	const [state, setState] = useState({coupon: null})
	const query = useQuery();
	const token = useSelector((s) => s.auth.token);
	const isMobile = window.innerWidth < 768;
	const status = useSelector((s) => (s.cart.addToCartStatus))
	const {total_price = 0, total_quantity = 0, products = [], price} = useSelector((s) => (s.cart.status === 'DONE' ? s.cart.data : {
		total_price: 0, total_quantity: 0, products: [], price: 0
	}))
	const coupons = useSelector(s => s.mainInformation.settings?.data?.coupons);
	const settings = useSelector((s) => s.mainInformation.settings.data);
	const currency_name = useSelector((s) => s.mainInformation?.settings?.data?.currency_name);
	const {t} = useTranslation();


	useEffect(() => {
		if(query.get('branch') && coupons){
			MainInformationService.getCouponsById({id: query.get('branch')}).then((res) => {
				if(Array.isArray(res) && res.length > 0){
					setState((s) => ({...s, coupon: res[0]}))
				}else if(coupons && coupons.length > 0) {
					setState((s) => ({...s, coupon: coupons[0]}))
				}
			})
		}

		if(query.get('lng')){
			BranchesService.getDeliveryBranchByLocation(
				{latitude: Number(query.get('lat')), longitude: Number(query.get('lng'))}
			).then((res) => {
				if(res?.branch){
					MainInformationService.getCouponsById({id: res?.branch.id}).then((res) => {
						if(Array.isArray(res.data) && res.data.length > 0){
							setState((s) => ({...s, coupon: res.data[0]}))
						}else if(coupons && coupons.length > 0) {
							setState((s) => ({...s, coupon: coupons[0]}))
						}
					})
				}
			})
		}
	}, [coupons])


	return (
		<Sticky topOffset={200} disableCompensation={isMobile}>
			{({
					style,
					isSticky,
					wasSticky,
					distanceFromTop,
					distanceFromBottom,
					calculatedHeight
				}) => (
				<div  style={!isMobile ? style : {}} className={isSticky && !isMobile ? 'sticky-section' : ''}>
					<div>
						<div className="generator-bg rounded shadow-sm mb-4 p-4 osahan-cart-item">
							<h5 className="mb-1 text-white d-flex justify-content-between">
								<span><Trans>Your Order</Trans></span>
								{
									status === 'WAIT' ? (
										<Loader
											type="Oval"
											color="#fff"
											height={20}
											width={20}
										/>
									) : ''
								}
							</h5>
							{
								total_quantity ? (
									<p className="mb-4 text-white">{total_quantity} <Trans>Items</Trans></p>
								) : ''
							}

							{
								!total_quantity ? (
									<div className="bg-white rounded shadow-sm mb-2 d-flex justify-content-center align-items-center flex-column" style={{paddingTop: 50, paddingBottom: 50}}>
										<p style={{fontSize: 50}}>
											<BsMinecartLoaded />
										</p>
										<p style={{fontSize: 16, textAlign: 'center'}}><Trans>Add items into your basket</Trans></p>
									</div>
								) : ''
							}

							{
								products ? (
									<div className="bg-white rounded shadow-sm mb-2">
										{
											products.map(({id, total_price, quantity, product, ...item}) => (
												<CheckoutItem
													item={item}
													itemName={product.name}
													price={total_price}
													priceUnit={settings ? settings?.currency_name : ''}
													id={id}
													qty={quantity}
													show={true}
													key={id}
												/>
											))
										}
									</div>
								) : ''
							}

							{
								price ? (
									<>
										<div className="mb-2 bg-white rounded p-2 clearfix">
											<Image fluid className="float-left" src="/img/wallet-icon.png" />
											<h6 className="font-weight-bold text-right mb-2"><Trans>Subtotal</Trans> : <span className="text-danger">{settings ? settings?.currency_name : ''} {Number(price).toFixed(2)}</span></h6>
											<p className="seven-color mb-1 text-right"><Trans>Cart.Extra charges may apply</Trans></p>
											{/*<p className="text-black mb-0 text-right">You have saved $955 on the bill</p>*/}
										</div>


										{
											token ? (
												<>
													<OverlayTrigger
														key={'checkout-btn'}
														placement={'bottom'}
														className={'checkout-btn'}
														overlay={
															(query.get('type') != 2 && deliveryMin && Number(deliveryMin) > price) ? (
																<Tooltip id={'checkout-btn'}>
																	<Trans>Minimum order</Trans> {settings ? settings?.currency_name : ''} {Number(deliveryMin)}
																</Tooltip>
																) : <div />
														}
													 defaultShow={false}>
														<Link to={(query.get('type') != 2 && deliveryMin && Number(deliveryMin) > price) ? `/listing${window.location.search}` : `/checkOut${window.location.search}`}
																	className={`btn btn-success btn-block btn-lg`}
														>
															<Trans>Checkout</Trans>
															<Icofont icon="long-arrow-right" />
														</Link>
													</OverlayTrigger>
												</>
											) : (
												<Link to={`/login?redir=checkout`} className="btn btn-success btn-block btn-lg">
													<Trans>Checkout</Trans>
													<Icofont icon="long-arrow-right" />
												</Link>
											)
										}


										{/*<div className="pt-2"></div>*/}
										{/*<div className="alert alert-success" role="alert">*/}
										{/*	You have saved <strong>$1,884</strong> on the bill*/}
										{/*</div>*/}
										{/*<div className="pt-2"></div>*/}
									</>
								) : ''
							}

						</div>

						{
							state.coupon && (
								<div className="bg-white rounded shadow-sm text-white mb-4 p-4 clearfix restaurant-detailed-earn-pts card-icon-overlap">
									<Image fluid className="float-left mr-3" src="/img/earn-score-icon.png" />
									<h6 className="pt-0 text-primary mb-1 font-weight-bold"><Trans>Offer</Trans></h6>
									<p className="mb-0">{`${t('This offer is available from date')} ${state.coupon.start_date} ${t('to date')} ${state.coupon.end_date} ${t('for a value')} ${state.coupon.value} ${state.coupon.type === 'fixed' ? currency_name : '%'} ${state.coupon.limit ? `${t('A maximum')}`+state.coupon.limit : ''} `} | <Trans>Use coupon</Trans>
										<span className="text-danger font-weight-bold">{' '}{state.coupon.code}
											<OverlayTrigger
												key={'checkout-btn'}
												placement={'bottom'}
												className={'checkout-btn'}
												overlay={
													<Tooltip id={'checkout-btn'}>
														<Trans>Copy Code to Clipboard</Trans>
													</Tooltip>
												}
												defaultShow={false}>
												<Button style={{
													fontSize: '14px',
													color: 'red'
												}} onClick={() => navigator.clipboard.writeText(state.coupon.code)}
																variant="link" className="card-btn mx-1 p-0"><Icofont icon="ui-copy" /></Button>
											</OverlayTrigger>
										</span>
									</p>
								</div>
							)
						}
					</div>
				</div>
			)}
		</Sticky>
	)
}

const CategoriesList = ({categories, onClick, active}) => {
	const isMobile = window.innerWidth < 768;
	const [state, setState] = useState({
		active: null
	});

	useEffect(() => {
		setState((s) => ({...s, active: active}));
	}, [active])

	return (
		<Sticky topOffset={200} disableCompensation={isMobile}>
			{({
					style,
					isSticky,
					wasSticky,
					distanceFromTop,
					distanceFromBottom,
					calculatedHeight
				}) => (
				<div style={!isMobile ? style : {}} className={isSticky && !isMobile ? 'sticky-section' : ''}>
					<div className="categories-list generator-bg2 rounded shadow-sm mb-4 p-2">
						<ul className="flex-column border-0 p-3">
							{
								categories.filter((i) => i.id === 'sale')[0].products.length > 0 && categories.filter((i) => i.id === 'sale').map(({id}) => (
									<li className="nav-item" key={id}>
										<Link
											onClick={() => {
												onClick(id);
												setState((s) => ({...s, active: id}));
											}}
											name="anchorName"
											className={`nav-link p-1 ${active === id ? 'active' : ''}`}
											to={{
												search: window.location.search,
											}}
										><Trans>Offers</Trans></Link>
									</li>
								))
							}
							{
								categories.filter((i) => i.id !== 'sale').map(({name, id}) => (
									<li className="nav-item" key={id}>
										<Link
											onClick={() => {
												onClick(id);
												setState((s) => ({...s, active: id}));
											}}
											name="anchorName"
											className={`nav-link p-1 ${active === id ? 'active' : ''}`}
											to={{
												search: window.location.search,
											}}
										>{name}</Link>
									</li>
								))
							}
						</ul>
					</div>
				 </div>
			)}
		</Sticky>
	)
}

const List = () => {
	const query = useQuery();
	const dispatch = useDispatch();
	const [state, setState] = useState({
		refsOfCategories: {},
		search: null,
		activeSection: null,
	});
	const [data, setData] = useState({categories: [{
			id: 'sale',
			image: "",
			name: "Sale",
			notice: "Sale",
			products: []
		}]});
	const {t} = useTranslation();
	const {data: menu, status} = useSelector((s) => s.branches.menu);
	const settings = useSelector((s) => s.mainInformation.settings.data);

	useEffect(() => {
		setTimeout(() => {
			const OLD_ID = localStorage.getItem('OLD_ID')
			const OLD_LNG = localStorage.getItem('OLD_LNG')
			const OLD_LAT = localStorage.getItem('OLD_LNG')

			if(query.get('branch')){
				dispatch({type: BRANCHES.GET_MENU, payload: {id: query.get('branch')}});
				localStorage.setItem('OLD_ID', query.get('branch'))
			}

			if(query.get('branch') && query.get('branch') === OLD_ID){

				const hash_key = localStorage.getItem(CART.HASH_KEY);
				dispatch({type: CART.GET_CART, payload: {hash_key, delivery_way_id: query.get('type')}})
				dispatch({type: CART.UPDATE_HASH_KEY, payload: {hash_key, delivery_way_id: query.get('type')}});
				localStorage.setItem('delivery_way_id', query.get('type'))
			}

			if(query.get('branch') && query.get('branch') !== OLD_ID){
				dispatch({type: CART.GET_CART, payload: {delivery_way_id: query.get('type')}})
				localStorage.setItem('delivery_way_id', query.get('type'))
			}

			if(query.get('lat') && query.get('lng')){
				dispatch({type: BRANCHES.GET_MENU_FOR_LOCATION, payload: {latitude: query.get('lat'), longitude: query.get('lng')}});
				localStorage.setItem('OLD_LNG', query.get('lng'))
				localStorage.setItem('OLD_LAT', query.get('lat'))
			}

			if(query.get('lat') && query.get('lng') && OLD_LNG === query.get('lng') && OLD_LAT === query.get('lat')){
				const hash_key = localStorage.getItem(CART.HASH_KEY);
				dispatch({type: CART.GET_CART, payload: {hash_key, delivery_way_id: query.get('type')}})
				dispatch({type: CART.UPDATE_HASH_KEY, payload: {hash_key, delivery_way_id: query.get('type')}});
				localStorage.setItem('delivery_way_id', query.get('type'))
			}

			if(query.get('lat') && query.get('lng') && (OLD_LNG !== query.get('lng') || OLD_LAT !== query.get('lat'))){
				dispatch({type: CART.GET_CART, payload: {delivery_way_id: query.get('type')}})
				localStorage.setItem('delivery_way_id', query.get('type'))
			}


		}, 0)
	}, [query.get('lng'), query.get('lat'), query.get('branch')]);

	useEffect(() => {
		if (data && data.categories && (status === 'DONE' || status === 'ERROR_FOR_LOCATION')) {
			setState((s) => ({...s, refsOfCategories: {}}))
			let _refsOfCategories = {}
			data.categories.map(({id}) => {
				_refsOfCategories[id]  = createRef()
			})
			setState((s) => ({...s, refsOfCategories: _refsOfCategories}))
		}
	}, [data, data.categories, status])

	useEffect(() => {
		if(status === 'WAIT'){
			setData({categories: [{
					id: 'sale',
					image: "",
					name: "Sale",
					notice: "Sale",
					products: []
				}]})
		}
		if(status !== 'WAIT'){
			if(status === 'ERROR_FOR_LOCATION'){
				BranchesService.getDefaultMenuByCountry().then((res) => {
					setData({...res.data,
						categories: [
							...res.data.categories,
							{
								id: 'sale',
								image: "",
								name: "Sale",
								notice: "Sale",
								products: []
							}
						],
					})
				}).catch((e) => {
					setData(menu)
				})
			}else {
				setData(menu)
			}
		}
	}, [status, menu]);


	const handleScrollTo = (id) => {
		if(state.refsOfCategories[id].current){
			state.refsOfCategories[id].current.scrollIntoView({ behavior: 'smooth' })
		}
	}

	useEffect(() => {
		if(data && (status === 'DONE' || status === 'ERROR_FOR_LOCATION')){
			let activeSection = null
			$(window).scroll(function (event) {
				const arr = data.categories.filter((i) => i.id === 'sale')[0].products.length > 0 ? [{id: 'sale'}, ...data.categories.filter((i) => i.id !== 'sale')] : data.categories.filter((i) => i.id !== 'sale')
				const ids = arr
					.filter(({id}) => $(window)?.scrollTop() > $(`#_${id}`)?.offset()?.top - 200)
					.map((i) => i.id)

				if(activeSection !== ids[ids.length - 1]){
					setState((s) => ({...s, activeSection: ids[ids.length - 1]}));
					activeSection = ids[ids.length - 1]
				}
			});
		}
	}, [data, status])


	useEffect(() => {
		if(window.location.hash === '#_sale' && status === 'DONE'){
			setTimeout(() => {
				if(document.getElementById('_sale')){
					document.getElementById('_sale')
						.scrollIntoView({ behavior: 'smooth' })
				}
			}, 500)
		}
	}, [status])


	useEffect(() => {
		if(status === 'ERROR_FOR_LOCATION'){
			dispatch({type: CART.CHANGE_HASH_KEY})
		}
	}, [status])

	return (
		<StickyContainer>
			<TopSearch mode={'secondary'} />
			<section className="offer-dedicated-body offer-dedicated-body-list pt-2 pb-2 mt-4">
				<Container>
					<Row>
						<Col md={3}>
							{
								status === 'WAIT' && (
									<Skeleton width={'100%'} height={500}/>
								)
							}
							{
								(status === 'DONE' || status === 'ERROR_FOR_LOCATION') && (
									<CategoriesList active={state.activeSection} categories={data.categories} onClick={(id) => {
										handleScrollTo(id)
									}} />
								)
							}
						</Col>
						<Col md={6}>
							<div className="offer-dedicated-body-left">
								<div className='h-100'>

									{
										status === 'WAIT' && (
											<Skeleton count={6} width={'100%'} height={100}/>
										)
									}

									{
										(status === 'DONE' || status === 'ERROR_FOR_LOCATION') && (

											<>
												{
													status === 'DONE' && (
														<Alert variant={'danger'} className={'text-center'} show={!data.is_open}>
															<Trans>
																This branch is currently closed
															</Trans>
														</Alert>
													)
												}

												{
													status === 'ERROR_FOR_LOCATION' && (
														<Alert variant={'danger'} className={'text-center'} show={status === 'ERROR_FOR_LOCATION'}>
															<Trans>
																This place is not supported
															</Trans>
														</Alert>
													)
												}

												<div className={'form-group'}>
													<input
														onKeyUp={(e) => {
															// eslint-disable-next-line no-unused-expressions
															e?.persist();
															setTimeout(() => {
																setState((s) => ({...s, search: e?.target?.value?.toLowerCase() || null}))
															}, 100);

														}}
														placeholder={`${t('search')}` + '...'} className={'form-control search-for-menu'} type={'search'}/></div>

											</>

										)
									}

									{
										(status === 'DONE' || status === 'ERROR_FOR_LOCATION') && (
											data.categories.filter((i) => i.id === 'sale').map(({name, products, id}) => (
												<Row key={name} >
													<div id={`_${id}`} ref={state.refsOfCategories[id]} className={'scroll-div-position'} />
													{
														products.filter((i) => state.search && state.search !== '' ? i.name?.toLowerCase().includes(state.search) : i).length > 0 ? (
															<>
																<h5 className="mb-4 mt-3 col-md-12"><Trans>Offers</Trans> <small className="h6 text-black-50">{products.length} <Trans>Menu.ITEMS</Trans></small></h5>
																<Col md={12}>
																	<div className="bg-white rounded border shadow-sm mb-4">
																		{
																			products.filter((i) => state.search && state.search !== '' ? i.name?.toLowerCase().includes(state.search) : i)
																				.map(({sold_out, images, is_wishlist, name, description, image, price, productSizes, thumb, id, optionGroups, notice, calories, is_sale, sale_amount, sale_price, ...x}) => {
																					return (
																						<QuickBite
																							is_sale={is_sale}
																							sale_amount={sale_amount}
																							sale_price={sale_price}
																							key={id}
																							id={id}
																							itemClass="menu-list"
																							thumb={thumb}
																							title={name}
																							price={price}
																							priceUnit={settings ? settings?.currency_name : ''}
																							description={description}
																							productSizes={productSizes}
																							optionGroups={optionGroups}
																							notice={notice}
																							calories={calories}
																							image={image}
																							images={images}
																							is_wishlist={is_wishlist}
																							available={!sold_out}
																							errorForLocation={status === 'ERROR_FOR_LOCATION'}
																							is_open={data.is_open}
																						/>
																					)
																				})
																		}
																	</div>
																</Col>
															</>
														) : ''
													}
												</Row>
											))
										)
									}

									{
										(status === 'DONE' || status === 'ERROR_FOR_LOCATION') && (
											data.categories.filter((i) => i.id !== 'sale').map(({name, products, id}) => (
												<Row key={name}>
													<div id={`_${id}`} ref={state.refsOfCategories[id]} className={'scroll-div-position'} />
													{
														products.filter((i) => state.search && state.search !== '' ? i.name?.toLowerCase().includes(state.search) : i).length > 0 ? (
															<>
																<h5 className="mb-4 mt-3 col-md-12">{name} <small className="h6 text-black-50">{products.length} <Trans>Menu.ITEMS</Trans></small></h5>
																<Col md={12}>
																	<div className="bg-white rounded border shadow-sm mb-4">
																		{
																			products.filter((i) => state.search && state.search !== '' ? i.name?.toLowerCase().includes(state.search) : i)
																				.map(({sold_out, images, is_wishlist, name, description, image, price, productSizes, thumb, id, optionGroups, notice, calories, is_sale, sale_amount, sale_price}) => {
																					return (
																						<QuickBite
																							is_sale={is_sale}
																							sale_amount={sale_amount}
																							sale_price={sale_price}
																							key={id}
																							id={id}
																							itemClass="menu-list"
																							thumb={thumb}
																							title={name}
																							price={price}
																							priceUnit={settings ? settings?.currency_name : ''}
																							description={description}
																							productSizes={productSizes}
																							optionGroups={optionGroups}
																							notice={notice}
																							images={images}
																							calories={calories}
																							image={image}
																							is_wishlist={is_wishlist}
																							available={!sold_out}
																							errorForLocation={status === 'ERROR_FOR_LOCATION'}
																							is_open={data.is_open}
																						/>
																					)
																				})
																		}
																	</div>
																</Col>
															</>
														) : ''
													}
												</Row>
											))
										)
									}
								</div>
							</div>
						</Col>
						<Col md={3}>
							{
								status === 'WAIT' && (
									<Skeleton width={'100%'} height={500}/>
								)
							}
							{
								status === 'DONE' && (
									<Cart deliveryMin={data.delivery_min} />
								)
							}
						</Col>
					</Row>
				</Container>
			</section>
		</StickyContainer>
	);

}

export default List;
