import React, {useState, useEffect, useRef} from 'react';
import {Link, useHistory} from 'react-router-dom';
import {
	Row,
	Col,
	Container,
	Form, OverlayTrigger, Tooltip, Image, Button,
} from 'react-bootstrap';
import CheckoutItem from '../common/CheckoutItem';
import AddAddressModal from '../modals/AddAddressModal';
import Icofont from 'react-icofont';
import Map from "../common/Map";
import useQuery from "../../hooks/useQuery";
import {useDispatch, useSelector} from "react-redux";
import {CART} from "../../redux/constants/cart";
import {BsMinecartLoaded} from "react-icons/bs";
import Loader from "react-loader-spinner";
import Coupon from "../Coupon";
import Payment from "./Payment";
import {Trans, useTranslation} from "react-i18next";
import interceptor from "../../services/interceptors/default-interceptor";
import {getLangCode} from "../../services/getLangCode";
import {AUTH} from "../../redux/constants/auth";
import axios from "axios";
import MainInformationService from "../../services/main-information";
import BranchesService from "../../services/branches";
import {AiOutlineClockCircle} from "react-icons/ai";

function Checkout() {
	const query = useQuery();
	const setPhoneNumberRef = useRef(false);
	const lastNameRef = useRef(null);
	const nameRef = useRef(null);
	const mobileRef = useRef(null);
	const emailRef = useRef(null);
	const addressRef = useRef(null);
	const moreDetailsRef = useRef(null);
	const {t} = useTranslation();
	const history = useHistory();
	const dispatch = useDispatch();
	const email = useSelector((s) => (s.auth.user?.metadata?.email))
	const name = useSelector((s) => {
		return s.auth?.user?.metadata?.name
	})
	const last_name = useSelector((s) => {
		return s.auth?.user?.metadata?.last_name
	})
	const coupons = useSelector(s => s.mainInformation.settings?.data?.coupons);
	const addToCartStatus = useSelector((s) => (s.cart.addToCartStatus))
	const {status: makeOrderStatus, data, error} = useSelector((s) => (s.cart.makeOrder))
	const status = useSelector((s) => (s.cart.status))
	const coupon = useSelector((s) => (s.cart.coupon))
	const {total_price = 0, total_quantity = 0, products = [], delivery_fee, branch_vat_number, price} = useSelector((s) => (s.cart.status === 'DONE' ? s.cart.data : {
		total_price: 0, total_quantity: 0, products: [], delivery_fee: 0, branch_vat_number: 0, price: 0
	}))
	const branch_id = query.get('branch') || localStorage.getItem('branch_active') || localStorage.getItem('OLD_ID')
	const settings = useSelector((s) => s.mainInformation.settings.data);
	const branche = useSelector((s) => s.branches?.branches?.data?.filter((b) => b.id == branch_id)[0] || []);

	const paymentWays = useSelector((s) => s.mainInformation.settings?.data?.payment_ways || []);
	const user = useSelector((s) => s.auth.user);

	const [phoneNumber, setPhoneNumber] = useState(null)
	const [state, setState] = useState({
		error: null,
		showAddressModal: false,
		searchType: query.get('type'),
		branch: query.get('branch') || null,
		userLocation: query.get('lat') && query.get('lng') ? {
			lat: query.get('lat'),
			lng: query.get('lng'),
		} :null,
		initLocation: query.get('lat') && query.get('lng') ? {
			lat: query.get('lat'),
			lng: query.get('lng'),
		} :null,
		showError: false,
		deliverTo: query.get('type') || null,
		receiptFromCar: false,
		paymentWay: paymentWays && paymentWays.length > 0 ? paymentWays[0].id : 0,
		address: null,
		coupon: null,
		checkoutLoading: false
	})

	useEffect(() => {
		if(status === 'WAIT'){
			const hash_key = localStorage.getItem(CART.HASH_KEY);
			if(hash_key){
				dispatch({type: CART.GET_CART, payload: {hash_key, delivery_way_id: query.get('type')}})
				localStorage.setItem('delivery_way_id', query.get('type'))
			}else {
				history.push(`/listing${window.location.search}`)
			}
		}
	}, [dispatch, history, status]);

	useEffect(() => {
		if(coupon.status === 'DONE'){
			dispatch({type: CART.COUPON_VALIDITY, payload: {code: coupon.data.coupon.code}})
		}
	}, [total_price])

	const hideAddressModal = () => setState((s) => ({...s, showAddressModal: false }));

	useEffect(() => {
		if(query.get('branch')){
			MainInformationService.getCouponsById({id: query.get('branch')}).then((res) => {
				if(Array.isArray(res) && res.length > 0){
					setState((s) => ({...s, coupon: res[0]}))
				}else if(coupons && coupons.length > 0) {

					setState((s) => ({...s, coupon: coupons[0]}))
				}
			})
		}

		if(query.get('lng')){
			BranchesService.getDeliveryBranchByLocation(
				{latitude: Number(query.get('lat')), longitude: Number(query.get('lng'))}
			).then((res) => {
				if(res?.branch){
					MainInformationService.getCouponsById({id: res?.branch.id}).then((res) => {
						if(Array.isArray(res.data) && res.data.length > 0){
							setState((s) => ({...s, coupon: res.data[0]}))
						}else if(coupons && coupons.length > 0) {
							setState((s) => ({...s, coupon: coupons[0]}))
						}
					})
				}
			})
		}
	}, [coupons])

	useEffect(() => {
		if(!setPhoneNumberRef.current && user?.phoneNumber){
			setPhoneNumber(user.phoneNumber);
			setPhoneNumberRef.current = true
		}
	}, [user]);


	const checkout = () => {

		setState((s) => ({...s, error: null}))

		if(!nameRef.current.value || !mobileRef.current.value ||
			nameRef.current.value === '' || mobileRef.current.value === '' || emailRef.current.value === '' || emailRef.current.value === ''  ){
			setState((s) => ({...s, error: 'All fields are required'}))
			return;
		}

		const payload = {
			name: nameRef.current.value,
			last_name: lastNameRef.current.value,
			email: emailRef.current.value,
			payment_way_id: state.paymentWay,
			delivery_way_id: Number(query.get('type')),
			more_details: moreDetailsRef.current.value,
			phone_number: phoneNumber
		}

		localStorage.setItem('delivery_way_id', Number(query.get('type')))

		if(state.userLocation?.lat && state.userLocation?.lng){
			payload.latitude = state.userLocation?.lat;
			payload.longitude = state.userLocation?.lng;
			payload.address = state.address === '' || !state.address ? '.' : state.address;
			payload.address_desc = addressRef.current.value;
		}

		const _token = localStorage.getItem('AUTH_TOKEN');
		const link = `${interceptor.conf.baseURL}/api/profile`;
		setState((s) => ({...s, checkoutLoading: true}))

		return axios.post(link, {
			name: nameRef.current.value,
			email: emailRef.current.value,
			last_name: lastNameRef.current.value,
		}, {
			headers: {
				'Content-Language': getLangCode(),
				'Authorization': `Bearer ${_token}`,
				...interceptor.headers
			}
		})
			.then(function (response) {
				setTimeout(() => {
					dispatch({type: AUTH.UPDATE_PROFILE, payload: response.data})
					dispatch({type: CART.MAKE_ORDER, payload, callback: ({status, id, link, data}) => {
							setState((s) => ({...s, checkoutLoading: false}))
							if(status === 'DONE'){
								document.location.href= `/thanks?order=${id}`;
							}
							if(status === 'PAYMENT'){
								window.location.href = link
							}

							if(status === 'BILL_REFERENCE'){
								document.location.href= `/ref?id=${data.ref}&order=${data.order_id}`;
							}
						} })
				}, 100)
			})
			.catch(function (error) {
				if(error.response.data.message === "Unauthenticated."){
					localStorage.clear();
					document.location.href="/";
				}else {
					setState((s) => ({...s, error: t('Verify that the data is correct'), checkoutLoading: false}))
				}
			});
	}

	return (
		<section className="offer-dedicated-body mt-4 mb-4 pt-2 pb-2">
			<AddAddressModal show={state.showAddressModal} onHide={hideAddressModal}/>
			<Container>
				<Row>
					<Col md={8}>
						<div className="offer-dedicated-body-left">
							<div className="pt-2" />
							<div className="bg-white rounded shadow-sm p-4 mb-4">
								<Row>
									<Col md={4}>
										<Form>
											<div className="form-row">
												<Form.Group className="col-md-12">
													<Form.Label><Trans>First Name</Trans></Form.Label>
													<Form.Control defaultValue={name} type="text" placeholder={t('Enter First Name')} ref={nameRef} />
												</Form.Group>
											</div>
										</Form>
									</Col>
									<Col md={4}>
										<Form>
											<div className="form-row">
												<Form.Group className="col-md-12">
													<Form.Label><Trans>Last Name</Trans></Form.Label>
													<Form.Control defaultValue={last_name} type="text" placeholder={t('Enter Last Name')} ref={lastNameRef} />
												</Form.Group>
											</div>
										</Form>
									</Col>
									<Col md={4}>
										<Form>
											<div className="form-row">
												<Form.Group className="col-md-12">
													<Form.Label><Trans>Mobile</Trans>
													</Form.Label>
													<Form.Control value={phoneNumber} type="text" placeholder={t('Enter Mobile number')} ref={mobileRef} readOnly={phoneNumber}/>
												</Form.Group>
											</div>
										</Form>
									</Col>

									<Col md={12}>
										<Form>
											<div className="form-row">
												<Form.Group className="col-md-12">
													<Form.Label><Trans>Email</Trans>
													</Form.Label>
													<Form.Control defaultValue={email} type="text" placeholder={t('Enter Email Address')} ref={emailRef} />
												</Form.Group>
											</div>
										</Form>
									</Col>

									<Col md={12}>
										<Form>
											<div className="form-row">
												<Form.Group className="col-md-12">
													<Form.Label><Trans>More Details</Trans>
													</Form.Label>
													<Form.Control as="textarea" type="text" placeholder={t('Enter More Details')} ref={moreDetailsRef}/>
												</Form.Group>
											</div>
										</Form>
									</Col>
								</Row>
							</div>
							<div className="pt-2" />

							{
								query.get('type') != 2 && (query.get('lat') || query.get('area') !== 'null') && (
									<div className="bg-white rounded shadow-sm p-4 mb-4">
										<h4 className="mb-1"><Trans>delivery address</Trans></h4>
										{
											state.address && (
												<h5 className="mb-3 text-black-50">{state.address}</h5>
											)
										}

										<Row>
											<Col md={12}>
												<Form>
													<div className="form-row">
														<Form.Group className="col-md-12">
															{
																(query.get('area') && query.get('area') !== 'null')? (
																	<Row>
																		<Col md={12} className={'mb-2 mt-2'}>
																			<Map
																				id={'map-overlay-box'}
																				height={300}
																				showSearch={true}
																				disabled={false}
																				onChange={({lat, lng, address}) => {
																					setState((s) => ({
																						...s,
																						address,
																						userLocation: {
																							lat, lng
																						}
																					}))
																				}}
																			/>
																		</Col>
																	</Row>
																) : ('')
															}

															{
																((query.get('lat') && query.get('lng'))) ? (
																	<Row>
																		<Col md={12} className={'mb-2 mt-2'}>
																			<Map
																				id={'map-overlay-box'}
																				height={300}
																				showSearch={false}
																				disabled={true}
																				initLocation={state.userLocation}
																				onChange={({lat, lng, address}) => {
																					setState((s) => ({
																						...s,
																						address,
																						userLocation: {
																							lat, lng
																						}
																					}))
																				}}
																				 />
																		</Col>
																	</Row>
																) : ('')
															}
															<h6 className="text-black-50">{
																((query.get('area') && query.get('area') !== 'null') || (query.get('lat') && query.get('lng'))) ?
																	<Trans>address description</Trans> :
																	<Trans>Enter the address</Trans>
															}</h6>
															<Form.Control type="text" placeholder={
																((query.get('area') && query.get('area') !== 'null') || (query.get('lat') && query.get('lng'))) ? t('address description') : t('Enter the address')} ref={addressRef} className={'mt-3'} />
														</Form.Group>
													</div>
												</Form>
											</Col>
										</Row>
									</div>
								)
							}

							<Row>
								<Col md={12}>
									{
										makeOrderStatus === 'ERROR' ? (
											<div role="alert" className="fade alert alert-danger show">{typeof error === "string" ? error : t('Something went wrong')}</div>
										) : ''
									}
									{
										state.error ? (
											<div role="alert" className="fade alert alert-danger show">{state.error}</div>
										) : ''
									}
								</Col>
							</Row>



							<div className="pt-2" />
							<Payment onChange={(e) => {
								setState((s) => ({...s, paymentWay: e}))
							}} onChangeMobile={(val) => {
								setPhoneNumber(val)
							}} />
						</div>
					</Col>
					<Col md={4}>
						<div className="generator-bg rounded shadow-sm mb-4 p-4 osahan-cart-item">

							<h5 className="mb-1 text-white d-flex justify-content-between mb-2">
								<span><Trans>Your Order</Trans></span>
								{
									addToCartStatus === 'WAIT' ? (
										<Loader
											type="Oval"
											color="#fff"
											height={20}
											width={20}
										/>
									) : ''
								}
							</h5>

								{
									products ? (
											<div className="bg-white rounded shadow-sm mb-2 pt-3">
													<div className="bg-white rounded shadow-sm mb-2">
														{
															products.map(({id, total_price, quantity, product, ...item}) => (
																<CheckoutItem
																	item={item}
																	itemName={product.name}
																	price={total_price}
																	priceUnit={settings ? settings?.currency_name : ''}
																	id={id}
																	key={id}
																	qty={quantity}
																	show={true}
																/>
															))
														}
													</div>
											</div>
									) : ''
								}

							{
								!total_quantity ? (
									<div className="bg-white rounded shadow-sm mb-2 d-flex justify-content-center align-items-center flex-column" style={{paddingTop: 50, paddingBottom: 50}}>
										<p style={{fontSize: 50}}>
											<BsMinecartLoaded />
										</p>
										<p style={{fontSize: 16, textAlign: 'center'}}><Trans>Add items into your basket</Trans></p>
									</div>
								) : ''
							}

							{
								total_quantity ? (
									<>
										<div className="mb-2 bg-white rounded p-2 clearfix">
											<Coupon />
										</div>
										<div className="mb-2 bg-white rounded p-2 clearfix">
											{
												!(coupon?.data && coupon.status === 'DONE') && (
													<>
														<p className="mb-1"><Trans>Item Total</Trans><span className="float-right text-dark">{Number(price).toFixed(2)} {settings ? settings?.currency_name : ''}</span></p>
														{Number(branch_vat_number) !== 0 && (
															<p className="mb-1"><Trans>Restaurant Charges</Trans> <span className="float-right text-dark">{Number(branch_vat_number).toFixed(2)} {settings ? settings?.currency_name : ''} </span></p>
														)}
														{
															Number(delivery_fee) !== 0 && (
																<p className="mb-1">
																	<Trans>Delivery Fee</Trans>
																	<span className="float-right text-dark"> {delivery_fee} {settings ? settings?.currency_name : ''}</span>
																</p>
															)
														}
														<h6 className="font-weight-bold mb-0"><Trans>TO PAY</Trans>
															<span className="float-right"> {Number(total_price).toFixed(2)
															} {settings ? settings?.currency_name : ''}</span>
														</h6>
													</>
												)
											}


											{
												(coupon?.data && coupon.status === 'DONE') && (
													<>
														<p className="mb-1"><Trans>Item Total</Trans><span className="float-right text-dark">{coupon.data.price} {settings ? settings?.currency_name : ''} </span></p>
														<p className="mb-1 text-success"><Trans>Total Discount</Trans>
															<span className="float-right text-success"> {coupon.data.coupon.value} {coupon.data.coupon.type === 'percent' ? '%' : settings ? settings?.currency_name : ''}</span>
														</p>
														<p className="mb-1"><Trans>Price after discount</Trans><span className="float-right text-dark">{coupon.data.price_after_discount} {settings ? settings?.currency_name : ''} </span></p>
														{
															Number(coupon.data.branch_vat_number) !== 0 && (
																<p className="mb-1"><Trans>Restaurant Charges</Trans>
																	<span className="float-right text-dark">
																		{Number(coupon.data.branch_vat_number).toFixed(2)} {settings ? settings?.currency_name : ''}
																	</span>
																</p>
															)
														}

														{
															Number(coupon.data.delivery_fee) !== 0 && (
																<p className="mb-1">
																	<Trans>Delivery Fee</Trans>
																	<span className="float-right text-dark"> {coupon.data.delivery_fee} {settings ? settings?.currency_name : ''}</span>
																</p>
															)
														}


														<hr />
														<h6 className="font-weight-bold mb-0"><Trans>TO PAY</Trans>
															<span className="float-right"> {coupon.data.total_price.toFixed(2)} {settings ? settings?.currency_name : ''}</span>
														</h6>
													</>
												)
											}

										</div>

										{
											(branche && query.get('type') != 2) && branche.delivery_time !== '0:00' ? (
												<>
													<div className="pt-2" />
													<div className="alert alert-success d-flex justify-content-between" role="alert">
														<span>
															<AiOutlineClockCircle /> <Trans>Delivery time</Trans>
														</span>
														<span>
															<strong>{branche.delivery_time} <Trans>MIN</Trans></strong>
														</span>
													</div>
												</>
											) : <></>
										}


										<OverlayTrigger
											key={'checkout-btn'}
											placement={'bottom'}
											className={'checkout-btn'}
											overlay={
												(query.get('type') != 2 && branche.delivery_min && Number(branche.delivery_min) > total_price) ? (
													<Tooltip id={'checkout-btn'}>
														<Trans>Minimum order</Trans> {settings ? settings?.currency_name : ''} {Number(branche.delivery_min)}
													</Tooltip>
												) : <div />
											}
											defaultShow={false}>
											<button onClick={(query.get('type') != 2 && branche.delivery_min && Number(branche.delivery_min) > total_price) ? () => {} : checkout}
															className="d-flex justify-content-center btn btn-success btn-block btn-lg">

												{
													(coupon?.data && coupon.status === 'DONE') && (
														<>
															${coupon.data.total_price.toFixed(2)} {settings ? settings?.currency_name : ''}
														</>
													)
												}

												{
													!(coupon?.data && coupon.status === 'DONE') && (
														<>
															{settings ? settings?.currency_name : ''} {total_price.toFixed(2)}
														</>
													)
												}

												{ state.checkoutLoading && <Loader
													className={'mx-3'}
													type="Oval"
													color="#ffffff"
													height={15}
													width={15}
												/> }

												{ !state.checkoutLoading && <Icofont icon="long-arrow-right" /> }

											</button>
										</OverlayTrigger>
									</>
								) : ''
							}
						</div>





						{
							state.coupon && (
								<div className="bg-white rounded shadow-sm text-white mb-4 p-4 clearfix restaurant-detailed-earn-pts card-icon-overlap">
									<Image fluid className="float-left mr-3" src="/img/earn-score-icon.png" />
									<h6 className="pt-0 text-primary mb-1 font-weight-bold"><Trans>Offer</Trans></h6>
									<p className="mb-0">{`${t('This offer is available from date')} ${state.coupon.start_date} ${t('to date')} ${state.coupon.end_date} ${t('for a value')} ${state.coupon.value} ${state.coupon.type === 'fixed' ? settings?.currency_name : '%'} ${state.coupon.limit ? `${t('A maximum')}`+state.coupon.limit : ''} `} | <Trans>Use coupon</Trans> <span className="text-danger font-weight-bold">{' '}{state.coupon.code}
										<OverlayTrigger
											key={'checkout-btn'}
											placement={'bottom'}
											className={'checkout-btn'}
											overlay={
												<Tooltip id={'checkout-btn'}>
													<Trans>Copy Code to Clipboard</Trans>
												</Tooltip>
											}
											defaultShow={false}>
												<Button style={{
													fontSize: '14px',
													color: 'red'
												}} onClick={() => navigator.clipboard.writeText(state.coupon.code)}
																variant="link" className="card-btn mx-1 p-0"><Icofont icon="ui-copy" /></Button>
											</OverlayTrigger>
										</span></p>

								</div>
							)
						}

					</Col>
				</Row>
			</Container>
		</section>
	);
}

export default Checkout;
