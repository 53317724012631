import React, {useEffect, useRef, useState} from 'react';
import {Link, useHistory} from 'react-router-dom';
import {Row, Col, Container, Form, Alert, Spinner} from 'react-bootstrap';
import {useDispatch, useSelector} from "react-redux";
import firebase from "firebase/compat";
import {AUTH} from "../redux/constants/auth";
import {getLangCode} from "../services/getLangCode";
import interceptor from "../services/interceptors/default-interceptor";
import OrderNotRated from "./modals/OrderNotRated";
import {CART} from "../redux/constants/cart";
import {Trans, useTranslation} from "react-i18next";
import useQuery from "../hooks/useQuery";
function convertNumber(fromNum) {
	var result;
	var arabicMap = {
		'٩': 9,
		'٨': 8,
		'٧': 7,
		'٦': 6,
		'٥': 5,
		'٤': 4,
		'٣': 3,
		'٢': 2,
		'١': 1,
		'٠': 0
	};
	result = arabicMap[fromNum];
	if (result === undefined) {
		result = fromNum;
	}
	return result;
}

const Login = () => {
	const query = useQuery();
	const history = useHistory();
	const dispatch = useDispatch();
	const debug = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';
	const InputRef = useRef(null);
	const codeRef = useRef(null);
	const SelectRef = useRef(null);
	const confirmCode = useRef(null);
	const country = localStorage.getItem('country');
	const {t} = useTranslation()
	const {data} = useSelector((s) => s.mainInformation.settings);
	const [state, setState] = useState({
		validated: false,
		showVerifier: false,
		loading: false,
		error: null,
		phone: null,
		orderNotRated: null,
		showOrderNotRated: null
	})

	useEffect(() => {
		window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('sign-in-button', {
			'size': 'invisible',
		});
	}, [])


	const signUp = (event) => {
		event.preventDefault();
		event.stopPropagation();
		setState((s) => ({...s,  error: null}))
		if (!InputRef.current.value || InputRef.current.value === '' ) {
			setState((s) => ({...s, error: t('Phone number is incorrect')}))
			return
		}
		const value = InputRef.current.value.replace(/ /g, '').split('').map((i) => {
			return convertNumber(i)
		}).join().replace(/,/g, '');
		setState((s) => ({...s, validated: true, error: null, loading: true, phone: `+${SelectRef.current.value}${value}`}))

		dispatch({type: AUTH.SIGNUP, payload: {
				phoneNumber: `+${SelectRef.current.value}${value}`,
				appVerifier: window.recaptchaVerifier,
				callback: (verificationId, confirm) => {
					if(verificationId && confirm){
						confirmCode.current = confirm;
						setState((s) => ({...s, showVerifier: true, loading: false}))
					}else {
						setState((s) => ({...s, error: t('Phone number is incorrect'), loading: false}))
					}
				}
			}})
	}


	const searchString =  localStorage.getItem('search');
	const confirm = (event) => {
		event.preventDefault();
		event.stopPropagation();

		if (!codeRef.current.value || codeRef.current.value === '') {
			setState((s) => ({...s, error: t('Please enter Verification Code')}))
			return
		}

		setState((s) => ({...s, validated: true, error: null, loading: true}));

		const value = codeRef.current.value.replace(/ /g, '').split('').map((i) => {
			return convertNumber(i)
		}).join().replace(/,/g, '');

		confirmCode.current(value).then((result) => {
			const {metadata, refreshToken, tenantId, phoneNumber, multiFactor: {user: {accessToken}}} = result.user;
			const url = `${interceptor.conf.baseURL}/api/auth`;
			fetch(url, {
				method: 'POST',
				body: JSON.stringify({
					Firebasetoken: accessToken,
					phone: phoneNumber
				}),
				headers: {
					'Content-Type': 'application/json',
					'Content-Language': getLangCode(),
					...interceptor.headers
				}
			})
				.then(response => response.json())
				.then(data => {
					dispatch({type: AUTH.VERIFICATION_CODE, payload: {metadata: {...metadata, ...data.user}, refreshToken, accessToken, token: data.token, tenantId, phoneNumber}})
					setState((s) => ({...s, loading: false}));
					setTimeout(() => {
						dispatch({type: CART.GET_ORDERS_NOT_RATED, payload: {callback: (res) => {
							if(res.length > 0){
								setState((s) => ({...s, orderNotRated: res, showOrderNotRated: true}))
							}else {
								if(query.get('redir') === 'checkout'){
									// history.push(`/checkout?${searchString}`);
									window.location.href = `${window.location.origin}/checkout?${searchString}`
								}else {
									window.location.href = `${window.location.origin}/`
									// history.push('/')
								}
							}
						}}})
					}, 1000)
				})
				.catch((e) => {})
		}).catch((error) => {
			setState((s) => ({...s, loading: false, error: t('Verification Code is incorrect')}))
		});
	}


	return (
		<Container fluid className='bg-white'>
			<Row>
				<Col md={4} lg={6}
						 className="d-none d-md-flex bg-image"
						 style={{
							 backgroundImage: `${data && data.homepage && data.homepage.homepage_bg_img ? 'url(' + data.homepage.homepage_bg_img + ')' : ''}`
						 }}
				/>
				<Col md={8} lg={6}>
					<div className="login d-flex align-items-center py-5">
						<Container>
							<Row>
								<Col md={9} lg={8} className="mx-auto pl-5 pr-5">
									{
										state.showVerifier ? (
											<>
												<h3 className="login-heading mb-4"><Trans>Verification Code</Trans>!</h3>
												<Form  noValidate>
													<div className="form-label-group">

														{
															debug ? (
																<Form.Control value={'123456'} type="text" id="inputCode" name={'code'} placeholder="code" required ref={codeRef}/>
															) : (
																<Form.Control type="text" id="inputCode" name={'code'} placeholder="code" required ref={codeRef}/>
															)
														}
														<Form.Control.Feedback type="invalid">
															<Trans>Please enter Verification Code</Trans>
														</Form.Control.Feedback>
														<Form.Label htmlFor="inputCode"><Trans>Code</Trans></Form.Label>
													</div>
													<button
														onClick={confirm}
														id={'sent-code'}
														className="d-flex justify-content-center btn btn-lg btn-outline-primary btn-block btn-login text-uppercase font-weight-bold mb-2">

														{ state.loading ? (
															<Trans>
																Loading
															</Trans>
														) : (
															<Trans>
																Send
															</Trans>
														) }

														{ state.loading && <Spinner animation="grow" size="sm" className='mx-1 my-1' /> }

													</button>
													{
														state.error ? (
															<Alert variant="danger" role="alert">
																{state.error}
															</Alert>
														) : ''
													}
												</Form>
											</>

										) : (
											<>
												<h3 className="login-heading mb-4"><Trans>Welcome</Trans>  </h3>
												<h5><Trans>Phone number</Trans></h5>
												<Form  noValidate dir={'ltr'}>
													<div className={'d-flex'}>
														<div className="form-label-group">

															<Form.Control style={{width: '82px', textAlign: 'left'}} readOnly={true} type="text" ref={SelectRef} value={`+${JSON.parse(country)?.phone_cde ? JSON.parse(country)?.phone_cde : 2}`} name={'code'} required/>
														</div>
														<div className="form-label-group" style={{'flex': 1,'margin': '0 10px', textAlign: 'left'}}>
															{
																debug ? (
																	// <Form.Control type="number" id="inputPhone"  value={'505338442'} name={'phone'} placeholder="Phone" required ref={InputRef}/>
																	<Form.Control type="number" id="inputPhone" value={'01062092532'} name={'phone'} placeholder="Phone" required ref={InputRef}/>
																	// <Form.Control type="number" id="inputPhone" value={'01155630808'} name={'phone'} placeholder="Phone" required ref={InputRef}/>
																) : (
																	<Form.Control type="text" id="inputPhone" name={'phone'}  placeholder="Phone" required ref={InputRef}/>
																)
															}
															<Form.Control.Feedback type="invalid">
																<Trans>Please enter Phone number</Trans>
															</Form.Control.Feedback>
														</div>
													</div>
													<button
														onClick={signUp}
														id={'sign-in-button'}
														className="d-flex justify-content-center btn btn-lg btn-outline-primary btn-block btn-login text-uppercase font-weight-bold mb-2">

														{ state.loading ? (
															<Trans>
																Loading
															</Trans>
														) : (
															<Trans>
																Sign in
															</Trans>
														) }

														{ state.loading && <Spinner animation="grow" size="sm" className='mx-1 my-1' /> }
													</button>

													{
														state.error ? (
															<Alert variant="danger" role="alert">
																{state.error}
															</Alert>
														) : ''
													}
												</Form>
											</>
										)
									}
								</Col>
							</Row>
						</Container>
					</div>
				</Col>
			</Row>
			<OrderNotRated orders={state.orderNotRated} show={state.showOrderNotRated} onHide={() => {
				setState((s) => ({...s, showOrderNotRated: false}))
				if(query.get('redir') === 'checkout'){
					history.push(`/checkout?${searchString}`)
				}else {
					history.push('/')
				}
			}} />
		</Container>
	);
}


export default Login;
