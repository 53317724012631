import React from 'react';
import PropTypes from 'prop-types';
import {useSelector} from "react-redux";

const PageTitle = (props) => {
	const {data} = useSelector((s) => s.mainInformation.settings);
	return (
		<section className="breadcrumb-osahan pt-5 pb-5 bg-dark position-relative text-center" style={{
			backgroundImage: `${data && data.homepage && data.homepage.homepage_bg_img ? 'url(' + data.homepage.homepage_bg_img + ')' : ''}`,
			backgroundRepeat: 'no-repeat',
			backgroundSize: 'cover',
		}}>
			<h1 className="text-white">{props.title}</h1>
			{props.subTitle?
				<h6 className="text-white-50">{props.subTitle}</h6>
				:""
			}
		</section>
	);
}

PageTitle.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string
};
PageTitle.defaultProps = {
  subTitle: ''
}

export default PageTitle;
