import { API_BASE_URL } from '../../configs/app-config'

const service = {
  conf: {
    baseURL: API_BASE_URL,
    withCredentials: true,
    credentials: 'same-origin',
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
  },
  headers: {
    'Content-Type': 'application/json',
    // 'Access-Control-Allow-Origin': '*',
    // 'Referrer-Policy': 'origin-when-cross-origin',
    // "Access-Control-Expose-Headers": "x-pagination, Content-Length",
    // "Access-Control-Allow-Methods": "POST,GET,OPTIONS,PUT,DELETE",
    // "Access-Control-Allow-Headers": "Authorization, Content-Type, Content-Type, Accept, membership-id, account-id, X-Requested-With, Origin",
    // 'Access-Control-Allow-Credentials': 'true',
    // 'Cache-Control': 'no-cache'
  },
  toQueryString: (url, params) => {
    const _url = new URL(url);
    if(params){
      Object.keys(params).map(key => _url.searchParams.append(key, params[key]))
    }
    return _url.href
  },
  interceptorsRequest: {},
  interceptorsResponse: {}
}

export default service
