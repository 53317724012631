import React, {useEffect, useState} from 'react';
import {Row,Col} from 'react-bootstrap';
import {Trans} from "react-i18next";
import {CART} from "../../redux/constants/cart";
import {useDispatch, useSelector} from "react-redux";
import QuickBite from "../common/QuickBite";
import Skeleton from "react-loading-skeleton";
import {AiOutlineHeart} from "react-icons/ai";

const Favourites = () => {
	const [state, setState] = useState({
		list: [],
		status: 'WAIT'
	});
	const activeBranch = localStorage.getItem('branch_active') || 1
	const {data, status} = useSelector((s) => s.branches.branches);


	const dispatch = useDispatch();
	const settings = useSelector((s) => s.mainInformation.settings.data);

	useEffect(() => {
		dispatch({type: CART.GET_WISH_LIST, payload: {
			callback: (res) => {
				if(res){
					setState((s) => ({...s, list: res?.map((i) => i.products), status: 'DONE'}))
				}
			},
			callbackError: (err) => {
				setState((s) => ({...s, list: [], status: 'DONE'}))
			}
		}})
	}, []);


	const update = () => {
		dispatch({type: CART.GET_WISH_LIST, payload: {
				callback: (res) => {
					if(res){
						setState((s) => ({...s, list: res?.map((i) => i.products), status: 'DONE'}))
					}
				},
				callbackError: (err) => {
					setState((s) => ({...s, list: [], status: 'DONE'}))
				}
			}})
	}

	return (
		<>
			<div className='p-4 bg-white shadow-sm'>
				<Row>
					<Col md={12}>
						<h4 className="font-weight-bold mt-0 mb-3"><Trans>Favourites</Trans></h4>
					</Col>

					{
						state.status === 'DONE' && state.list.length === 0 ? (
							<Col md={12} sm={12} className="mb-4 pb-2 empty-section">
								<AiOutlineHeart fontSize={150} />
								<p><Trans>There are no favourites</Trans></p>
							</Col>
						) : ''
					}


					{
						state.status === 'DONE' ? (
							<Col md={12} sm={12} className="mb-4 pb-2">
								{
									state.list
										?.map(({is_sale, images, sale_amount, sale_price, sold_out, is_wishlist, name, description, image, price, productSizes, thumb, id, optionGroups, notice, calories}) => (
											<QuickBite
												images={images}
												is_sale={is_sale}
												sale_amount={sale_amount}
												sale_price={sale_price}
												key={id}
												id={id}
												itemClass="menu-list"
												thumb={thumb}
												title={name}
												price={price}
												priceUnit={settings ? settings?.currency_name : ''}
												description={description}
												productSizes={productSizes}
												optionGroups={optionGroups || []}
												notice={notice || ''}
												calories={calories}
												image={image}
												is_wishlist={is_wishlist}
												callback={update}
												available={!sold_out}
												errorForLocation={status === 'ERROR_FOR_LOCATION'}
												is_open={data?.filter((i) => i.id == activeBranch)[0].is_open}
											/>
										))
								}
							</Col>
						) : (
							<Col md={12} sm={12} className="mb-4 pb-2">
									<Skeleton width={'100%'} height={200}/>
									<Skeleton width={'100%'} height={200}/>
									<Skeleton width={'100%'} height={200}/>
							</Col>
						)
					}
				</Row>
			</div>
		</>
	);
}
export default Favourites;
