export const BRANCHES = {

  GET_BRANCHES: 'GET_BRANCHES',
  SET_BRANCHES: 'SET_BRANCHES',
  GET_BRANCHES_STATUS: 'GET_BRANCHES_STATUS',
  GET_BRANCHES_ERROR: 'GET_BRANCHES_ERROR',

  GET_MENU: 'GET_MENU',
  SET_MENU: 'SET_MENU',
  GET_MENU_STATUS: 'GET_MENU_STATUS',
  GET_MENU_ERROR: 'GET_MENU_ERROR',


  GET_DELIVERY_LOCATION: 'GET_DELIVERY_LOCATION',
  SET_DELIVERY_LOCATION: 'SET_DELIVERY_LOCATION',
  GET_DELIVERY_LOCATION_STATUS: 'GET_DELIVERY_LOCATION_STATUS',
  GET_DELIVERY_LOCATION_ERROR: 'GET_DELIVERY_LOCATION_ERROR',

  GET_MENU_FOR_LOCATION: 'GET_MENU_FOR_LOCATION'

}
