import React from 'react';
import TopSearch from '../home/TopSearch';

class Index extends React.Component {
	render() {
    	return (
    		<>
				  <TopSearch />
    		</>
    	);
    }
}
export default Index;
