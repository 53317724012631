import interceptor from './interceptors/default-interceptor';
import {getLangCode} from "./getLangCode";
import axios from "axios";
const CartService = {}


const urlToObj = (url) => {
  const _url = url.split('&');
  return _url.reduce((p, c) => {
    return {...p, [c.split('=')[0]]: c.split('=')[1]}
  }, {})
}

const calcExtraParams = () => {
  let delivery_way_id = null;
  let _params = {};

  if(localStorage.getItem('delivery_way_id')){
    delivery_way_id = localStorage.getItem('delivery_way_id')
    _params = {
      ..._params,
      delivery_way_id,
    }
  }


  if(localStorage.getItem('search')){
    const param = urlToObj(localStorage.getItem('search'));

    if(param.type === '1' && param?.lat && param?.lng){

      _params = {
        ..._params,
        orderType: '',
        latitude: param?.lat,
        longitude: param?.lng,
        delivery_way_id,
      }

      return _params
    }

    if(param.type === '1' && !param?.lat && !param?.lng && param?.area){
      _params = {
        ..._params,
        orderType: 'cityArea',
        areaId: param.area,
        delivery_way_id,
      }
      return _params
    }
    return {}
  }
  return {}
}

CartService.getCart = function (params) {
  return new Promise((resolve, reject) => {
    const link = `${interceptor.conf.baseURL}/api/cart`;
    const url = params.hash_key ? interceptor.toQueryString(link, {...params, ...calcExtraParams()}) : interceptor.toQueryString(link, {...calcExtraParams()});
    return fetch(url, {
      headers: {
        'Content-Language': getLangCode(),
        ...interceptor.headers
      }
    })
        .then(response => response.json())
        .then(data => resolve(data))
        .catch((e) => reject(e));
  })
}

CartService.getNewCart = function (params) {
  return new Promise((resolve, reject) => {
    const link = `${interceptor.conf.baseURL}/api/cart`;
    return fetch(link, {
      method: 'get',
      headers: {
        'Content-Language': getLangCode(),
        ...interceptor.headers
      }
    })
        .then(response => response.json())
        .then(data => resolve(data))
        .catch((e) => reject(e));
  })
}


CartService.activeCart = CartService.getCart;

CartService.addToCart = function (params) {
  const link = `${interceptor.conf.baseURL}/api/cart`;
  return axios.post(link, {...params, ...calcExtraParams()}, {
    headers: {
      'Accept': 'application/json',
      'Content-Type' : 'application/json',
      'Content-Language': getLangCode(),
      ...interceptor.headers
    }
  })
}

CartService.updateQty = function (params) {
  return new Promise((resolve, reject) => {
    const url = `${interceptor.conf.baseURL}/api/cart/update-qty`;
    return fetch(url, {
      method: 'POST',
      redirect: 'follow',
      body: JSON.stringify({...params, ...calcExtraParams()}),
      headers: {
        'Content-Type': 'application/json',
        'Content-Language': getLangCode(),
        ...interceptor.headers
      }
    })
        .then(response => response.json())
        .then(data => resolve(data))
        .catch((e) => reject(e));
  })
}

CartService.removeItem = function (params) {
  return new Promise((resolve, reject) => {
    const url = `${interceptor.conf.baseURL}/api/cart/remove-item`;
    return fetch(url, {
      method: 'POST',
      redirect: 'follow',
      body: JSON.stringify({...params, ...calcExtraParams()}),
      headers: {
        'Content-Type': 'application/json',
        'Content-Language': getLangCode(),
        ...interceptor.headers
      }
    })
        .then(response => response.json())
        .then(data => resolve(data))
        .catch((e) => reject(e));
  })
}

CartService.CouponValidity = function (params) {
  const url = `${interceptor.conf.baseURL}/api/coupon-validity`;
  const _token = localStorage.getItem('AUTH_TOKEN');
  return axios.post(url, params, {
    headers: {
      'Content-Type' : 'application/json',
      'Content-Language': getLangCode(),
      'Authorization': `Bearer ${_token}`,
      ...interceptor.headers
    }
  })
}

CartService.MakeOrder = function (params) {
  return new Promise( (resolve, reject) => {
    const _token = localStorage.getItem('AUTH_TOKEN');
    const url = `${interceptor.conf.baseURL}/api/make-order`;
    return fetch(url, {
      method: 'POST',
      body: JSON.stringify({...params, platform: 'web', ...calcExtraParams()}),
      headers: {
        'Content-Type': 'application/json',
        'Content-Language': getLangCode(),
        'Authorization': `Bearer ${_token}`,
        ...interceptor.headers
      }
    })
        .then(response => response.json())
        .then(data => resolve(data))
        .catch((e) => {
          reject(e)
        });
  })
}

CartService.cancelOrder = function (params) {
  return new Promise((resolve, reject) => {
    const _token = localStorage.getItem('AUTH_TOKEN');
    const url = `${interceptor.conf.baseURL}/api/cancel-order/orders/${params.id}`;
    return fetch(url, {
      method: 'GET',
      redirect: 'follow',
      body: JSON.stringify(params),
      headers: {
        'Content-Type': 'application/json',
        'Content-Language': getLangCode(),
        'Authorization': `Bearer ${_token}`,
        ...interceptor.headers
      }
    })
        .then(response => response.json())
        .then(data => resolve(data))
        .catch((e) => reject(e));
  })
}


CartService.orders = function (params) {
  return new Promise( (resolve, reject) => {
    const _token = localStorage.getItem('AUTH_TOKEN');
    const url = `${interceptor.conf.baseURL}/api/orders`;

    return fetch(url, {
      method: 'GET',
      redirect: 'follow',
      body: JSON.stringify(params),
      headers: {
        'Content-Type': 'application/json',
        'Content-Language': getLangCode(),
        'Authorization': `Bearer ${_token}`,
        ...interceptor.headers
      }
    })
        .then(response => response.json())
        .then(data => resolve(data))
        .catch((e) => reject(e));
  })
}


CartService.order = function (params) {
  return new Promise((resolve, reject) => {
    const _token = localStorage.getItem('AUTH_TOKEN');
    const url = `${interceptor.conf.baseURL}/api/orders/${params.id}`;

    return fetch(url, {
      method: 'get',
      redirect: 'follow',
      headers: {
        'Content-Type': 'application/json',
        'Content-Language': getLangCode(),
        'Authorization': `Bearer ${_token}`,
        ...interceptor.headers
      }
    })
        .then(response => response.json())
        .then(data => resolve(data))
        .catch((e) => reject(e));
  })
}

CartService.addToWishList = function (params) {
  return new Promise((resolve, reject) => {
    const _token = localStorage.getItem('AUTH_TOKEN');
    const url = `${interceptor.conf.baseURL}/api/wish-list/add`;
    return fetch(url, {
      method: 'post',
      redirect: 'follow',
      body: JSON.stringify(params),
      headers: {
        'Content-Type': 'application/json',
        'Content-Language': getLangCode(),
        'Authorization': `Bearer ${_token}`,
        ...interceptor.headers
      }
    })
        .then(response => response.json())
        .then(data => resolve(data))
        .catch((e) => reject(e));
  })
}

CartService.removeFromWishList = function (params) {
  return new Promise((resolve, reject) => {
    const _token = localStorage.getItem('AUTH_TOKEN');
    const url = `${interceptor.conf.baseURL}/api/wish-list/remove`;
    return fetch(url, {
      method: 'post',
      redirect: 'follow',
      body: JSON.stringify(params),
      headers: {
        'Content-Type': 'application/json',
        'Content-Language': getLangCode(),
        'Authorization': `Bearer ${_token}`,
        ...interceptor.headers
      }
    })
        .then(response => response.json())
        .then(data => resolve(data))
        .catch((e) => reject(e));
  })
}

CartService.getWishList = function (params) {
  return new Promise((resolve, reject) => {
    const _token = localStorage.getItem('AUTH_TOKEN');
    const branch = localStorage.getItem('branch_active');
    const url = `${interceptor.conf.baseURL}/api/wish-list/branch/${branch}`;
    return fetch(url, {
      method: 'get',
      redirect: 'follow',
      headers: {
        'Content-Type': 'application/json',
        'Content-Language': getLangCode(),
        'Authorization': `Bearer ${_token}`,
        ...interceptor.headers
      }
    })
        .then(response => response.json())
        .then(data => resolve(data))
        .catch((e) => reject(e));
  })
}

CartService.getOrdersNotRated = function () {
  return new Promise((resolve, reject) => {
    const _token = localStorage.getItem('AUTH_TOKEN');
    const url = `${interceptor.conf.baseURL}/api/orders/not-rated`;
    return fetch(url, {
      method: 'get',
      redirect: 'follow',
      headers: {
        'Content-Language': getLangCode(),
        'Authorization': `Bearer ${_token}`,
        ...interceptor.headers
      }
    })
        .then(response => response.json())
        .then(data => resolve(data))
        .catch((e) => reject(e));
  })
}



export default CartService

