import React, {useRef, useState, useEffect} from 'react';
import {Row, Col, Container, Form, Spinner} from 'react-bootstrap';
import Icofont from 'react-icofont';
import {Trans, useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import MainInformationService from "../../services/main-information";
import {GoogleApiWrapper} from 'google-maps-react';

const ContactUs = (props) => {
	const [state, setState] = useState({
		status: null,
		error: null,
		hasBeenSent: false,
		latitude: null,
		longitude: null,
		center: {
			latitude: null,
			longitude: null,
		},
		data: null
	})
	const {t} = useTranslation()
	const nameRef = useRef();
	const phoneRef = useRef();
	const emailRef = useRef();
	const messageRef = useRef();
	const settings = useSelector((s) => s.mainInformation.settings.data);
	const {data} = useSelector((s) => s.branches.branches);

	const sent = () => {
		setState((s) => ({...s, status: 'WAIT', error: null, hasBeenSent: false}))
		if(
			(!nameRef.current.value || nameRef.current.value === '') ||
			(!phoneRef.current.value || phoneRef.current.value === '') ||
			(!emailRef.current.value || emailRef.current.value === '') ||
			(!messageRef.current.value || messageRef.current.value === '')
		){
			setState((s) => ({...s, status: 'ERROR', error: t('All fields are required')}))
			return
		}

		const params = {
			name: nameRef.current.value,
			phone: phoneRef.current.value,
			email: emailRef.current.value,
			message: messageRef.current.value,
		}

		MainInformationService.contact(params).then((res) => {
			setState((s) => ({...s, status: 'DONE', error: null, hasBeenSent: true}))
		}).catch((e) => {
			if(e.response.status === 422){
				setState((s) => ({...s, status: 'ERROR', error: Object.values(e.response.data.errors)[0]}))
				return
			}
			setState((s) => ({...s, status: 'ERROR', error: t('Something went wrong')}))
		})
	}

	const branch_active = localStorage.getItem('branch_active')
	const branch = useSelector((s) => {

		if(branch_active && s.branches.branches?.data?.filter((i) => i?.id == branch_active)?.length > 0){
			return s.branches.branches?.data?.filter((i) => i?.id == branch_active)[0]
		}

		if((!branch_active || !s.branches.branches?.data?.map((i) => i?.id).includes(branch_active)) && s?.branches?.branches?.data?.length > 0){
			return s?.branches?.branches?.data[0]
		}

	});

	useEffect(() => {
		if(data && data.length > 0){
			setState((s) => ({...s, latitude: branch?.latitude, longitude: branch?.longitude, data: branch}))
		}
		return () => {
			setState((s) => ({...s, latitude: null, longitude: null}))
		}
	}, [data, branch])


	const desktopStyle = window.innerWidth > 991 ? {
		top: '110px',
		maxHeight: 'calc(100vh - 110px)',
	} : {
		top: 0,
		position: 'absolute',
		minHeight: '100vh',
	}

	return (
		<section className="section bg-white osahan-track-order-page position-relative" >

			<div className="contact-us-map" id={'branch_map'}
				 style={{
					 width: '100vw',
					 backgroundImage: `${settings ? `url('${window.innerWidth < 991 ? settings.background_mobile : settings.background_web}')` : ''}`,
					 backgroundRepeat: 'no-repeat',
					 backgroundPosition: 'center',
					 backgroundSize: '100% 100%',
					 bottom: '0',
					 right: '0',
					 left: '0',
					 position: 'fixed',
					 ...desktopStyle
			}}>
				{/*{*/}
				{/*	(state.latitude && state.longitude) && (*/}
				{/*		<>*/}
				{/*			<Map ref={mapRef} google={props.google}*/}
				{/*					 zoom={15}*/}
				{/*					 initialCenter={{lat: state.latitude, lng: (dir === 'rtl' ? state.longitude - 0.009 : state.longitude + 0.009)}}*/}
				{/*					 center={{lat: state.latitude, lng: (dir === 'rtl' ? state.longitude - 0.009 : state.longitude + 0.009)}}*/}
				{/*					 style={{width: '100%', height: '100%', position: 'relative'}}*/}
				{/*			>*/}
				{/*				<Marker*/}
				{/*					position={{lat: state.latitude, lng: state.longitude}} />*/}
				{/*			</Map>*/}
				{/*		</>*/}
				{/*	)*/}
				{/*}*/}
			</div>

			<Container className="pt-5 pb-5">
				<Row className="d-flex align-items-center">
					<Col md={3} className="text-center pb-4">
						{/*<div className="osahan-point mx-auto"></div>*/}
					</Col>
					<Col md={6}>
						<div className="bg-white p-4 pb-3 shadow-lg mb-2">
							<h5 className="mb-2"><Trans>Contact Us</Trans></h5>
							<h6 className="mb-1 mt-1 text-center">
								<Form.Group className="col-md-12">
									<Form.Label><Trans>Name</Trans></Form.Label>
									<Form.Control type="text"  placeholder="Enter Name" ref={nameRef}/>
								</Form.Group>
								<Form.Group className="col-md-12">
									<Form.Label><Trans>Phone number</Trans></Form.Label>
									<Form.Control type="number" placeholder="Enter Phone number" ref={phoneRef}/>
								</Form.Group>
								<Form.Group className="col-md-12">
									<Form.Label><Trans>Email</Trans></Form.Label>
									<Form.Control type="email" placeholder="Enter Email" ref={emailRef}/>
								</Form.Group>
								<Form.Group className="col-md-12">
									<Form.Label><Trans>Message</Trans></Form.Label>
									<Form.Control as="textarea" type="text" placeholder="message" ref={messageRef}/>
								</Form.Group>
								<Form.Group className="col-md-12">
									<button onClick={sent} className="btn btn-success btn-block btn-lg">
										{
											state.status !== 'WAIT' ? (
												<>

													<Trans>SEND</Trans>
												</>
											) : (
												<>
													<Spinner animation="grow" size="sm" className='mx-1' />
													<Trans>LOADING</Trans>
												</>
											)
										}
										<Icofont icon="long-arrow-right" />
									</button>

									{
										state.error ? (
											<div className={'mt-2'}>
												<div role="alert" className="fade alert alert-danger show">{state.error}</div>
											</div>
										) : ('')
									}

									{
										state.hasBeenSent ? (
											<div className={'mt-2'}>
												<div role="alert" className="fade alert alert-success show"><Trans>has been sent</Trans></div>
											</div>
										) : ('')
									}

								</Form.Group>
							</h6>
						</div>

						<div className="bg-white p-4 shadow-lg mt-2">
							<Row className="text-center">
								{
									state?.data?.mobile && (
										<Col>
											<Icofont icon="phone" className="icofont-3x text-info"/>
											<p className="mt-1 font-weight-bold text-dark text-center mb-0">{state.data.mobile}</p>
											<small className="text-info mb-0"><Trans>CALL</Trans></small>
										</Col>
									)
								}
								{
									state?.data?.email && (
										<Col>
											<Icofont icon="email" className="icofont-3x text-success"/>
											<p className="mt-1 font-weight-bold text-dark  text-center mb-0">{state.data.email}</p>
											<small className="text-success mb-0"><Trans>E-MAIL</Trans></small>
										</Col>
									)
								}
								{
									state?.data?.address && (
										<Col>
											<Icofont icon="pin" className="icofont-3x text-primary"/>
											<p className="mt-1 font-weight-bold text-dark  text-center mb-0">{state.data.address}</p>
											<small className="text-primary mb-0"><Trans>ADDRESS</Trans></small>
										</Col>
									)
								}
							</Row>
						</div>
					</Col>
					<Col md={3} className="text-center pb-4">
						{/*<div className="osahan-point mx-auto"></div>*/}
					</Col>
				</Row>
			</Container>
		</section>
	);
}

const LoadingContainer = () => (
	<section className="section bg-white osahan-track-order-page position-relative">
		<div className="position-absolute contact-us-map" id={'branch_map'} style={{
			minHeight: '100vh',height: '100%', width: '100vw',
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
		}}>
			<Spinner animation="grow" size="lg" className='mx-1 my-1' />
		</div>
	</section>
)

const debug = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';
let opt = { }

if(process.env.REACT_APP_WEBSITE_NAME === 'CARRYOUT'){
	opt = {
		apiKey: 'AIzaSyCkxkHGSSdW5V6rAuCZynQ3Cst0RF8kgZM'
	}
}

if(process.env.REACT_APP_WEBSITE_NAME === 'FLYORDER'){
	opt = {
		apiKey: 'AIzaSyCkxkHGSSdW5V6rAuCZynQ3Cst0RF8kgZM'
	}
}
export default GoogleApiWrapper(opt)(ContactUs)
