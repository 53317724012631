import React, {useState} from 'react';
import {Switch, Route, useHistory} from 'react-router-dom';
import {NavLink,Link } from 'react-router-dom';
import {Row,Col,Container,Image} from 'react-bootstrap';
import Offers from './myaccount/Offers';
import Orders from './myaccount/Orders';
import Favourites from './myaccount/Favourites';
import Payments from './myaccount/Payments';
import Addresses from './myaccount/Addresses';
import EditProfileModal from './modals/EditProfileModal';
import {useDispatch, useSelector} from "react-redux";
import {AUTH} from "../redux/constants/auth";
import {Trans, useTranslation} from "react-i18next";
const MyAccount = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector((s) => s.auth.user);
  const [state, setState] = useState({
    showEditProfile: false
  });
  const {t} = useTranslation();
  const hideEditProfile = () => setState({ showEditProfile: false });

  const logout = () => {
    localStorage.clear();
    dispatch({type: AUTH.SIGNOUT});
    document.location.href="/";
  }

  return (
    <>
      <EditProfileModal show={state.showEditProfile} onHide={hideEditProfile}/>
      <section className="section pt-4 pb-4 osahan-account-page">
        <Container>
          <Row>
            <Col md={3}>
              <div className="osahan-account-page-left shadow-sm bg-white h-100">
                <div className="border-bottom p-4">
                  <div className="osahan-user text-center">
                    <div className="osahan-user-media">
                      {/*<Image className="mb-3 shadow-sm mt-1" src="/img/user/4.png" alt="gurdeep singh osahan" />*/}
                      <div className="osahan-user-media-body">
                        {
                          user?.metadata?.name ? (
                            <h6 className="mb-2">{user?.metadata?.name} {user?.metadata?.last_name}</h6>
                          ) : ''
                        }
                        <p className="mb-1">{user?.phoneNumber}</p>
                        <p className="mb-0 text-black font-weight-bold">

                          <p className="mb-0 text-black font-weight-bold">
                            <Link to='#'
                                  onClick={() => setState({ showEditProfile: true })}
                                  className="text-primary mx-1">
                              <i className="icofont-ui-edit" /> <Trans>EDIT</Trans></Link>

                            <a href='javascript:void(0)'
                                  onClick={logout}
                                  className="text-primary mx-1">
                              <i className="fa fa-sign-out" /> <Trans>LOGOUT</Trans></a>
                          </p>

                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <ul className="nav flex-column border-0 pt-4 pl-4 pb-4">
                  <li className="nav-item">
                    <NavLink className="nav-link" activeClassName="active" exact to="/myaccount/orders"><i className="icofont-food-cart"></i> <Trans>Orders</Trans></NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink  className="nav-link" activeClassName="active" exact to="/myaccount/offers"><i className="icofont-sale-discount"></i> <Trans>coupons</Trans></NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink className="nav-link" exact to="/myaccount/favourites"><i className="icofont-heart"></i> <Trans>Favourites</Trans></NavLink>
                  </li>
                  {/*<li className="nav-item">*/}
                  {/*  <NavLink className="nav-link" activeClassName="active" exact to="/myaccount/payments"><i className="icofont-credit-card"></i> Payments</NavLink>*/}
                  {/*</li>*/}
                  {/*<li className="nav-item">*/}
                  {/*  <NavLink className="nav-link" activeClassName="active" exact to="/myaccount/addresses"><i className="icofont-location-pin"></i> Addresses</NavLink>*/}
                  {/*</li>*/}
                </ul>
              </div>
            </Col>
            <Col md={9}>
              <Switch>
                <Route path="/myaccount/orders" exact component={Orders} />
                <Route path="/myaccount/offers" exact component={Offers} />
                <Route path="/myaccount/favourites" exact component={Favourites} />
                {/*<Route path="/myaccount/payments" exact component={Payments} />*/}
                {/*<Route path="/myaccount/addresses" exact component={Addresses} />*/}
              </Switch>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}



export default MyAccount;
