import React, {useEffect, useState} from 'react';
import {Switch, Route, Redirect} from 'react-router-dom';

import Header from './components/common/Header';
import Footer from './components/common/Footer';
import Index from './components/views/Index';
import List from './components/views/List';
import Loading from './components/views/Loading';
import CheckOut from "./components/views/Checkout";
import AboutUs from "./components/views/about-us";
import Branchs from "./components/views/branchs";

import Offers from './components/Offers';
import MyAccount from './components/MyAccount';
import NotFound from './components/views/NotFound';
import Extra from './components/Extra';
import Login from './components/Login';
import Register from './components/Register';
import TrackOrder from './components/TrackOrder';
import Invoice from './components/views/Invoice';
import PaymentError from './components/views/PaymentError';

import Detail from './components/views/Detail';
import './style/App.css';
import 'react-select2-wrapper/css/select2.css';
import List2 from './components/List';

import {useDispatch, useSelector} from "react-redux";
import {MAIN_INFO} from "./redux/constants/main-information";
import {BRANCHES} from "./redux/constants/branches";
import './i18n';
import {CART} from "./redux/constants/cart";
import {AUTH} from "./redux/constants/auth";
import ContactUs from "./components/views/contact-us";
import BillReference from "./components/views/BillReference";

function App(props){
  const [state, setState] = useState({showLayout: false, showFooter: false})
  const dispatch = useDispatch();
  const lang = useSelector((s) => s.mainInformation.settings.lang);
  const appName = useSelector((s) => s.mainInformation?.settings?.data?.app_name);
  const cssVarsReady = useSelector((s) => s.mainInformation.cssVarsReady);


  useEffect(() => {
    dispatch({type: AUTH.CHECK_AUTH})

    dispatch({type: MAIN_INFO.SET_SETTING_LANG, payload: JSON.parse(localStorage.getItem('LANG'))});
    setTimeout(() => {
      dispatch({type: MAIN_INFO.GET_SETTINGS})
      dispatch({type: MAIN_INFO.GET_CITIES})
      dispatch({type: BRANCHES.GET_DELIVERY_LOCATION})
      dispatch({type: BRANCHES.GET_BRANCHES})

      const hash_key = localStorage.getItem(CART.HASH_KEY);
      dispatch({type: CART.GET_CART, payload: {hash_key}});
      dispatch({type: CART.UPDATE_HASH_KEY, payload: {hash_key}});
    }, 0)
  }, [dispatch])

  useEffect(() => {
    if(lang && lang.code){
      document.getElementsByTagName('html')[0].setAttribute('lang', lang.code);
      document.getElementsByTagName('html')[0].setAttribute('dir', lang.dir);
      localStorage.setItem('LANG', JSON.stringify(lang))
    }
  }, [lang]);

  useEffect(() => {

    if(props.location.pathname!=='/login' &&  props.location.pathname!=='/register'){
      setState((s) => ({...s, showLayout: true}))
    }else {
      setState((s) => ({...s, showLayout: false}))
    }

    if(props.location.pathname!=='/' &&  props.location.pathname!=='' &&
      props.location.pathname!=='/login' &&  props.location.pathname!=='/register'
      &&  props.location.pathname!=='/contact-us'
      &&  props.location.pathname!=='/about-us'
    ){
      setState((s) => ({...s, showFooter: true}))
    }else {
      setState((s) => ({...s, showFooter: false}))
    }
  }, [props.location.pathname])

  useEffect(() => {
    if(appName){
      document.getElementsByTagName('title')[0].innerText = appName
    }
  }, [appName]);

  return (
      <>
        <div style={{display: !cssVarsReady ? 'block' : 'none'}}>
          <Loading />
        </div>
          {
            state.showLayout ? <Header/> : ''
          }
          <div className={`content ${props.location.pathname!=='/login' ? 'main-content' : ''}`}>
            <Switch>
              <Route path="/" exact component={Index} />
              <Route path="/listing" exact component={List} />
              <Route path="/about-us" exact component={AboutUs} />
              <Route path="/contact-us" exact component={ContactUs} />
              <Route path="/checkout" exact component={CheckOut} />
              <Route path="/offers" exact component={Offers} />
              <Route path="/listing2" exact component={List2} />
              <Route path="/myaccount" component={MyAccount} />
              <Route path="/404" exact component={NotFound} />
              <Route path="/fail_payment" exact component={PaymentError} />
              <Route path="/extra" exact component={Extra} />
              <Route path="/login" exact component={Login} />
              <Route path="/register" exact component={Register} />
              <Route path="/branchs" exact component={Branchs} />
              <Route path="/ref" exact component={BillReference} />

              <Route path="/track-order" exact component={TrackOrder} />
              <Route path="/invoice" exact component={Detail} />
              <Route path="/thanks" exact component={Detail} />
              <Route path="/detail" exact component={Detail} />
              <Route exact component={NotFound} />
            </Switch>
            {
              state.showFooter ? <Footer/> : ''
            }
          </div>
      </>
  );
}

export default App;
