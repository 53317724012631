import React, {useState, useEffect} from 'react';
import {Row, Col, Container, Table, Badge} from 'react-bootstrap';
import PageTitle from '../common/PageTitle';
import {useDispatch, useSelector} from "react-redux";
import {Trans, useTranslation} from "react-i18next";
import Skeleton from "react-loading-skeleton";
import Map from "../common/Map";
import {AiTwotoneMail, AiOutlinePhone, AiOutlineFieldTime} from "react-icons/ai";
import {RiMapPin2Line} from "react-icons/ri";
import dayjs from "dayjs";
import { orderByDistance } from 'geolib';

window.dayjs = dayjs;

const Branchs = () => {
	const {t} = useTranslation();
	const [branchs, setBranchs] = useState([]);
	const {data, status} = useSelector((s) => s.branches.branches);

	useEffect(() => {
		if(data){
			setBranchs(data)
		}
	}, [data]);

	useEffect(() => {
		navigator.geolocation.getCurrentPosition(({coords: {latitude, longitude}}) => {
			if(data){
				setBranchs(orderByDistance( {latitude, longitude}, data))
			}
		}, () => {
			if(data){
				setBranchs(data)
			}
		});
	}, [data]);

	return (
		<>
			<PageTitle
				title={t('Branchs')}
			/>
			<section className="section pt-5 pb-5">
				<Container>

					{
						status === 'WAIT' && (
							<Col md={12} sm={12} className="mb-4 pb-2">
								<Skeleton width={'100%'} height={200}/>
								<Skeleton width={'100%'} height={200}/>
								<Skeleton width={'100%'} height={200}/>
							</Col>
						)
					}


					{
						status === 'DONE' && (
							<Row>
								{
									branchs.map((i) => {
										return (
											<Col key={i.id} md={8} className="mx-auto mb-3 branche-card">
												<div className="p-5 bg-white shadow-sm">
													<Row className="mb-1">
														<Col md={12} xs={12}>
															<div className={'branche-card-map'}>
																<Map
																	id={`map-overlay-box-${i.id}`}
																	height={200}
																	initLocation={{
																		lat:i.latitude,
																		lng: i.longitude,
																	}}
																	disabled={true}
																/>
															</div>
														</Col>

														<Col md={12} xs={12} className={'mt-3'}>
															<h3 className="mt-0 d-flex justify-content-between">
																{i.name}
																<span>
																	{
																		i.is_open === 1 && (<Badge style={{fontSize: '14px'}} variant="success" className='mr-1'><Trans>now open</Trans></Badge>)
																	}
																	{
																		i.is_open === 0 && (<Badge style={{fontSize: '14px'}} variant="danger" className='mr-1'><Trans>closed now</Trans></Badge>)
																	}
																</span>
															</h3>
															<p className={'mb-0'}><RiMapPin2Line className={'mx-1'}/>{i.address}</p>
															<p className={'mb-0'}><AiTwotoneMail className={'mx-1'} />{i.email}</p>
															<p className={'mb-0'}><AiOutlinePhone className={'mx-1'}/>{i.phone}</p>

															<Table className="mt-3 mb-0">
																<h6 className={'mb-2'}><AiOutlineFieldTime className={'mx-1'}/><Trans>Worktime</Trans></h6>
																<tbody>
																{
																	i.branch_days.map((ii) => {
																		return 		<tr>
																			<th style={{color: '#ff380c'}} key={ii.id} className="text-black font-weight-bold" scope="col">
																				{ii.day.name}
																			</th>
																			<td> <Trans>open</Trans>: {dayjs('1/1/2020 ' + ii.open_at).format('h:m A')}</td>
																			<td> <Trans>close</Trans>: {dayjs('1/1/2020 ' + ii.close_at).format('h:m A')}</td>
																		</tr>
																	})
																}
																</tbody>
															</Table>
														</Col>
													</Row>
												</div>
											</Col>
										)
									})
								}
							</Row>
						)
					}
				</Container>
			</section>
		</>
	);
}


export default Branchs;
