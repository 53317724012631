import React, {useEffect, useRef, useState} from 'react';
import {Link, useHistory} from 'react-router-dom';
import {Row, Col, Container, Form, Alert, InputGroup, Button} from 'react-bootstrap';
import {useDispatch, useSelector} from "react-redux";
import firebase from "firebase/compat";
import {CART} from "../redux/constants/cart";
import Icofont from "react-icofont";
import {Trans, useTranslation} from "react-i18next";

const Coupon = () => {
  const dispatch = useDispatch();
  const inputRef = useRef(null);
  const {data, status, error} = useSelector((s) => s.cart?.coupon);
  const [state, setState] = useState({
    error: null
  })
  const {t} = useTranslation();
  const confirm = (event) => {
    event.preventDefault();
    event.stopPropagation();

    if (!inputRef.current.value || inputRef.current.value === '') {
      setState((s) => ({...s, error: t('Promo Code is incorrect')}))
      return
    }
    dispatch({type: CART.COUPON_VALIDITY, payload: {code: inputRef.current.value}})
  }


  return (
    <div>
      <InputGroup className="input-group-sm mb-2">
        <Form.Control type="text" placeholder={t('Enter promo code')} ref={inputRef}/>
        <InputGroup.Append>
          <Button variant="primary" type="button" id="button-addon2" onClick={confirm} disabled={status === 'WAIT'}>
            <Icofont icon="sale-discount" />
            {
              status === 'WAIT' ? <span>LOADING</span> : <span><Trans>Use coupon</Trans></span>
            }
          </Button>
        </InputGroup.Append>
      </InputGroup>
      {
        status === 'ERROR' ? (
          <div role="alert" className="fade alert alert-danger show">{typeof error === "string" ? error : error}</div>
        ) : ''
      }

    </div>
  );
}


export default Coupon;
