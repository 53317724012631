import {all, takeEvery, put, fork, call, select} from 'redux-saga/effects';
import {BRANCHES} from '../constants/branches';
import BranchesService from "../../services/branches";
import {CART} from "../constants/cart";


export function* getBranches() {
  yield takeEvery(BRANCHES.GET_BRANCHES, function* ({payload}) {
    try {
      const country = yield call(() => {
        if(localStorage.getItem('country')){
          return JSON.parse(localStorage.getItem('country')).id
        }
        return 1
      })
      yield put({type: BRANCHES.GET_BRANCHES_STATUS, payload: 'WAIT'});
      const res = yield call(BranchesService.getBranches, {...payload, country});
      yield put({type: BRANCHES.SET_BRANCHES, payload: res.data});
      yield put({type: BRANCHES.GET_BRANCHES_STATUS, payload: 'DONE'})
    } catch (error) {
      yield put({type: BRANCHES.GET_BRANCHES_STATUS, payload: 'ERROR'})
      yield put({type: BRANCHES.GET_BRANCHES_ERROR, payload: error})

      if(error?.response?.data?.message === "Unauthenticated."){
        localStorage.clear();
        document.location.href="/";
      }
    }
  });
}

export function* getMenuForLocation() {
  yield takeEvery(BRANCHES.GET_MENU_FOR_LOCATION, function* ({payload}) {
    const branch_active = localStorage.getItem('branch_active_for_location')

    yield put({type: BRANCHES.GET_MENU_STATUS, payload: 'WAIT'})
    const country = yield call(() => {
      if(localStorage.getItem('country')){
        return JSON.parse(localStorage.getItem('country')).id
      }
      return 1
    })
    try {
      const branche = yield call(BranchesService.getBranches, {...payload, country});
      const mainBrancheId = branche?.data[0].id || 1

      yield put({type: BRANCHES.GET_DELIVERY_LOCATION})
      const res = yield call(BranchesService.getDeliveryBranchByLocation, {latitude: Number(payload.latitude), longitude: Number(payload.longitude)})

      if(res?.branch?.id !== Number(branch_active)){
        localStorage.setItem('branch_active_for_location', res?.branch?.id)
        yield put({type: CART.CHANGE_HASH_KEY})
      }

      if(res.hasOwnProperty('error')){
        yield put({type: BRANCHES.GET_MENU, payload: {id: mainBrancheId, errorForLocation: true}})
      }else {
        yield put({type: BRANCHES.GET_MENU, payload: {id: res.branch.id}})
      }

    } catch (error) {
      if(error?.response?.data?.message === "Unauthenticated."){
        localStorage.clear();
        document.location.href="/";
      }
    }
  });
}

export function* getMenu() {
  yield takeEvery(BRANCHES.GET_MENU, function* ({payload}) {
    try {
      const country = yield call(() => {
        if(localStorage.getItem('country')){
          return JSON.parse(localStorage.getItem('country')).id
        }
        return 1
      })
      yield put({type: BRANCHES.GET_MENU_STATUS, payload: 'WAIT'})
      const res = yield call(BranchesService.getMenu, {country, ...payload})
      const sale = yield call(BranchesService.getSale, {country, ...payload})

      yield put({type: BRANCHES.SET_MENU, payload: {...res.data,
          categories: [
            ...res.data.categories,
            {
              id: 'sale',
              image: "",
              name: "Sale",
              notice: "Sale",
              products: sale.data
            }
          ],
        }});
      if(!payload.hasOwnProperty('errorForLocation')){
        yield put({type: BRANCHES.GET_MENU_STATUS, payload: 'DONE'})
      }

      if(payload.errorForLocation){
        yield put({type: BRANCHES.GET_MENU_STATUS, payload: 'ERROR_FOR_LOCATION'})
      }
    } catch (error) {
      yield put({type: BRANCHES.GET_MENU_STATUS, payload: 'ERROR'})
      yield put({type: BRANCHES.GET_MENU_ERROR, payload: error})

      if(error?.response?.data?.message === "Unauthenticated."){
        localStorage.clear();
        document.location.href="/";
      }
    }
  });
}

export function* getDeliveryLocations() {
  yield takeEvery(BRANCHES.GET_DELIVERY_LOCATION, function* ({payload}) {
    const deliveryLocationsStatus = yield select((s) => s.branches.deliveryLocations.status)
    const country = yield call(() => {
      if(localStorage.getItem('country')){
        return JSON.parse(localStorage.getItem('country')).id
      }
      return 1
    })
    if(deliveryLocationsStatus === 'DONE' || deliveryLocationsStatus === 'WAIT' ){
      return
    }
    try {
      yield put({type: BRANCHES.GET_DELIVERY_LOCATION_STATUS, payload: 'WAIT'})
      const res = yield call(BranchesService.getDeliveryLocations, {country, ...payload})
      yield put({type: BRANCHES.SET_DELIVERY_LOCATION, payload: res});
      yield put({type: BRANCHES.GET_DELIVERY_LOCATION_STATUS, payload: 'DONE'})
    } catch (error) {
      yield put({type: BRANCHES.GET_DELIVERY_LOCATION_STATUS, payload: 'ERROR'})
      yield put({type: BRANCHES.GET_DELIVERY_LOCATION_ERROR, payload: error})

      if(error?.response?.data?.message === "Unauthenticated."){
        localStorage.clear();
        document.location.href="/";
      }
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(getBranches),
    fork(getMenu),
    fork(getDeliveryLocations),
    fork(getMenuForLocation)
  ]);
}
