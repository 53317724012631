import React, {useRef, useState} from 'react';
import {
  Form,
  Alert,
  Button,
  ButtonToolbar,
  ToggleButtonGroup, ToggleButton, Modal
} from 'react-bootstrap';
import {useDispatch, useSelector} from "react-redux";
import {Trans, useTranslation} from "react-i18next";
import interceptor from "../../services/interceptors/default-interceptor";
import axios from "axios";
import {getLangCode} from "../../services/getLangCode";
import {AUTH} from "../../redux/constants/auth";

const Payment = ({onChange, onChangeMobile = () => {}}) => {
  const paymentWays = useSelector((s) => s.mainInformation.settings?.data?.payment_ways || []);
  const [showWalletModal, setShowWalletModal] = useState(false);
  const [showKioskModal, setShowKioskModal] = useState(false);
  const [errorMsg, setError] = useState(null);
  const user = useSelector((s) => s.auth.user);
  const {t} = useTranslation()
  const mobileRef = useRef();
  const emailRef = useRef();
  const dispatch = useDispatch();
  const _onChange = (id, type) => {
    const user = localStorage.getItem('USER');
    const _user = JSON.parse(user)
    if(type === 'wallet'){
      setShowWalletModal(true);
    }

    if(type === 'kiosk' && !_user?.metadata?.email){
      setShowKioskModal(true);
    }

    onChange(id)

  }

  const saveEmail = (email) => {
    const _token = localStorage.getItem('AUTH_TOKEN');
    const user = localStorage.getItem('USER');
    const link = `${interceptor.conf.baseURL}/api/profile`;

    axios.post(link, {
      email,
    }, {
      headers: {
        'Content-Language': getLangCode(),
        'Authorization': `Bearer ${_token}`,
        ...interceptor.headers
      }
    })
        .then(function (response) {
          const _user = JSON.parse(user)
          dispatch({type: AUTH.UPDATE_PROFILE, payload:{ data: {..._user.metadata, email} }})
          setShowKioskModal(false)
        })
        .catch(function (error) {
          console.log(error)
        });
  }

  return (
      <>
        <div className="bg-white rounded shadow-sm p-4 osahan-payment">
          <h4 className="mb-3"><Trans>Choose payment method</Trans></h4>
          <Form.Group className="mb-2">
            <ButtonToolbar>
              <ToggleButtonGroup className="d-flex w-100" type="radio" name="options" defaultValue={1}>
                {
                  paymentWays.map(({type, id, name}) => {
                    return (
                        <ToggleButton variant='info' value={id} key={id} onClick={(() => {_onChange(id, type)})}>
                          {name}
                          {/*<div>*/}
                          {/*  <span className="mr-1 badge badge-warning"> <Trans>payment fee</Trans> {' '} {fee} {settings ? settings?.currency_name : ''}</span>*/}
                          {/*</div>*/}
                        </ToggleButton>
                    )
                  })
                }
              </ToggleButtonGroup>
            </ButtonToolbar>
          </Form.Group>
        </div>
        <Modal
            show={showWalletModal}
            onHide={() => {
              setShowWalletModal(false)
            }}
            size="md"
            centered
        >
          <Modal.Header closeButton={true}>
            <Modal.Title as='h5' id="edit-profile"><Trans>Payment will be made through this number. Change it if you want to</Trans></Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <Form>
              <div className="form-row">
                <Form.Group className="col-md-12">
                  <Form.Label><Trans>Phone number</Trans></Form.Label>
                  <Form.Control ref={mobileRef} type="tel" defaultValue={user.phoneNumber} placeholder={t('Enter Phone number')}/>
                </Form.Group>
              </div>
            </Form>
            {
              errorMsg ? (
                    <Alert variant="danger" role="alert">
                      {t('Phone number is incorrect')}
                    </Alert>
                ) : <></>
            }
          </Modal.Body>

          <Modal.Footer className={'edit-profile-footer'}>
            <Button type='button' onClick={() => {
              setShowWalletModal(false)
            }} variant="outline-primary" className="d-flex text-center justify-content-center"><Trans>CANCEL</Trans></Button>
            <Button type='button' variant="primary" onClick={() => {
              setError(false)
              if (!mobileRef.current.value || mobileRef.current.value === '') {
                setError(true)
                return
              }

              if (!(/^\d+$/.test(mobileRef.current.value.replace('+', '')))) {
                setError(true)
                return
              }
              setShowWalletModal(false)
              onChangeMobile(mobileRef.current.value)

            }} className='d-flex text-center justify-content-center'>
              <Trans>OK</Trans>
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
            show={showKioskModal}
            onHide={() => {
              setShowKioskModal(false)
            }}
            size="md"
            centered
        >
          <Modal.Header closeButton={true}>
            <Modal.Title as='h5' id="edit-profile"><Trans>To use this service you need to add your email to can receive your payment reference number</Trans></Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <Form>
              <div className="form-row">
                <Form.Group className="col-md-12">
                  <Form.Label><Trans>Email</Trans></Form.Label>
                  <Form.Control ref={emailRef} type="email" defaultValue={user?.metadata?.email} placeholder={t('Enter Email Address')}/>
                </Form.Group>
              </div>
            </Form>
            {
              errorMsg ? (
                  <Alert variant="danger" role="alert">
                    {t('Email Address is incorrect')}
                  </Alert>
              ) : <></>
            }
          </Modal.Body>

          <Modal.Footer className={'edit-profile-footer'}>
            <Button type='button' onClick={() => {
              setShowWalletModal(false)
            }} variant="outline-primary" className="d-flex text-center justify-content-center"><Trans>CANCEL</Trans></Button>
            <Button type='button' variant="primary" onClick={() => {
              setError(false)
              if (!emailRef.current.value || emailRef.current.value === '') {
                setError(true)
                return
              }

              if (!(/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(emailRef.current.value.replace('+', '')))) {
                setError(true)
                return
              }

              saveEmail(emailRef.current.value)

            }} className='d-flex text-center justify-content-center'>
              <Trans>OK</Trans>
            </Button>
          </Modal.Footer>
        </Modal>
      </>
  );
}


export default Payment;
