const dev = {
	// API_ENDPOINT_URL: 'https://customer.flyorder.online/back',
	// API_URL: 'https://customer.flyorder.online/back',

	API_ENDPOINT_URL: 'https://carryoutstore.com/back',
	API_URL: 'https://carryoutstore.com/back',

	// API_ENDPOINT_URL: window.location.origin + '/back',
	// API_URL: window.location.origin + '/back',
};

const prod = {
	API_ENDPOINT_URL: window.location.origin + '/back',
	API_URL: window.location.origin + '/back',
};

const test = {
	API_ENDPOINT_URL: window.location.origin + '/back',
	API_URL: window.location.origin +  '/back',
};

const getEnv = () => {
	switch (process.env.NODE_ENV) {
		case 'development':
			return dev
		case 'production':
			return prod
		case 'test':
			return test
		default:
			break;
	}
}

export const env = getEnv()
