export const MAIN_INFO = {

  GET_SETTINGS: 'GET_SETTINGS',
  UPDATE_SETTINGS: 'UPDATE_SETTINGS',
  SET_SETTINGS: 'SET_SETTINGS',
  GET_SETTINGS_STATUS: 'GET_SETTINGS_STATUS',
  GET_SETTINGS_ERROR: 'GET_SETTINGS_ERROR',
  SET_COUNTRY: 'SET_COUNTRY',
  SET_COUNTRY_SAGA: 'SET_COUNTRY_SAGA',
  SET_SETTING_LANG: 'SET_SETTING_LANG',
  SET_CSS_VARS_READY: 'SET_CSS_VARS_READY',

  GET_CITIES: 'GET_CITIES',
  SET_CITIES: 'SET_CITIES',
  GET_CITIES_STATUS: 'GET_CITIES_STATUS',
  GET_CITIES_ERROR: 'GET_CITIES_ERROR',

  GET_REGIONS: 'GET_REGIONS',
  SET_REGIONS: 'SET_REGIONS',
  GET_REGIONS_STATUS: 'GET_REGIONS_STATUS',
  GET_REGIONS_ERROR: 'GET_REGIONS_ERROR',

  UPDATE_BEST: 'UPDATE_BEST',
  SET_BEST_TO_SETTINGS: 'SET_BEST_TO_SETTINGS',
  SET_CITIES_REGIONS: 'SET_CITIES_REGIONS'

}
