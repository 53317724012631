import React, {useEffect, useRef, useState} from 'react';
import {Link, useHistory} from 'react-router-dom';
import {Row, Col, Container, Form, Alert} from 'react-bootstrap';
import {useDispatch, useSelector} from "react-redux";
import firebase from "firebase/compat";
import {AUTH} from "../redux/constants/auth";

const Register = () => {
	const history = useHistory();
	const debug = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';
	const InputRef = useRef(null);
	const codeRef = useRef(null);
	const confirmCode = useRef(null);
	const {data} = useSelector((s) => s.mainInformation.settings);
	const dispatch = useDispatch();
	const [state, setState] = useState({
		validated: false,
		showVerifier: false,
		loading: false,
		error: null
	})

	useEffect(() => {
		window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('sign-in-button', {
			'size': 'invisible',
		});
	}, [])


	const signUp = (event) => {
		event.preventDefault();
		event.stopPropagation();

		if (!InputRef.current.value || InputRef.current.value === '') {
			setState((s) => ({...s, error: 'Phone number is incorrect'}))
			return
		}

		setState((s) => ({...s, validated: true, error: null, loading: true}))
		dispatch({type: AUTH.SIGNUP, payload: {
			phoneNumber: InputRef.current.value,
			appVerifier: window.recaptchaVerifier,
			callback: (verificationId, confirm) => {
				if(verificationId && confirm){
					confirmCode.current = confirm;
					setState((s) => ({...s, showVerifier: true, loading: false}))
				}else {
					setState((s) => ({...s, error: 'Phone number is incorrect', loading: false}))
				}
			}
		}})
	}

	const confirm = (event) => {
		event.preventDefault();
		event.stopPropagation();

		if (!codeRef.current.value || codeRef.current.value === '') {
			setState((s) => ({...s, error: 'Verification Code is incorrect'}))
			return
		}

		setState((s) => ({...s, validated: true, error: null, loading: true}))
		confirmCode.current(codeRef.current.value).then((result) => {
			const {metadata, uid, tenantId, phoneNumber} = result.user;
			dispatch({type: AUTH.VERIFICATION_CODE, payload: {metadata, uid, tenantId, phoneNumber}})
			setState((s) => ({...s, loading: false}));
			history.push('/')
		}).catch((error) => {
			setState((s) => ({...s, loading: false, error}))
		});
	}

	return (
		<Container fluid className='bg-white'>
			<Row>
				<Col md={4} lg={6}
						 className="d-none d-md-flex bg-image"
						 style={{
							 backgroundImage: `${data && data.homepage && data.homepage.homepage_bg_img ? 'url(' + data.homepage.homepage_bg_img + ')' : ''}`
						 }}
				/>
				<Col md={8} lg={6}>
					<div className="login d-flex align-items-center py-5">
						<Container>
							<Row>
								<Col md={9} lg={8} className="mx-auto pl-5 pr-5">

									{
										state.showVerifier ? (
											<>
												<h3 className="login-heading mb-4">Verification Code!</h3>
												<Form  noValidate>
													<div className="form-label-group">
														{/*<Form.Control value={debug ? '123456' : ''} type="text" id="inputCode" name={'code'} placeholder="code" required ref={codeRef}/>*/}
														<Form.Control type="text" id="inputCode" name={'code'} placeholder="code" required ref={codeRef}/>
														<Form.Control.Feedback type="invalid">
															Please choose a Phone.
														</Form.Control.Feedback>
														<Form.Label htmlFor="inputCode">Code</Form.Label>
													</div>
													<button
														onClick={confirm}
														id={'sent-code'}
														className="btn btn-lg btn-outline-primary btn-block btn-login text-uppercase font-weight-bold mb-2">
														Send
													</button>
													{
														state.error ? (
															<Alert variant="danger" role="alert">
																{state.error}
															</Alert>
														) : ''
													}
													<div className="text-center pt-3">
														Already have an account? <Link className="font-weight-bold" to="/login">Sign In</Link>
													</div>
												</Form>
											</>

										) : (
											<>
												<h3 className="login-heading mb-4">New Buddy!</h3>
												<Form  noValidate>
													<div className="form-label-group">
														{/*<Form.Control type="tel" value={debug ? '+201155630808' : ''} id="inputPhone" name={'phone'} placeholder="Phone" required ref={InputRef}/>*/}
														<Form.Control type="tel" id="inputPhone" name={'phone'} placeholder="Phone" required ref={InputRef}/>
														<Form.Control.Feedback type="invalid">
															Please choose a Phone.
														</Form.Control.Feedback>
														<Form.Label htmlFor="inputPhone">Phone</Form.Label>
													</div>
													<button
														onClick={signUp}
														id={'sign-in-button'}
														className="btn btn-lg btn-outline-primary btn-block btn-login text-uppercase font-weight-bold mb-2">
														Sign Up
													</button>

													{
														state.error ? (
															<Alert variant="danger" role="alert">
																{state.error}
															</Alert>
														) : ''
													}
													<div className="text-center pt-3">
														Already have an account? <Link className="font-weight-bold" to="/login">Sign In</Link>
													</div>
												</Form>
											</>
										)
									}



								</Col>
							</Row>
						</Container>
					</div>
				</Col>
			</Row>
		</Container>
	);
}


export default Register;
