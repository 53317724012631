import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {Row, Col, Container, Image, Table, Alert} from 'react-bootstrap';
import Icofont from 'react-icofont';
import PageTitle from '../common/PageTitle';
import {useDispatch, useSelector} from "react-redux";
import {CART} from "../../redux/constants/cart";
import useQuery from "../../hooks/useQuery";
import {Trans, useTranslation} from "react-i18next";
import Skeleton from "react-loading-skeleton";
import {onMessageListener} from '../../services/auth/FirebaseAuth'
import axios from "axios";
import {geocodeByLatLng} from '../common/Map'
import dayjs from "dayjs";


const listenBGUpdate = () => new Promise((resolve, reject) => {
	let hidden = 'hidden';
	let visibilityState = 'visibilityState';
	let visibilityChange = 'visibilitychange';

	if (typeof document.msHidden !== "undefined") {
		hidden = 'msHidden';
		visibilityState = 'msVisibilityState';
		visibilityChange = 'msvisibilitychange';
	}

	let document_hidden = document[hidden];
	document.addEventListener(visibilityChange, function() {
		if(document_hidden != document[hidden]) {
			if(document[hidden]) {

			} else {

				resolve()
			}
			document_hidden = document[hidden];
		}
	});
})

const Detail = () => {
	const dispatch = useDispatch()
	const query = useQuery();
	const [state, setState] = useState({status: 'WAIT', data: null, orderStatus: null, address: null, update: 1, actions: []})
	const {lang, data: app} = useSelector((s) => s.mainInformation.settings);
	const statuses = useSelector((s) => s?.mainInformation?.settings?.data?.statuses);
	const {t} = useTranslation();

	const settings = useSelector((s) => s.mainInformation.settings.data);

	useEffect(() => {
		dispatch({type: CART.GET_ORDER, payload: {id: query.get('order'), callback: (data) => {
			setState((s) => ({...s, status: 'DONE', data}))
			if(data?.actions[data?.actions.length - 1]){
				setState((s) => ({...s, orderStatus: data?.actions[data?.actions.length - 1].status_id, actions: data?.actions}))
			}

			if(data.address){
				setState((s) => ({...s, address: data.address}));
				return
			}

			if(data.latitude && data.longitude){
				geocodeByLatLng({ lat: Number(data.latitude), lng: Number(data.longitude) })
					.then((results) => {
						setState((s) => ({...s, address: results[0].formatted_address}));
					})
					.catch((e) => { })
			}
		}}})
	}, []);

	useEffect(() => {
		onMessageListener().then((res) => {
			dispatch({type: CART.UPDATE_ORDER, payload: {id: query.get('order'), callback: (data) => {
				setState((s) => ({...s, status: 'DONE', data, orderStatus: data?.actions[data?.actions.length - 1].status_id, actions: data?.actions}))
			}}})
		})
	})

	useEffect(() => {
		listenBGUpdate().then((res) => {
			dispatch({type: CART.UPDATE_ORDER, payload: {id: window.location.search.split('=')[1], callback: (data) => {
						setState((s) => ({
							...s,
							status: 'DONE',
							data,
							orderStatus: data?.actions[data?.actions.length - 1].status_id,
							update: s.update + 1,
							actions: data?.actions
						}))
					}}})
		})
	})

	const calcClass = (id, delivery_way, orderStatus) => {
		let isActive = false;
		const doneClassName = `status-done`
		const activeClassName = `active`
		if(delivery_way === 'delivery'){
			const ids = [8,5,6,7];
			isActive = ids[ids.indexOf(orderStatus) + 1] === id

			if(orderStatus === 7 && (id === 7 || id === 6 || id === 5 || id === 8)){
				return doneClassName
			}

			if(isActive){
				return activeClassName
			}

			if(orderStatus === 6 && (id === 6 || id === 5 || id === 8)){
				return doneClassName
			}

			if(orderStatus === 5 && (id === 5 || id === 8)){
				return doneClassName
			}

			if(orderStatus === 8 && id === 8){
				return doneClassName
			}
		}

		if(delivery_way === 'pickup'){
			const ids = [8,5,9,7];
			isActive = ids[ids.indexOf(orderStatus) + 1] === id

			if(orderStatus === 7 && (id === 7 || id === 9 || id === 5 || id === 8)){
				return doneClassName
			}

			if(isActive){
				return activeClassName
			}

			if(orderStatus === 9 && (id === 9 || id === 5 || id === 8)){
				return doneClassName
			}

			if(orderStatus === 5 && (id === 5 || id === 8)){
				return doneClassName
			}

			if(orderStatus === 8 && id === 8){
				return doneClassName
			}
		}

		return ''
	}

	return (
		<>
			<PageTitle
				title={t('Order Detail')}
				subTitle={`Order #${query.get('order')}`}
			/>


			<section className="section pt-5 pb-5" id={`_${state.update}`}>
				<Container>

					{
						(state.status === 'WAIT' || !app) && (
							<Skeleton count={6} width={'100%'} height={100}/>
						)
					}
					{
						app && state.status === 'DONE' && (
							<Row>
								<Col md={8} className="mx-auto">

									<div className="bg-white p-4 shadow-lg">

										{
											window.location.pathname === '/detail' ?
												<>
													<div className="mb-2 mt-5 text-center"><Trans>Order</Trans> #{query.get('order')}</div>
												</>
												: <>
													<Row className="mb-1 pb-3">
														<Col md={12} xs={12}>
															<h3 className="mt-5 text-center">
																<Trans>invoice.Thanks for choosing
																</Trans> <strong className="text-secondary">{app.app_name}</strong>,
																<Trans>invoice.Here are your order details</Trans>
																:
															</h3>
														</Col>
													</Row>
												</>
										}




										<div className="osahan-track-order-detail po">
											{
												window.location.pathname === '/detail' ?
													<>
														<h5 className="mt-0 mb-2 text-center"><Trans>Order Details</Trans></h5>
													</>
													: <>
														<h5 className="mt-0 mb-2 text-center"><Trans>Order</Trans>#{query.get('order')}</h5>
													</>
											}




											{
												(state.orderStatus === 2 || state.orderStatus === 4) && (
													<>
														<div style={{display: 'flex'}}>
														<span className="mb-3 badge" style={{
															backgroundColor: state.data.actions[state.data.actions.length - 1].status.color,
															color: `#ffffff`,
															margin: '0 auto',
															fontSize: '15px'
														}}>{state.data.actions[state.data.actions.length - 1].status.name}</span>
														</div>
														{
															state.orderStatus === 4 && (
																<div style={{display: 'flex'}}>
																	<p style={{
																		display: 'block',
																		width: '100%',
																		textAlign: 'center',
																		marginBottom: '30px',
																		fontSize: '15px',
																		fontWeight: '600',
																	}}>{state.data.actions[state.data.actions.length - 1].comment}</p>
																</div>
															)
														}
													</>
												)
											}

											<>
												{
													(state.data.hasOwnProperty('is_paid') && !state?.data?.is_paid && state.data?.payment_way_id === 4) ? (
														<>
															<Alert variant="success" role="alert" className={'mt-4 mb-4'}>
																<h4 className="alert-heading text-center mt-2">{t('Awaiting payment')}</h4>
																<p className={'text-center mt-3'} style={{fontSize: '15px'}}>{t('Waiting for the payment process to be completed using the reference number')} <b> " {state?.data?.bill_reference} " </b> {t('until the order is completed')}</p>
															</Alert>
														</>
													) : (<></>)
												}
											</>

											<>
												{
													(state.data.hasOwnProperty('is_paid') && state?.data?.is_paid && state.data?.payment_way_id === 4) ? (
														<>
															{
																(state.orderStatus && state.orderStatus !== 2 && state.orderStatus !== 4) && (
																	<div className={'status mb-5'}>
																		{
																			statuses && statuses[state?.data?.delivery_way?.id === 1 ? 'delivery' : 'pickup']?.map(({id, name_ar, name_en}, i) => {
																				return (
																					<React.Fragment key={id}>
																						<div className={`status-point ${calcClass(id, state?.data?.delivery_way?.id === 1 ? 'delivery' : 'pickup', state.orderStatus)}`}>
																							<span /> <span className={`status-point-text`}>{
																							lang?.code === 'ar' ? name_ar : name_en
																						}</span>
																						</div>
																						{
																							statuses[state?.data?.delivery_way?.id === 1 ? 'delivery' : 'pickup']?.length !== i + 1 && (
																								<div className={`status-line ${calcClass(id, state?.data?.delivery_way?.id === 1 ? 'delivery' : 'pickup', state.orderStatus)}`}></div>
																							)
																						}
																					</React.Fragment>
																				)
																			})
																		}
																	</div>
																)
															}
														</>
													) : (<></>)
												}
											</>

											<>
												{
													(state.data?.payment_way_id !== 4) ? (
														<>
															{
																(state.orderStatus && state.orderStatus !== 2 && state.orderStatus !== 4) && (
																	<div className={'status mb-5'}>
																		{
																			statuses && statuses[state?.data?.delivery_way?.id === 1 ? 'delivery' : 'pickup']?.map(({id, name_ar, name_en}, i) => {
																				return (
																					<React.Fragment key={id}>
																						<div className={`status-point ${calcClass(id, state?.data?.delivery_way?.id === 1 ? 'delivery' : 'pickup', state.orderStatus)}`}>
																							<span /> <span className={`status-point-text`}>{
																							lang?.code === 'ar' ? name_ar : name_en
																						}</span>
																						</div>
																						{
																							statuses[state?.data?.delivery_way?.id === 1 ? 'delivery' : 'pickup']?.length !== i + 1 && (
																								<div className={`status-line ${calcClass(id, state?.data?.delivery_way?.id === 1 ? 'delivery' : 'pickup', state.orderStatus)}`}></div>
																							)
																						}
																					</React.Fragment>
																				)
																			})
																		}
																	</div>
																)
															}
														</>
													) : (<></>)
												}
											</>

											<Row>
												<Col md={6} >
													<p className={'osahan-track-order-detail-title mb-1'}><Icofont icon="food-cart"/> <Trans>FROM</Trans> </p>
													<h6 ><p className="text-black mb-4"> {state.data.branch.name} - {state.data.branch.address}</p> </h6>

													<p className={'osahan-track-order-detail-title mb-1'}><Icofont icon="pay"/><Trans>PAYMENT WAY</Trans> </p>
													<p className="text-black mb-0">{state.data.payment_way.name}</p>

												</Col>

												<Col md={6}>

													{
														state.data.delivery_way.id === 2 ? (
															<>
																<p className={'osahan-track-order-detail-title mb-1'}><Icofont icon="map-pins"/> <Trans>Receipt from the branch</Trans></p>
																<p className="text-black mb-1">{state.data.branch.name}</p>
															</>
														) : (
															<>
																<p className={'osahan-track-order-detail-title mb-1'}><Icofont icon="map-pins"/> <Trans>DELIVER TO</Trans></p>
																<p className="text-black mb-1">{state.address}</p>
																{
																	state.data.address_desc && (
																		<p className="text-gray mb-4"> - <span>{state.data.address_desc}</span> </p>
																	)
																}
															</>
														)
													}

													<p className={'mb-1 mt-3 osahan-track-order-detail-title '}><Icofont icon="calendar"/> <Trans>invoice.Order Date</Trans> </p>
													<h6 ><p className="text-black mb-4"> {dayjs(state.data.created_at).format('YYYY MMMM DD, hh:mm A')}</p> </h6>
												</Col>
											</Row>
											<Row className="mt-5">
												<Col md={12}>
													<Table className="mt-3 mb-0">
														<thead className="thead-light">
														<tr>
															<th className="text-black font-weight-bold" scope="col"><Trans>invoice.Item Name</Trans></th>
															<th className="text-right text-black font-weight-bold" scope="col"><Trans>invoice.Quantity</Trans></th>
															<th className="text-right text-black font-weight-bold" scope="col"><Trans>invoice.Price</Trans></th>
														</tr>
														</thead>
														<tbody>

														{
															state.data.products.map((product) => {
																return (
																	<tr key={product.id}>
																		<td>
																			{product.product.name} {' '}
																			{
																				product?.size || product?.optionChoices?.length > 0 ? (
																					<span className={'my-1 text-secondary'}>{product?.size?.name} {product?.optionChoices?.length > 0 ? ` | ${product?.optionChoices?.map((i) => i.option.name).join(', ')}` : ''}</span>
																				) : ''
																			}
																		</td>
																		<td className="text-right"> {product.quantity}</td>
																		<td className="text-right"> {Number(product.total_price).toFixed(2)} {settings?.currency_name}</td>
																	</tr>
																)
															})
														}
														<tr>

															<td className="text-right" colSpan="2">
																<p className="mb-1"><Trans>Cost</Trans>:</p>
																{
																	state.data.coupon && (
																		<>
																			<p className="mb-1"><Trans>Total Discount</Trans>:</p>
																			<p className="mb-1"><Trans>Price after discount</Trans>:</p>
																		</>
																	)
																}

																{Number(state.data.payment_way.fee) !== 0 && <p className="mb-1"><Trans>payment fee</Trans>:</p>}
																{Number(state.data.branch_vat_number) !== 0 && <p className="mb-1"><Trans>Restaurant Charges</Trans>:</p>}
																{Number(state.data.delivery_fee) !== 0 && <p className="mb-1"><Trans>Delivery Fee</Trans>:</p>}
																<h6 className="text-success"><Trans>invoice.Grand Total</Trans>:</h6>
															</td>

															<td className="text-right">
																<p className="mb-1"> {state.data.cost} {settings?.currency_name}</p>

																{
																	state.data.coupon && (
																		<>
																			<p className="mb-1">{state.data.coupon.value} {state.data.coupon.type === 'percent' ? '%' : settings ? settings?.currency_name : ''} </p>
																			<p className="mb-1">{state.data.cost_with_coupon} {settings?.currency_name}</p>
																		</>
																	)
																}
																{Number(state.data.payment_way.fee) !== 0 && <p className="mb-1">{state.data.payment_way.fee} {settings?.currency_name}</p>}
																{Number(state.data.branch_vat_number) !== 0 && <p className="mb-1">{Number(state.data.branch_vat_number).toFixed(2)} {settings?.currency_name}</p>}
																{Number(state.data.delivery_fee) !== 0 && <p className="mb-1">{state.data.delivery_fee} {settings?.currency_name}</p>}
																<h6 className="text-success"> {Number(state.data.total_cost).toFixed(2)} {settings?.currency_name}</h6>
															</td>

														</tr>
														</tbody>
													</Table>
												</Col>
											</Row>
										</div>
									</div>
								</Col>
							</Row>
						)
					}
				</Container>
			</section>
		</>
	);
}


export default Detail;
