import React from 'react';
import {Link} from 'react-router-dom';
import {Row,Col,Container,Image} from 'react-bootstrap';
import {useTranslation} from "react-i18next";
import useQuery from "../../hooks/useQuery";

const BillReference = () => {
	const {t} = useTranslation();
	const query = useQuery();
	return (
		<section className="section pt-5 pb-5 osahan-not-found-page">
			<Container>
				<Row>
					<Col md={12} className="text-center pt-5 pb-5">
						<Image className="img-fluid" src="/img/check.png" alt="Successfully" style={{width: '20%', minWidth: '230px'}} />
						<h1 className="mt-2 mb-3 text-center">{t('Successfully')}</h1>
						<p className="mb-1 mt-1 text-center" style={{fontSize: '20px'}}>{t('Use this reference number')} <b>" {query.get('id')} "</b> {t('to complete your payment process')}</p>
						<p className="mb-5 text-center" style={{fontSize: '20px'}}>{t('and it wall be send to your email also')}</p>
						<Link className="btn mx-1 btn-primary btn-lg" to={`/detail?order=${query.get('order')}`}>{t('SHOW ORDER')}</Link>
					</Col>
				</Row>
			</Container>
		</section>
	)
}

export default BillReference;
