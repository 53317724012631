import React from 'react';
import ProductBox from "../home/ProductBox";
import OwlCarousel from "react-owl-carousel3";
import {useSelector} from "react-redux";
import {CART} from "../../redux/constants/cart";

const ACarousel = ({branch = null, onClick = () => {}}) => {
  const slider = useSelector((s) => s.mainInformation?.settings?.data?.slider[0].images || []);
  return (
    <div dir={'ltr'}>
      {
        slider.length > 0 && (
          <OwlCarousel nav loop {...options} className="homepage-ad owl-theme">
            {
              slider.map((item, i) => (
                <div className="item" key={i} onClick={onClick}>
                  <ProductBox
                    image={item}
                    imageClass='img-fluid rounded'
                    imageAlt='carousel'
                    linkUrl={branch ? `/listing?${localStorage.getItem(CART.SEARCH)}#_sale` : '#'}
                    boxClass='homepage-search-slider-item'
                  />
                </div>
              ))
            }
          </OwlCarousel>
        )
      }
    </div>
  )
}


const options={
  responsive: {
    0:{
      items:1,
    },
    764:{
      items:1,
    },
    765: {
      items: 1,
    },
    1200: {
      items: 1,
    },
  },
  lazyLoad: true,
  loop: false,
  autoplay: true,
  autoplaySpeed: 1000,
  dots: false,
  autoplayTimeout: 2000,
  nav: true,
  autoplayHoverPause: true,
  navText: ["<i class='fa fa-chevron-left'></i>", "<i class='fa fa-chevron-right'></i>"],
}

ACarousel.propTypes = {

};

export default ACarousel;
