import React from 'react';
import {Link} from 'react-router-dom';
import {Row,Col,Container,Image} from 'react-bootstrap';
import {useTranslation} from "react-i18next";

const NotFound = () => {
	const {t} = useTranslation();
	return (
		<section className="section pt-5 pb-5 osahan-not-found-page">
			<Container>
				<Row>
					<Col md={12} className="text-center pt-5 pb-5">
						<Image className="img-fluid" src="/img/404.png" alt="404" />
						<h1 className="mt-2 mb-2 text-center">{t('Page not found')}</h1>
						<p className={'text-center'}>{t('Uh-oh! Looks like the page you are trying to access, doesn\'t exist. Please start afresh.')}</p>
						<Link className="btn btn-primary btn-lg" to="/">{t('GO HOME')}</Link>
					</Col>
				</Row>
			</Container>
		</section>
	)
}

export default NotFound;
