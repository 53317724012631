import React, {useEffect, useState, useRef} from 'react';
import {NavLink, Link, useHistory} from 'react-router-dom';
import {Navbar, Nav, Container, NavDropdown, Image, Button} from 'react-bootstrap';
import DropDownTitle from '../common/DropDownTitle';
import CartDropdownItem from '../cart/CartDropdownItem';
import {useDispatch, useSelector} from "react-redux";
import {MAIN_INFO} from "../../redux/constants/main-information";
import {Trans} from "react-i18next";
import {BsMinecartLoaded} from "react-icons/bs";
import {CART} from "../../redux/constants/cart";
import {AUTH} from "../../redux/constants/auth";
import $ from 'jquery';
import Icofont from "react-icofont";

const IsAuthEmailWarning = () => {
	const history = useHistory()
	const isAuthEmail = useSelector((s) => !!s.auth?.user?.metadata?.email)
	const isAuth = useSelector((s) => !!s.auth?.token)

	const [show, setShow] = useState(false);
	const showAuthEmailWarningInHome =  localStorage.getItem('showAuthEmailWarningInHome');
	const showAuthEmailWarningInCheckout =  localStorage.getItem('showAuthEmailWarningInCheckout');

	useEffect(() => {
		if(isAuth){
			if(!isAuthEmail && ((history.location.pathname === '/checkout' && !showAuthEmailWarningInCheckout) || (history.location.pathname === '/checkOut' && !showAuthEmailWarningInCheckout) || (history.location.pathname === '/' && !showAuthEmailWarningInHome))){
				setShow(true)
			}else {
				setShow(false)
			}
		}
	}, [isAuth, isAuthEmail, history.location.pathname]);


	useEffect(() => {
		if(show){
			$('.content.main-content').css({
				paddingTop: 165
			})
		}else {
			$('.content.main-content').css({
				paddingTop: ''
			})
		}
		return () => {
			$('.content.main-content').css({
				paddingTop: ''
			})
		}
	}, [show]);


	return (
		<>
			{
				show ? (
					<div className={'email-warning'}>
						<div className={'container'}><Trans>emailWarning</Trans></div>
						<Button onClick={() => {
							if(history.location.pathname === '/checkout'){
								localStorage.setItem('showAuthEmailWarningInCheckout', true);
							}
							if(history.location.pathname === '/'){
								localStorage.setItem('showAuthEmailWarningInHome', true);
							}
							setShow(false)
						}} variant={'link'}><Icofont icon="close-circled" /></Button>
					</div>
				) : <></>
			}
		</>
	)
}

async function detectAdBlock() {
	let adBlockEnabled = false
	const googleAdUrl = 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js'
	try {
		await fetch(new Request(googleAdUrl)).catch(_ => adBlockEnabled = true)
	} catch (e) {
		adBlockEnabled = true
	} finally {
		console.log(`AdBlock Enabled: ${adBlockEnabled}`)
	}
	return adBlockEnabled
}

const IsAdBlockWarning = () => {
	const cssVarsReady = useSelector((s) => s.mainInformation.cssVarsReady);
	const isRtl = document.getElementsByTagName('html')[0].getAttribute('dir') === 'rtl'
	const [show, setShow] = useState(false);
	const history = useHistory()

	useEffect(() => {
		const showAdBlockWarningInHome = localStorage.getItem('showAdBlockWarningInHome');
		if(cssVarsReady && history.location.pathname === '/'){
			setTimeout(function () {
				detectAdBlock().then((res) => {
					if(res){

						const slider = document.getElementsByClassName('osahan-slider');
						if(slider?.length > 0 && slider[0].clientHeight < 100 && !showAdBlockWarningInHome){
							setShow(true)
						}
					}
				})
			}, 500);
		}

		if(history.location.pathname !== '/'){
			setShow(false)
		}

	}, [cssVarsReady, history.location.pathname]);

	return (
		<>
			{
				show ? (
					<div className={'ad-block-show'}>
						<div className={'container'}>  <Trans>adBlockWarning</Trans> <img className={isRtl ? 'ad-block-right' : 'ad-block-left'} src={'./img/AdBlock-Logo.wine.png'} alt={'AdBlock'} /> </div>
						<Button onClick={() => {
							localStorage.setItem('showAdBlockWarningInHome', true);
							setShow(false)
						}} variant={'link'}><Icofont icon="close-circled" /></Button>
					</div>
				) : <></>
			}
		</>
	)
}



const CartBtn = ({cart, price, total_quantity, token, data, className = ''}) => {
	return (
		<NavDropdown  alignRight className={`dropdown-cart ${className}`}
					  title={
						  <DropDownTitle
							  className='d-inline-block'
							  faIcon='shopping-basket'
							  iconClass='mx-1'
							  title={window.innerWidth < 350 && className === 'cart-mobile' ? '' : 'nav.Cart'}
							  badgeClass='mx-1'
							  badgeVariant='success'
							  badgeValue={cart && cart.total_quantity ? Number(cart.total_quantity) : 0}
						  />
					  }
		>
			<div className="dropdown-cart-top shadow-sm">
				<div className="dropdown-cart-top-body border-top p-4">
					{
						!price ? (
							<>
								<div className="bg-white rounded mb-2 d-flex justify-content-center align-items-center flex-column" style={{paddingTop: 50, paddingBottom: 50}}>
									<p style={{fontSize: 50}}>
										<BsMinecartLoaded />
									</p>
									<p style={{fontSize: 16, textAlign: 'center'}}><Trans>Add items into your basket</Trans></p>
								</div>
							</>
						) : ''
					}

					{
						total_quantity ? (
							<>
								{
									cart.products.map(({id, total_price, quantity, product}) => {
										return (
											<CartDropdownItem
												key={id}
												icoIcon='ui-press'
												iconClass='text-success food-item'
												title={`x${quantity} ${product.name}`}
												price={`${data ? data.currency_name : ''} ${Number(product.price).toFixed(2)}`}
											/>
										)
									})
								}
							</>
						) : ''
					}
				</div>

				{
					total_quantity ? (
						<>
							<div className="dropdown-cart-top-footer border-top p-4">
								<p className="mb-0 font-weight-bold text-secondary"><Trans>Cart.Sub Total</Trans> <span className="float-right text-dark">{data ? data.currency_name : ''} {Number(price).toFixed(2)}</span></p>
								<small className="text-info"><Trans>Cart.Extra charges may apply</Trans></small>
							</div>
							<div className="dropdown-cart-top-footer border-top p-2">
								{
									token ? (
										<NavDropdown.Item eventKey={5.1} as={Link} className="btn btn-success btn-block py-3 text-white text-center dropdown-item"
														  to={`/checkout?${localStorage.getItem(CART.SEARCH)}`}>
											<Trans>Cart.Checkout</Trans>
										</NavDropdown.Item>
									) : (
										<NavDropdown.Item eventKey={5.1} as={Link} className="btn btn-success btn-block py-3 text-white text-center dropdown-item" to="/login"> <Trans>Cart.Checkout</Trans></NavDropdown.Item>
									)
								}
							</div>
						</>
					) : ''
				}

			</div>
		</NavDropdown>
	)
}

const Header = () => {
	const history = useHistory()
	const ref =  useRef()
	const dispatch = useDispatch();
	const {lang, data} = useSelector((s) => s.mainInformation.settings);
	const country = useSelector((s) => s.mainInformation.country);

	const token = useSelector((s) => s.auth.token);
	const {total_price = 0, total_quantity = 0, products = [], price} = useSelector((s) => (s.cart.status === 'DONE' ? s.cart.data : {
		total_price: 0, total_quantity: 0, products: [], price: 0
	}))
	const cart = useSelector((s) => (s.cart.data))

	const [state, setState] = useState({
		isNavExpanded: false
	});

	const setIsNavExpanded = (isNavExpanded) => {
		setState((s) => ({...s,  isNavExpanded: isNavExpanded }));
	}

	const closeMenu = () => {
		setState((s) => ({...s,  isNavExpanded: false }));
	}

	const handleClick = (e) => {
		if (ref.current.contains(e.target)) {
		} else {
			setState((s) => ({...s,  isNavExpanded: false }));
		}
	}

	const socialLinks = useSelector((s) => {
		if(s.mainInformation.settings.status !== 'WAIT'){
			return s.mainInformation.settings?.data?.social_links || []
		}
		return []
	})

	useEffect(() => {
		document.addEventListener('click', handleClick, false);
		return () => {
			document.removeEventListener('click', handleClick, false);
		}
	}, []);

	return (
		<div ref={ref} dir={!lang || lang?.code === 'en' ? 'rtl' : 'ltr'} className={'header'}>
			<Navbar onToggle={setIsNavExpanded} expanded={state.isNavExpanded} expand='lg' className="top-nav">
				<Container>
					<Navbar.Collapse id="navbarNavDropdown">
						<Nav activeKey={0} onSelect={closeMenu}>
							{
								data?.countries && data?.countries.length > 0 ? (
									<Navbar.Collapse>
										<Nav>
											<NavDropdown
												id="countries"
												title={<span> {data?.countries.filter((i) => i.phone_cde === country.phone_cde)[0].name} <img className={'flag'} src={data?.flag} alt={data?.countries.filter((i) => i.phone_cde === country.phone_cde)[0].name}/> </span>}
											>
												{
													data?.countries.map((item) => (
														<NavDropdown.Item className={item.id === country?.id ? 'active' : ''} key={item.id} onClick={() => {
															if(country.id !== item.id){
																dispatch({type: MAIN_INFO.SET_COUNTRY_SAGA, payload: item})
															}
														}}>
															<span className={'countries-item'}> <img className={'flag'} src={item?.flag}  alt={item.name}/> {item.name}  </span>
														</NavDropdown.Item>
													))
												}
											</NavDropdown>
										</Nav>
									</Navbar.Collapse>
								) : ''
							}

							{
								!lang || lang.code === 'en' ? (
									<a className={'nav-link'} href={'#'} onClick={(e) => {
										e.preventDefault()
										dispatch({type: MAIN_INFO.SET_CSS_VARS_READY, payload: false})
										dispatch({type: MAIN_INFO.SET_SETTING_LANG, payload: {code: 'ar', dir: 'rtl'}})
										window.location.href = '/'
									}}>
										عربي
									</a>
								) : ''
							}

							{
								lang && lang.code === 'ar' ? (
									<a className={'nav-link'} href={'#'} onClick={(e) => {
										e.preventDefault()
										dispatch({type: MAIN_INFO.SET_CSS_VARS_READY, payload: false})
										dispatch({type: MAIN_INFO.SET_SETTING_LANG, payload: {code: 'en', dir: 'ltr'}})
										window.location.href = '/'
									}}>
										English
									</a>
								) : ''
							}

						</Nav>
					</Navbar.Collapse>
					<Navbar>
						{
							socialLinks.map(({link, name, id}) => {
								let icon = name;
								switch (name) {
									case 'فيسبوك':
										icon = 'facebook';
										break
									case 'تويتر':
										icon = 'twitter';
										break
									case 'واتس اب':
										icon = 'whatsapp';
										break

									case 'انستجرام':
										icon = 'instagram';
										break

									case 'يوتيوب':
										icon = 'youtube';
										break

									case 'سناب شات':
										icon = 'snapchat-ghost';
										break
									case 'فيسبوك ماسنجر':
										icon = 'comment';
										break
									case 'Messenger':
										icon = 'comment';
										break
									default:
										icon = name;
								}
								return (
									<a href={link} target={'_blank'} className={'follow-us-btn'} key={id} title={name}>
										<i className={`fa fa-${icon}`} aria-hidden="true" />
									</a>
								)
							})
						}
					</Navbar>
				</Container>
			</Navbar>
			<Navbar onToggle={setIsNavExpanded} expanded={state.isNavExpanded} color="light" expand='lg' className="navbar-light osahan-nav shadow-sm">
				<Container>
					<Navbar.Brand>
						<Link  to="/">
							{
								data && data.homepage ? (<Image src={data.side_menu.side_menu_logo} alt={data.app_name} />) : ''
							}
						</Link>
					</Navbar.Brand>

					<CartBtn cart={cart} price={price} total_quantity={total_quantity} token={token} data={data} className={'cart-mobile'} />

					<Navbar.Toggle/>

					<Navbar.Collapse id="navbarNavDropdown">
						<Nav className="ml-auto" onSelect={closeMenu}>
							<Nav.Link eventKey={0} as={NavLink}  exact to="/">
								<Trans>nav.Home</Trans> <span className="sr-only">(<Trans>nav.Home</Trans>)</span>
							</Nav.Link>

							<Nav.Link eventKey={1} as={NavLink} exact to="/contact-us">
								<Trans>nav.Contact Us</Trans> <span className="sr-only">(<Trans>nav.Contact Us</Trans>)</span>
							</Nav.Link>

							<Nav.Link eventKey={2} as={NavLink} exact to="/about-us">
								<Trans>nav.About Us</Trans> <span className="sr-only">(<Trans>nav.About Us</Trans>)</span>
							</Nav.Link>
							<Nav.Link eventKey={3} as={NavLink} exact to="/branchs">
								<Trans>nav.Branchs</Trans> <span className="sr-only">(<Trans>nav.Branchs</Trans>)</span>
							</Nav.Link>

							{
								data?.countries && data?.countries.length > 0 ? (
									<Navbar.Collapse className={'select-countries'}>
										<Nav>
											<NavDropdown
												id="countries"
												title={<span> {data?.countries.filter((i) => i.phone_cde === country.phone_cde)[0].name} <img className={'flag-mob'} src={data?.flag} alt={data?.countries.filter((i) => i.phone_cde === country.phone_cde)[0].name}/> </span>}
											>
												{
													data?.countries.map((item) => (
													<NavDropdown.Item className={item.id === country?.id ? 'active' : ''} key={item.id}
														onClick={() => {
															if(country.id !== item.id){
																dispatch({type: MAIN_INFO.SET_COUNTRY_SAGA, payload: item})
															}
														}}>
														<span> <img className={'flag-mob'} src={item?.flag}  alt={item.name}/> {item.name}  </span>
														</NavDropdown.Item>
													))
												}

											</NavDropdown>
										</Nav>
									</Navbar.Collapse>
								) : ''
							}

							{
								token ? (
									<>
										<Nav.Link style={{display: 'none'}} eventKey={8} as={NavLink} exact to="/myaccount/offers">
											<Trans>nav.offers</Trans> <span className="sr-only">(<Trans>nav.offers</Trans>)</span>
										</Nav.Link>

										<Nav.Link eventKey={4} as={NavLink} className={`${window.location.pathname === '/myaccount/orders' || window.location.pathname === '/myaccount/offers' ? 'active' : ''}`} exact to="/myaccount/orders">
											<Trans>nav.My Account</Trans> <span className="sr-only">(<Trans>nav.My Account</Trans>)</span>
										</Nav.Link>

										<Nav.Link eventKey={5} as={NavLink}  exact to="/myaccount/favourites">
											<Trans>nav.favourites</Trans> <span className="sr-only">(<Trans>nav.favourites</Trans>)</span>
										</Nav.Link>
									</>
								) : (
									<>
										<Nav.Link eventKey={6} as={NavLink} exact to="/login">
										<Trans>nav.Login</Trans> <span className="sr-only">(<Trans>nav.Login</Trans>)</span>
										</Nav.Link>
										<Nav.Link eventKey={5} as={NavLink} exact to="/login">
										<Trans>nav.favourites</Trans> <span className="sr-only">(<Trans>nav.favourites</Trans>)</span>
										</Nav.Link>
									</>
								)
							}

							<CartBtn cart={cart} price={price} total_quantity={total_quantity} token={token} data={data} />

							{
								!lang || lang.code === 'en' ? (
									<a className={'nav-link change-lang-btn'} href={'#'} onClick={(e) => {
										e.preventDefault()
										dispatch({type: MAIN_INFO.SET_CSS_VARS_READY, payload: false})
										dispatch({type: MAIN_INFO.SET_SETTING_LANG, payload: {code: 'ar', dir: 'rtl'}})
										window.location.href = '/'
									}}>
										عربي
									</a>
								) : ''
							}

							{
								lang && lang.code === 'ar' ? (
									<a className={'nav-link change-lang-btn'} href={'#'} onClick={(e) => {
										e.preventDefault()
										dispatch({type: MAIN_INFO.SET_CSS_VARS_READY, payload: false})
										dispatch({type: MAIN_INFO.SET_SETTING_LANG, payload: {code: 'en', dir: 'ltr'}})
										window.location.href = '/'
									}}>
										English
									</a>
								) : ''
							}

							{
								socialLinks.length > 0 && (
									<div className={'nav-link change-lang-btn'}>
										{
											socialLinks.map(({link, name, id}) => {
												let icon = name;
												switch (name) {
													case 'فيسبوك':
														icon = 'facebook';
														break
													case 'تويتر':
														icon = 'twitter';
														break
													case 'واتس اب':
														icon = 'whatsapp';
														break

													case 'انستجرام':
														icon = 'instagram';
														break

													case 'يوتيوب':
														icon = 'youtube';
														break

													case 'سناب شات':
														icon = 'snapchat-ghost';
														break
													case 'فيسبوك ماسنجر':
														icon = 'comment';
														break
													case 'Messenger':
														icon = 'comment';
														break
													default:
														icon = name;
												}
												return (
													<a href={link} target={'_blank'} className={'follow-us-btn'} key={id}>
														<i className={`fa fa-${icon}`} aria-hidden="true" />
													</a>
												)
											})
										}
									</div>
								)
							}

						</Nav>
					</Navbar.Collapse>
				</Container>
			</Navbar>
			<IsAuthEmailWarning />
			<IsAdBlockWarning />
		</div>
	);

}

export default Header

