import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
  en: {
    translation: {
      "OK": "OK",
      "nav.current": "current",
      "nav.Home": "Home",
      "nav.My Account": "My Account",
      "nav.Cart": "Cart",
      "nav.Login": "Login",
      "nav.About Us": "About Us",
      "nav.Contact Us": "Contact Us",
      "nav.Countries": "Countries",
      "nav.favourites": "Favourites",
      "nav.Branchs": "Branches",

      "Cart.Sub Total": "Sub Total",
      "Cart.Extra charges may apply": "Extra charges may apply",
      "Cart.Checkout": "Checkout",
      "Checkout": "Checkout",

      "footer.Follow us": "Follow us",
      "footer.DOWNLOAD APP": "DOWNLOAD APP",
      "footer.powered_by_FlyOrder": "powered by",
      "footer.powered_by_Brown": "powered by Brown Arrow",

      "search": "search",
      "search.Pickup from branch": "Pickup from branch",
      "search.Delivery": "Delivery",
      "search.Select Branch": "Select Branch",
      "search.Choose the delivery method": "Choose the delivery method",
      "Search.Search": "Search",
      "search.Main Menu Description": "Best seller",
      "View Menu": "View Menu",
      "Menu.ITEMS": "ITEMS",
      "Menu.Cal": "Cal",
      "Menu.Choose": "Choose",
      "Menu.Ok": "OK",
      "Menu.Add": "Add",
      "Menu.Notes": "Notes",
      "Menu.the size": "the size",
      "Menu.minimum": "minimum",
      "Menu.maximum": "maximum",
      "Menu.Check the options": "Check the options",


      "invoice.Thanks for choosing": "Thanks for choosing",
      "invoice.Here are your order details": "Here are your order details",
      "invoice.Order No": "Order No",
      "invoice.Grand Total": "Grand Total",
      "invoice.Item Name": "Item Name",
      "invoice.Quantity": "Quantity",
      "invoice.Price": "Price",
      "invoice.Order Date": "Order Date",
      "Name": "Name",
      "First Name": "First Name",
      "Last Name": "Last Name",
      "Mobile": "Mobile",
      "More Details": "More Details",
      "delivery address": "delivery address",
      "Enter the address": "Enter the address",
      "Enter Email Address": "Enter Email Address",
      "address description": "address description",
      "Something went wrong": "Something went wrong",
      "delivery Location": "delivery Location",
      "Delivered to this point": "Delivered to this point",
      "Add items into your basket": "Add items into your basket",
      "Item Total": "Item Total",
      "Restaurant Charges": "Charges",
      "Total discount breakup": "Total discount breakup",
      "Total Discount": "Total Discount",
      "TO PAY": "TO PAY",
      "Delivery Fee": "Delivery Fee",
      "Invoice": "Invoice",
      "Past Orders": "Past Orders",
      "There are no previous Orders": "There are no previous Orders",
      "Delivered on": "Delivered on",
      "ORDER": "ORDER",
      "RATE": "RATE",
      "Total Paid": "Total Paid",
      "CONTACT US": "CONTACT US",

      "EDIT" : "EDIT",
      "LOGOUT": "LOGOUT",
      "Orders" : "Orders",
      "Offers" : "Offers",
      "coupons": 'Coupons',
      "Offer" : "Offer",
      "Favourites" : "Favourites",
      "Your Order": "Your Order",
      "HELP" : "HELP",
      "Sign in": "Sign in",
      "Contact Us": "Contact Us",
      "Welcome": "Welcome",
      "Send": "Send" ,
      "Please enter Phone number": "Please enter Phone number",
      "Verification Code": "Verification Code",
      "Code": "Code",
      "Please enter Verification Code": "Please enter Verification Code",
      "Phone number": "Phone number",
      "Email": "Email",
      "Message": "Message",
      "SEND": "SEND",
      "CALL": "CALL",
      "E-MAIL": "E-MAIL",
      "ADDRESS": "ADDRESS",
      "LOADING": "LOADING",
      "LOADING...": "LOADING....",
      "has been sent": "has been sent",
      "Detail": 'Detail',
      "Order Detail": "Order Detail",
      "Price on Selection": "Price on Selection",
      "CANCEL": 'CANCEL',
      "Edit profile": "Edit profile",
      "comment": "comment",
      "UPDTAE": "UPDTAE",
      "Rate Order": "Rate Order",
      "All fields are required": "All fields are required",
      "Verify that the data is correct": "Verify that the data is correct",
      "DELIVER TO": "DELIVER TO",
      "PAYMENT WAY": "PAYMENT WAY",
      "ITEMS": "ITEMS",
      "Receipt from the branch": "Receipt from the branch",
      "Choose payment method": "Choose payment method",
      "Order Accept" :"Order Accept",
      "In the kitchen" :"In the kitchen",
      "on the way" :"on the way",
      "Completed order" :"Completed order",
      "required": "Required",
      "FROM" : "FROM",
      "Order Details": "Order Details",
      "Order": "Order",
      "Cost": "Cost",
      "Payment Fee": "Payment Fee",
      "TOTAL BILL": "TOTAL BILL",
      "sold out" : "sold out",
      "Promo Code is incorrect": "Promo Code is incorrect",
      "Subtotal": "Subtotal",
      "Items": "Items",
      "This branch is currently closed": "This branch is currently closed",
      "This offer is available from date": "This offer is available from date",
      "from date": "from date",
      "to date": "to date",
      "for a value": "for a value",
      "A maximum": "A maximum",
      "Use coupon": "Use coupon",
      "Order on": 'Order on',
      "This place is not supported" : "This place is not supported",
      "This location is not supported, choose another delivery method and try again" : "This location is not supported, choose another delivery method and try again",
      "Minimum order": "Minimum order",
      "Copy Code to Clipboard": "Copy Code to Clipboard",
      "Copy": "Copy",
      "There are no favourites": "There are no favourites",
      "There are no offers": "There are no offers",
      "There are no coupons": "There are no coupons",
      "Please choose a delivery method first": "Please choose a delivery method first",
      "payment fee": "payment fee",
      "MIN": "MIN",
      "Delivery time": "Delivery time",
      "Branchs": "Branches",
      "Worktime": "Worktime",
      "now open": "now open",
      "closed now": "closed now",
      "open": "open",
      "close": "close",
      "Phone number is incorrect": "Phone number is incorrect",
      "Email Address is incorrect": "Email Address is incorrect",
      "Verification Code is incorrect": "Verification Code is incorrect",
      "Loading": "Loading",
      "Price after discount": "Price after discount",
      "Enter promo code": "Enter promo code",
      "APPLY": "APPLY",
      "Enter More Details": "Enter More Details",
      "Enter Mobile number": "Enter Mobile number",
      "Enter Name": "Enter Name",
      "Enter First Name": "Enter First Name",
      "Enter Last Name": "Enter Last Name",
      "Product Info": "Product Info",
      "footer.DownloadAppNow": "DOWNLOAD APP NOW",
      "Show More": "Show More",
      "Hide More": "Show less",
      "emailWarning": "Warning : You must add your e-mail to your account via (My Account => Edit) in order to receive the status of the order by e-mail",
      "Delivery Address": "Delivery Address",
      "adBlockWarning": "You are using an ad blocker. Our services may not function properly",
      "Payment will be made through this number. Change it if you want to":"Payment will be made through this number. Change it if you want to",
      "Page not found": "Page not found",
      "Uh-oh! Looks like the page you are trying to access, doesn't exist. Please start afresh.": "Uh-oh! Looks like the page you are trying to access, doesn\'t exist. Please start afresh.",
      "GO HOME": "GO HOME",
      "Payment Failed": "Payment method failed",
      "Sorry, there was an error in the payment process": "Please change your payment method and try again",
      "To use this service you need to add your email to can receive your payment reference number": "To use this service you need to add your email to can receive your payment reference number",
      "Successfully": "Successfully",
      "Use this reference number": "Use this reference number",
      "to complete your payment process": "to complete your payment process",
      "and it wall be send to your email also": "and it wall be send to your email also",
      "SHOW ORDER": "Track order",
      "Waiting for the payment process to be completed using the reference number": "Waiting for the payment process to be completed using the reference number",
      "until the order is completed": "until the order is completed"
    }
  },
  ar: {
    translation: {
      "OK": "موافق",
      "nav.current": "تيار",
      "nav.Home": "الرئيسية",
      "nav.My Account": "حسابي",
      "nav.Cart": "عربة التسوق",
      "nav.Login": "تسجيل",
      "nav.About Us": "من نحن",
      "nav.Contact Us": "اتصل بنا",
      "nav.Countries": "الدول",
      "nav.favourites": "المفضلة",
      "nav.Branchs": "الفروع",
      "Cart.Sub Total": "المجموع الفرعي",
      "Cart.Extra charges may apply": "قد يتم تطبيق رسوم إضافية",
      "Cart.Checkout": "الدفع",
      "Checkout": "اطلب الان",

      "footer.Follow us": "تابعنا",
      "footer.DOWNLOAD APP": "تحميل التطبيق",
      "footer.powered_by_FlyOrder": "powered by",
      "footer.powered_by_Brown": "powered by Brown Arrow",
      "search": "بحث",
      "search.Pickup from branch": "الاستلام من الفرع",
      "search.Delivery": "توصيل",
      "search.Select Branch": "حدد الفرع",
      "search.Choose the delivery method": "اختر طريقة التوصيل",
      "Search.Search": "بحث",
      "search.Main Menu Description": "الاكثر مبيعا",
      "View Menu": "القائمة",
      "Menu.ITEMS": "قطعة",
      "Menu.Cal": "كالوري",
      "Menu.Choose": "اختر",
      "Menu.Ok": "موافق",
      "Menu.Add": "اضافة",
      "Menu.Notes": "ملاحظة",
      "Menu.the size": "الحجم",
      "Menu.minimum": "الحد الأدنى",
      "Menu.maximum": "أقصى",
      "Menu.Check the options": "تأكد من الخيارات",

      "invoice.Thanks for choosing": "شكرًا لاختيارك",
      "invoice.Here are your order details": "ها هي تفاصيل طلبك",
      "invoice.Order No": "رقم الطلب",
      "invoice.Grand Total": "الإجمالي ",
      "invoice.Item Name": "الاسم",
      "invoice.Quantity": "الكمية",
      "invoice.Price": "السعر",
      "invoice.Order Date": "تاريخ الطلب",


      "Name": "الاسم",
      "First Name": "الاسم الأول",
      "Last Name": "الاسم الأخير",
      "Mobile": "الجوال",
      "More Details": "مزيد من التفاصيل",
      "delivery address": "عنوان التسليم",
      "Enter the address": "ادخل العنوان",
      "Enter Email Address": "ادخل عنوان البريد الالكتروني",
      "address description": "وصف العنوان",
      "Something went wrong": "حدث خطأ",
      "delivery Location": "موقع التسليم",
      "Delivered to this point": "تم التسليم إلى هذه النقطة",
      "Your Order": "طلبك",
      "Add items into your basket": "أضف عناصر إلى سلتك",
      "Item Total": "إجمالي العناصر",
      "Restaurant Charges": "الضرائب",
      "Total discount breakup": "إجمالي تفصيل الخصم",
      "Total Discount": "الخصم الإجمالي",
      "TO PAY": "للدفع",
      "Delivery Fee": "رسوم التوصيل",
      "Invoice": "فاتورة",

      "Past Orders": "الطلبات السابقة",
      "There are no previous Orders": "لا توجد أوامر سابقة",
      "Delivered on": "تم التسليم",
      "ORDER": "طلب",
      "RATE": "قيم",
      "Total Paid": "الاجمالي",
      "CONTACT US": "اتصل بنا",

      "EDIT" : "تعديل",
      "LOGOUT": "خروج",
      "Orders" : "طلباتي",
      "Offers" : "عروض",
      "coupons": 'كوبونات',
      "Offer" : "خصم",
      "Favourites" : "المفضلة",
      "HELP" : "مساعده",
      "Sign in": "تسجيل الدخول",
      "Contact Us": "اتصل بنا",
      "Welcome": "مرحبا",
      "Please enter Phone number": "من فضلك ادخل رقم الهادف",
      "Verification Code": "رمز التحقق",
      "Code": "الرمز",
      "Please enter Verification Code": "الرجاء إدخال رمز التحقق",
      "Send": "ارسال" ,
      "Phone number": "رقم الهاتف",
      "Email": "البريد الالكتروني",
      "Message": "الرسالة",
      "SEND": "ارسال",
      "CALL": "الهاتف",
      "E-MAIL": "البريد الالكتروني",
      "ADDRESS": "العنوان",
      "LOADING": "جاري التحميل",
      "LOADING...": "ارسال....",
      "has been sent" : 'تم الارسال',
      "Detail": 'التفاصيل',
      "Order Detail": "تفاصيل الطلب",
      "Price on Selection": "السعر عند الاختيار",
      "CANCEL": 'إلغاء',
      "Edit profile": "تعديل الملف الشخصي",
      "comment": "التعليق",
      "UPDTAE": "تعديل",
      "Rate Order": "تقييم الطلب",
      "All fields are required": "جميع الحقول مطلوبة",
      "required": "مطلوب",
      "Verify that the data is correct": "تحقق من صحة البيانات",
      "DELIVER TO": "توصيل الي",
      "PAYMENT WAY": "طريقه لدفع",
      "ITEMS": "عنصر",
      "Receipt from the branch": "استلام من الفرع",
      "Choose payment method": "اختر طريقة الدفع",

      "Order Accept" :"قبول الطبل",
      "In the kitchen" :"فى المطبخ",
      "on the way" :"فى الطريق",
      "Completed order" :"طلب مكتمل",

      "FROM" : "من فرع",
      "Order Details": "تفاصيل الطلب",
      "Order": "الطلب",
      "Cost": "السعر",
      "Payment Fee": "رسوم الدفع",
      "TOTAL BILL": "إجمالي الفاتورة",
      "sold out" : "غير متاح",
      "Promo Code is incorrect": "الرمز الترويجي غير صحيح",
      "Subtotal": "الاجمالي",
      "Items" : "عنصر",
      "This branch is currently closed" : "هذا الفرع مغلق حاليا",
      "This offer is available from date": "هذا العرض متاح من تاريخ",
      "from date": "من تاريخ",
      "to date": "حتي يوم",
      "for a value": "بقيمة",
      "A maximum": "بحد اقصي",
      "Use coupon": "أستخدم العرض",
      "Order on": 'تاريخ الطلب',
      "This place is not supported" : "هذا المكان غير مدعوم",
      "This location is not supported, choose another delivery method and try again" : "هذا المكان غير مدعوم،  اختر طريقة توصيل اخري وحاول مجددا",
      "Minimum order": "الحد الادني للطلب",
      "Copy Code to Clipboard": "نسخ الرمز",
      "Copy": "نسخ",
      "There are no favourites": "لا يوجد اي تفضيلات",
      "There are no offers": "لا يوجد اي عروض",
      "There are no coupons": "لا يوجد اي كوبونات",
      "Please choose a delivery method first": "من فضلك اختر طريقة التوصيل اولا",
      "payment fee": "رسوم الدفع",
      "MIN": "دقيقة",
      "Delivery time": "وقت التوصيل",
      "Branchs": "الفروع",
      "Worktime": "اوقات العمل",
      "now open": "مفتوح الان",
      "open": "مفتوح",
      "close": "مغلق",
      "closed now": "مغلق الان",
      "Phone number is incorrect": "تأكد من رقم الهاتف",
      "Email Address is incorrect": "البريد الالكتروني غير صحيح",
      "Verification Code is incorrect": "رمز التحقق غير صحيح",
      "Loading": "جار التحميل",
      "Price after discount": "السعر بعد الخصم",
      "Enter promo code": "إدخال الرمز الترويجي",
      "APPLY": "تطبيق",
      "Enter More Details": "أدخل مزيد من التفاصيل",
      "Enter Mobile number": "أدخل رقم الهاتف",
      "Enter Name": "أدخل الاسم",
      "Enter First Name": "أدخل الاسم الأول",
      "Enter Last Name": "أدخل الاسم الأخير",
      "Product Info": "معلومات المنتج",
      "footer.DownloadAppNow": "حمل التطبيق الأن",
      "Show More": 'المزيد',
      "Hide More": 'اخفاء',
      "emailWarning": "تنبيه  :  يجب عليك اضافة البريد الالكتروني الخاص بك في حسابك بواسطة ( حسابي => تعديل ) لكي يصلك حاله الاوردر عن طريق البريد الالكتروني",
      "Delivery Address": "عنوان التوصيل",
      "adBlockWarning": "أنت تستخدم مانع الإعلانات. قد لا تعمل خدماتنا بشكل صحيح",
      "Payment will be made through this number. Change it if you want to":"ستتم اجرائات الدفع من خلال هذا الرقم. قم بتغييره اذا ارد ذلك",
      "Page not found": "الصفحة غير موجودة",
      "Uh-oh! Looks like the page you are trying to access, doesn't exist. Please start afresh.": "عذرًا! يبدو أن الصفحة التي تحاول الوصول إليها غير موجودة. يرجى البدء من جديد.",
      "GO HOME": "الرئيسية",
      "Payment Failed": "فشل طريقة الدفع",
      "Sorry, there was an error in the payment process": "نتمني تغيير طريقة الدفع واعادة المحاولة",
      "To use this service you need to add your email to can receive your payment reference number": "لاستخدام هذه الخدمة ، تحتاج إلى إضافة بريدك الإلكتروني لتتمكن من استلام الرقم المرجعي للدفع",
      "Successfully": "تم الطلب بنجاح",
      "Use this reference number": "استخدم هذا الرقم المرجعي",
      "to complete your payment process": "لإكمال عملية الدفع الخاصة بك",
      "and it wall be send to your email also": "وسيتم إرساله إلى بريدك الإلكتروني أيضًا",
      "SHOW ORDER": "تفاصيل الطلب",
      "Waiting for the payment process to be completed using the reference number": "في انتظار اكتمال عملية الدفع باستخدام الرقم المرجعي",
      "until the order is completed": "حتى اكتمال الطلب"
    }
  }
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: JSON.parse(localStorage.getItem('LANG')) ? JSON.parse(localStorage.getItem('LANG')).code : 'en',
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
