import interceptor from "./interceptors/default-interceptor";
import {getLangCode} from "./getLangCode";
import axios from "axios";
import {call} from "redux-saga/effects";

const MainInformationService = {}
MainInformationService.getCountries = function (params) {
  return new Promise((resolve, reject) => {
    const link = `${interceptor.conf.baseURL}/api/countries`;
    const url = params ? interceptor.toQueryString(link, params) : link;
    return fetch(url, {
      method: 'GET',
      headers: {
        'Content-Language': getLangCode(),
        ...interceptor.headers
      }
    })
      .then(response => response.json())
      .then(data => resolve(data))
      .catch((e) => reject(e));
  })
}

MainInformationService.getSettings = function (params) {
  return new Promise((resolve, reject) => {
    const link = `${interceptor.conf.baseURL}/api/settings/${params.country}`;
    const url = params ? interceptor.toQueryString(link, params) : link;
    return fetch(url, {
      method: 'GET',
      headers: {
        'Content-Language': getLangCode(),
        ...interceptor.headers
      }
    })
      .then(response => response.json())
      .then(data => resolve(data))
      .catch((e) => reject(e));
  })
}
MainInformationService.getSlider = function (params) {
  return new Promise((resolve, reject) => {
    const link = `${interceptor.conf.baseURL}/api/sliders/web`;
    const url = params ? interceptor.toQueryString(link, params) : link;
    return fetch(url, {
      method: 'GET',
      headers: {
        'Content-Language': getLangCode(),
        ...interceptor.headers
      }
    })
      .then(response => response.json())
      .then(data => resolve(data))
      .catch((e) => reject(e));
  })
}

MainInformationService.getBest = function ({id, ...params}) {
  return new Promise((resolve, reject) => {
    const link = `${interceptor.conf.baseURL}/api/products/best/${id}`;
    const url = params ? interceptor.toQueryString(link, params) : link;
    return fetch(url, {
      method: 'GET',
      headers: {
        'Content-Language': getLangCode(),
        ...interceptor.headers
      }
    })
      .then(response => response.json())
      .then(data => resolve(data))
      .catch((e) => reject(e));
  })
}

MainInformationService.getCouponsById = function ({id, ...params}) {
  const country = () => {
    if(localStorage.getItem('country')){
      return JSON.parse(localStorage.getItem('country')).id
    }
    return 1
  }

  return new Promise((resolve, reject) => {
    const link = `${interceptor.conf.baseURL}/api/coupons/${country()}/${id}`;
    const url = params ? interceptor.toQueryString(link, params) : link;
    return fetch(url, {
      method: 'GET',
      headers: {
        'Content-Language': getLangCode(),
        ...interceptor.headers
      }
    })
      .then(response => response.json())
      .then(data => resolve(data))
      .catch((e) => reject(e));
  })
}

MainInformationService.getUserCoupons = function ({country, ...params}) {
  return new Promise((resolve, reject) => {
    const link = `${interceptor.conf.baseURL}/api/coupons/user`;
    const url = params ? interceptor.toQueryString(link, params) : link;
    const _token = localStorage.getItem('AUTH_TOKEN');
    return fetch(url, {
      method: 'GET',
      headers: {
        'Content-Language': getLangCode(),
        'Authorization': `Bearer ${_token}`,
        ...interceptor.headers
      }
    })
      .then(response => response.json())
      .then(data => resolve(data))
      .catch((e) => reject(e));
  })
}

MainInformationService.getAllCoupons = function ({country, ...params}) {
  return new Promise((resolve, reject) => {
    const link = `${interceptor.conf.baseURL}/api/coupons/${country}`;
    const url = params ? interceptor.toQueryString(link, params) : link;
    return fetch(url, {
      method: 'GET',
      headers: {
        'Content-Language': getLangCode(),
        ...interceptor.headers
      }
    })
      .then(response => response.json())
      .then(data => resolve(data))
      .catch((e) => reject(e));
  })
}

MainInformationService.getCities = function (params) {
  return new Promise((resolve, reject) => {
    const link = `${interceptor.conf.baseURL}/api/cities/${params.countryId}`;
    const url = params ? interceptor.toQueryString(link, params) : link;
    return fetch(url, {
      method: 'GET',
      headers: {
        'Content-Language': getLangCode(),
        ...interceptor.headers
      }
    })
      .then(response => response.json())
      .then(data => resolve(data))
      .catch((e) => reject(e));
  })
}

MainInformationService.getRegions = function ({id, ...params}) {
  return new Promise((resolve, reject) => {
    const link = `${interceptor.conf.baseURL}/api/regions/city/${id}`;
    const url = params ? interceptor.toQueryString(link, params) : link;
    return fetch(url, {
      method: 'GET',
      headers: {
        'Content-Language': getLangCode(),
        ...interceptor.headers
      }
    })
      .then(response => response.json())
      .then(data => resolve(data))
      .catch((e) => reject(e));
  })
}

MainInformationService.contact = function (params) {
  const url = `${interceptor.conf.baseURL}/api/contact/save`;
  const _token = localStorage.getItem('AUTH_TOKEN');
  return axios.post(url, params, {
    headers: {
      'Content-Type' : 'application/json',
      'Content-Language': getLangCode(),
      'Authorization': `Bearer ${_token}`,
      ...interceptor.headers
    }
  })

}




export default MainInformationService
