import { all } from 'redux-saga/effects';
import MainInformationSaga from './main-information';
import Branches from './branches';
import Cart from './cart';
import Auth from './auth';

export default function* rootSaga(getState) {
  yield all([
    MainInformationSaga(),
    Branches(),
    Cart(),
    Auth()
  ]);
}
