import { combineReducers } from 'redux';
import MainInformation from './main-information';
import Branches from './branches';
import Cart from './cart';
import Auth from './auth';

const reducers = combineReducers({
  mainInformation: MainInformation,
  branches: Branches,
  cart: Cart,
  auth: Auth
});

export default reducers;
