import { all, takeEvery, put, fork, call, delay } from 'redux-saga/effects';
import {MAIN_INFO} from '../constants/main-information';
import MainInformationService from "../../services/main-information";
import BranchesService from "../../services/branches";
import login from "../../components/Login";
import {AUTH} from "../constants/auth";

function setCssVars(vars) {
  const style = document.createElement('style');
  const {side_menu, header, button, homepage} = vars.data;
  const {side_menu_bg_color, side_menu_text_color} = side_menu;
  const {homepage_text_color, homepage_bg_color} = homepage;
  const {header_bg_color, header_text_color} = header;
  const {button_color, button_color_text} = button;
  const root = `
    :root {
      --side_menu_bg_color: ${side_menu_bg_color};
      --side_menu_text_color: ${side_menu_text_color};
      
      --header_bg_color: ${side_menu_bg_color};
      --header_text_color: ${side_menu_text_color};
      
      
      --home_bg_color: ${homepage_bg_color};
      --home_text_color: ${homepage_text_color};
      
      --button_color: ${button_color};
      --button_color_text: ${button_color_text};
      --color_gradients_1: ${vars.data.two_color_gradients[0]};
      --color_gradients_2: ${vars.data.two_color_gradients[1]};
    }`

  style.textContent = root;
  document.head.append(style);
}

export function* updateSettings() {
  yield takeEvery(MAIN_INFO.UPDATE_SETTINGS, function* ({payload}) {
    try {
      const country = yield call(() => {
        if(localStorage.getItem('country')){
          return JSON.parse(localStorage.getItem('country')).id
        }
        return 1
      })
      const res = yield call(MainInformationService.getSettings, {country, ...payload})
      const slider = yield call(MainInformationService.getSlider, {country, ...payload})
      const countries = yield call(MainInformationService.getCountries, payload)
      const branches = yield call(BranchesService.getBranches, {...payload, country});



      yield put({type: MAIN_INFO.SET_SETTINGS, payload: {...res.data, slider: slider.data,  countries: countries.data}});
      yield put({type: MAIN_INFO.GET_SETTINGS_STATUS, payload: 'DONE'})

      const search = yield localStorage.getItem('search');
      let best = [];
      if(search){
        let _search = {}
        let bId = branches.data[0].id;
        search.split('&').map((i) => {
          _search[i.split('=')[0]] = i.split('=')[1]
        })

        if(_search.hasOwnProperty('lat')){
          const res = yield call(BranchesService.getDeliveryBranchByLocation, {latitude: Number(_search.lat), longitude: Number(_search.lng)})
          if(res?.branch){
            bId = res.branch.id
          }
        }
        if(_search.hasOwnProperty('branch')){
          bId = _search.branch
        }
        best = yield call(MainInformationService.getBest, {...payload, id: bId});
      }else {
        best = yield call(MainInformationService.getBest, {...payload, id: branches.data[0].id});
      }

      let coupons = []
      let userCoupons = []
      try {
        const _coupons = yield call(MainInformationService.getAllCoupons, {...payload, country});
        if(!_coupons.hasOwnProperty('status')){
          coupons = _coupons
        }
        if(localStorage.getItem('AUTH_TOKEN')){
          const _userCoupons = yield call(MainInformationService.getUserCoupons, {...payload, country});
          if(!_userCoupons.hasOwnProperty('status')){
            userCoupons = _userCoupons
          }
        }
      }catch (e) {}
      yield call(setCssVars, res);
      yield put({type: MAIN_INFO.SET_CSS_VARS_READY, payload: true})
      yield put({type: MAIN_INFO.SET_SETTINGS, payload: {...res.data, slider: slider.data, best: best.data, countries: countries.data, coupons: [...coupons, ...userCoupons]}});
      yield put({type: MAIN_INFO.GET_SETTINGS_STATUS, payload: 'DONE'})
    } catch (error) {
      yield put({type: MAIN_INFO.GET_SETTINGS_STATUS, payload: 'ERROR'})
      yield put({type: MAIN_INFO.GET_SETTINGS_ERROR, payload: error})
    }
  });
}

export function* getSettings() {
  yield takeEvery(MAIN_INFO.GET_SETTINGS, function* ({payload}) {

    try {
      const country = yield call(() => {
        if(localStorage.getItem('country')){
          return JSON.parse(localStorage.getItem('country')).id
        }
        return 1
      })
      yield put({type: MAIN_INFO.GET_SETTINGS_STATUS, payload: 'WAIT'})
      const res = yield call(MainInformationService.getSettings, {country, ...payload})
      const slider = yield call(MainInformationService.getSlider, {country, ...payload})
      const countries = yield call(MainInformationService.getCountries, payload)
      const branches = yield call(BranchesService.getBranches, {...payload, country});


      if(countries?.data && !localStorage.getItem('country')){
        localStorage.setItem('country', JSON.stringify(countries.data[0]))
      }

      const search = yield localStorage.getItem('search');

      let best = [];
      if(search){
        let _search = {}
        let bId = branches.data[0].id;
        search.split('&').map((i) => {
          _search[i.split('=')[0]] = i.split('=')[1]
        })

        if(_search.hasOwnProperty('lat')){
          const res = yield call(BranchesService.getDeliveryBranchByLocation, {latitude: Number(_search.lat), longitude: Number(_search.lng)})
          if(res?.branch){
            bId = res.branch.id
          }
        }
        if(_search.hasOwnProperty('branch')){
          bId = _search.branch
        }
        best = yield call(MainInformationService.getBest, {...payload, id: bId});
      }else {
        best = yield call(MainInformationService.getBest, {...payload, id: branches.data[0].id});
      }

      let coupons = []
      let userCoupons = []
      try {
        const _coupons = yield call(MainInformationService.getAllCoupons, {...payload, country});
        if(!_coupons.hasOwnProperty('status')){
          coupons = _coupons
        }
        if(localStorage.getItem('AUTH_TOKEN')){
          const _userCoupons = yield call(MainInformationService.getUserCoupons, {...payload, country});
          if(!_userCoupons.hasOwnProperty('status')){
            userCoupons = _userCoupons
          }
        }
      }catch (e) {}


      yield call(setCssVars, res);
      yield delay(1000)
      yield put({type: MAIN_INFO.SET_CSS_VARS_READY, payload: true})
      yield put({type: MAIN_INFO.SET_SETTINGS, payload: {...res.data, slider: slider.data, best: best.data, countries: countries.data, coupons: [...coupons, ...userCoupons]}});
      yield put({type: MAIN_INFO.GET_SETTINGS_STATUS, payload: 'DONE'})
    } catch (error) {
      yield put({type: MAIN_INFO.GET_SETTINGS_STATUS, payload: 'ERROR'})
      yield put({type: MAIN_INFO.GET_SETTINGS_ERROR, payload: error})
    }
  });
}

export function* updateBest() {
  yield takeEvery(MAIN_INFO.UPDATE_BEST, function* ({payload}) {
    try {
      const best = yield call(MainInformationService.getBest, {...payload, id: payload.id});
      yield put({type: MAIN_INFO.SET_BEST_TO_SETTINGS, payload: {best: best.data}});
    }catch (e) {

    }
  });
}
export function* getCities() {
  yield takeEvery(MAIN_INFO.GET_CITIES, function* ({payload}) {
    try {
      const country = yield call(() => {
        if(localStorage.getItem('country')){
          return JSON.parse(localStorage.getItem('country')).id
        }
        return 1
      })
      yield put({type: MAIN_INFO.GET_CITIES_STATUS, payload: 'WAIT'})
      const res = yield call(MainInformationService.getCities, {...payload, countryId: country})
      yield put({type: MAIN_INFO.GET_REGIONS, payload: res})
      yield put({type: MAIN_INFO.SET_CITIES, payload: res.data});
      yield put({type: MAIN_INFO.GET_CITIES_STATUS, payload: 'DONE'})
    } catch (error) {
      yield put({type: MAIN_INFO.GET_CITIES_STATUS, payload: 'ERROR'})
      yield put({type: MAIN_INFO.GET_CITIES_ERROR, payload: error})
    }
  });
}

const reshapeRegions = (payload) => {
  return new Promise((resolve, reject) => {
    const _regions = {};
    payload.data.map((i) => i.id).map( async (i) => {
      const res = await MainInformationService.getRegions({id: i});
      _regions[res.data[0].city] = res.data;
      if(Object.keys(_regions).length === payload.data.length){
        resolve(_regions)
      }
    })
  })
}

export function* getRegions() {
  yield takeEvery(MAIN_INFO.GET_REGIONS, function* ({payload}) {
    yield put({type: MAIN_INFO.GET_REGIONS_STATUS, payload: 'WAIT'})
    try {
      const regions = yield call(reshapeRegions, payload)
      yield put({type: MAIN_INFO.SET_REGIONS, payload: regions});
      yield put({type: MAIN_INFO.GET_REGIONS_STATUS, payload: 'DONE'})
    } catch (error) {
      yield put({type: MAIN_INFO.GET_REGIONS_STATUS, payload: 'ERROR'})
      yield put({type: MAIN_INFO.GET_REGIONS_ERROR, payload: error})
    }
  });
}

export function* setCountry() {
  yield takeEvery(MAIN_INFO.SET_COUNTRY_SAGA, function* ({payload}) {
    localStorage.clear();
    localStorage.setItem('country', JSON.stringify(payload))
    yield put({type: MAIN_INFO.SET_COUNTRY, payload})
    yield put({type: AUTH.SIGNOUT, payload})
    yield call(() => {
      document.location.href="/";
    })
  });
}

export default function* rootSaga() {
  yield all([
    fork(getSettings),
    fork(getCities),
    fork(getRegions),
    fork(updateBest),
    fork(updateSettings),
    fork(setCountry)
  ]);
}
