import {AUTH} from '../constants/auth';

const initState = {
  loading: false,
  message: '',
  showMessage: false,
  redirect: '',
  token: localStorage.getItem('AUTH_TOKEN'),
  user: {}
}

const auth = (state = initState, action) => {
  switch (action.type) {
    case AUTH.AUTHENTICATED:
      return {
        ...state,
        loading: false,
        token: action.token,
        user: action.user,
      }
    case AUTH.UPDATE_PROFILE_DATE:
      return {
        ...state,
        user: {
          ...state.user,
          metadata: {
            ...state.user.metadata,
            ...action.payload
          }
        },
      }
    case AUTH.SHOW_AUTH_MESSAGE:
      return {
        ...state,
        message: action.message,
        showMessage: true,
        loading: false
      }
    case AUTH.HIDE_AUTH_MESSAGE:
      return {
        ...state,
        message: '',
        showMessage: false,
      }
    case AUTH.SIGNOUT_SUCCESS: {
      return {
        ...state,
        token: null,
        redirect: '/',
        loading: false
      }
    }
    case AUTH.SIGNUP_SUCCESS: {
      return {
        ...state,
        loading: false,
        token: action.token
      }
    }
    case AUTH.SHOW_LOADING: {
      return {
        ...state,
        loading: true
      }
    }
    case AUTH.SIGNIN_WITH_GOOGLE_AUTHENTICATED: {
      return {
        ...state,
        loading: false,
        token: action.token
      }
    }
    case AUTH.SIGNIN_WITH_FACEBOOK_AUTHENTICATED: {
      return {
        ...state,
        loading: false,
        token: action.token
      }
    }
    default:
      return state;
  }
}

export default auth
