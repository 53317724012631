export const CART = {

  GET_CART: 'GET_CART',
  UPDATE_HASH_KEY: 'UPDATE_HASH_KEY',
  CHANGE_HASH_KEY: 'CHANGE_HASH_KEY',
  SET_CART: 'SET_CART',
  GET_CART_STATUS: 'GET_CART_STATUS',
  GET_CART_ERROR: 'GET_CART_ERROR',

  EMPTYING_CART: 'EMPTYING_CART',
  ACTIVE_CART: 'ACTIVE_CART',
  ACTIVE_CART_ERROR: 'ACTIVE_CART_ERROR',
  ACTIVE_CART_STATUS: 'ACTIVE_CART_STATUS',

  ADD_TO_CART: 'ADD_TO_CART',
  ADD_TO_CART_STATUS: 'ADD_TO_CART_STATUS',
  SET_CART_FINISH: 'SET_CART_FINISH',
  ADD_TO_CART_ERROR: 'ADD_TO_CART_ERROR',
  REMOVE_ITEM: 'REMOVE_ITEM',


  COUPON_VALIDITY: 'COUPON_VALIDITY',
  COUPON_VALIDITY_STATUS: 'COUPON_VALIDITY_STATUS',
  SET_COUPON: 'SET_COUPON',
  COUPON_VALIDITY_ERROR: 'COUPON_VALIDITY_ERROR',

  MAKE_ORDER: 'MAKE_ORDER',
  MAKE_ORDER_STATUS: 'MAKE_ORDER_STATUS',
  SET_MAKE_ORDER: 'SET_MAKE_ORDER',
  MAKE_ORDER_ERROR: 'MAKE_ORDER_ERROR',


  CANCEL_ORDER: 'CANCEL_ORDER',
  CANCEL_ORDER_STATUS: 'CANCEL_ORDER_STATUS',
  SET_CANCEL_ORDER: 'SET_CANCEL_ORDER',
  CANCEL_ORDER_ERROR: 'CANCEL_ORDER_ERROR',


  GET_ORDERS: 'GET_ORDERS',
  UPDATE_ORDERS: 'UPDATE_ORDERS',
  GET_ORDERS_STATUS: 'GET_ORDERS_STATUS',
  SET_ORDERS: 'SET_ORDERS',
  GET_ORDERS_ERROR: 'GET_ORDERS_ERROR',


  GET_ORDER: 'GET_ORDER',
  UPDATE_ORDER: 'UPDATE_ORDER',
  GET_ORDER_STATUS: 'GET_ORDER_STATUS',
  SET_ORDER: 'SET_ORDER',
  GET_ORDER_ERROR: 'GET_ORDER_ERROR',

  UPDATE_QTY: 'UPDATE_QTY',
  HASH_KEY: 'hash_key',

  SEARCH: 'search',

  ADD_TO_WISH_LIST: 'ADD_TO_WISH_LIST',
  REMOVE_TO_WISH_LIST: 'REMOVE_TO_WISH_LIST',
  GET_WISH_LIST: 'GET_WISH_LIST',
  GET_ORDERS_NOT_RATED: 'GET_ORDERS_NOT_RATED',
}
