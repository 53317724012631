import React from 'react';
import {Link} from 'react-router-dom';
import {Row,Col,Container,Image} from 'react-bootstrap';
import {useTranslation} from "react-i18next";
import {CART} from "../../redux/constants/cart";

const PaymentError = () => {
	const {t} = useTranslation();
	return (
		<section className="section pt-5 pb-5 osahan-not-found-page">
			<Container>
				<Row>
					<Col md={12} className="text-center pt-5 pb-5">
						<Image className="img-fluid" src="/img/cancel.png" alt="payment-error" />
						<h1 className="mt-5 mb-3 text-center">{t('Payment Failed')}</h1>
						<p className={'text-center'} style={{fontSize: '19px'}}>{t('Sorry, there was an error in the payment process')}</p>
						<Link className="btn btn-primary btn-lg" to={`/checkout?${localStorage.getItem(CART.SEARCH)}`}>{t('Checkout')}</Link>
					</Col>
				</Row>
			</Container>
		</section>
	)
}

export default PaymentError;
