import interceptor from './interceptors/default-interceptor';
import {getLangCode} from "./getLangCode";
const BranchesService = {}

BranchesService.getBranches = function ({country, ...params}) {
  return new Promise((resolve, reject) => {
    const link = `${interceptor.conf.baseURL}/api/branches/country/${country}`;
    const url = params ? interceptor.toQueryString(link, params) : link;
    return fetch(url, {
      method: 'GET',
      headers: {
        'Content-Language': getLangCode(),
        ...interceptor.headers
      }
    })
      .then(response => response.json())
      .then(data => resolve(data))
      .catch((e) => reject(e));
  })
}

BranchesService.getSale = function (params) {

  localStorage.setItem('branch_active', params.id)

  return new Promise((resolve, reject) => {
    const _token = localStorage.getItem('AUTH_TOKEN');
    const url = `${interceptor.conf.baseURL}/api/products/sale/${params.id}`;
    return fetch(url, {
      headers: {
        'Content-Language': getLangCode(),
        'Authorization': `Bearer ${_token}`,
        ...interceptor.headers
      }
    })
        .then(response => response.json())
        .then(data => resolve(data))
        .catch((e) => reject(e));
  })
}

BranchesService.getMenu = function (params) {

  localStorage.setItem('branch_active', params.id)

  return new Promise((resolve, reject) => {
    const _token = localStorage.getItem('AUTH_TOKEN');
    const url = `${interceptor.conf.baseURL}/api/branches/${params.id}/menu`;
    return fetch(url, {
      headers: {
        'Content-Language': getLangCode(),
        'Authorization': `Bearer ${_token}`,
        ...interceptor.headers
      }
    })
      .then(response => response.json())
      .then(data => resolve(data))
      .catch((e) => reject(e));
  })
}

BranchesService.getDeliveryLocations = function () {
  return new Promise((resolve, reject) => {
    const url = `${interceptor.conf.baseURL}/api/deliveryLocations`;
    return fetch(url, {
      method: 'GET',
      headers: {
        'Content-Language': getLangCode(),
        ...interceptor.headers
      }
    })
      .then(response => response.json())
      .then(data => resolve(data))
      .catch((e) => reject(e));
  })
}

BranchesService.getDefaultMenuByCountry = function () {

  const country = () => {
    if(localStorage.getItem('country')){
      return JSON.parse(localStorage.getItem('country')).id
    }
    return 1
  }

  return new Promise((resolve, reject) => {
    const url = `${interceptor.conf.baseURL}/api/branches/default-menu/${country()}`;
    return fetch(url, {
      method: 'get',
      headers: {
        'Content-Language': getLangCode(),
        ...interceptor.headers
      }
    })
        .then(response => response.json())
        .then(data => resolve(data))
        .catch((e) => reject(e));
  })
}

BranchesService.getDeliveryBranchByLocation = function ({latitude, longitude}) {

  const country = () => {
    if(localStorage.getItem('country')){
      return JSON.parse(localStorage.getItem('country')).id
    }
    return 1
  }

  return new Promise((resolve, reject) => {
    const url = `${interceptor.conf.baseURL}/api/branch/location?latitude=${latitude}&longitude=${longitude}&country=${country()}`;
    return fetch(url, {
      method: 'post',
      headers: {
        'Content-Language': getLangCode(),
        ...interceptor.headers
      }
    })
      .then(response => response.json())
      .then(data => {
        localStorage.setItem('branch_active', data?.branch?.id)
        resolve(data)
      })
      .catch((e) => reject(e));
  })
}



export default BranchesService
