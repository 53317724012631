import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { getMessaging } from "firebase/messaging/sw";
import {onMessage} from "firebase/messaging";
import {getLangCode} from "../getLangCode";
import interceptor from "../interceptors/default-interceptor";

let firebaseConfig = {}
if(process.env.REACT_APP_WEBSITE_NAME === 'CARRYOUT'){
	firebaseConfig = {
		apiKey: "AIzaSyB_asQNP2VfU0PnQYp0LPo_43W79PW_2v4",
		authDomain: "carryout2022-d4903.firebaseapp.com",
		projectId: "carryout2022-d4903",
		storageBucket: "carryout2022-d4903.appspot.com",
		messagingSenderId: "597132775778",
		appId: "1:597132775778:web:15e064d8a64d36b6a51c3b",
		measurementId: "G-WG17FM3Q5B"

	};
}

if(process.env.REACT_APP_WEBSITE_NAME === 'FLYORDER'){
	firebaseConfig = {
		apiKey: "AIzaSyC_Wc6mbICriI7O6fq8JjvjSklyu7JqWhw",
		authDomain: "orderonline-3824d.firebaseapp.com",
		projectId: "orderonline-3824d",
		storageBucket: "orderonline-3824d.appspot.com",
		messagingSenderId: "865092382829",
		appId: "1:865092382829:web:cce26ae39bb8ee800d8f64",
		measurementId: "G-ZPGNXSBYDH"
	};
}


const firebaseApp =  firebase.initializeApp(firebaseConfig);

// firebase utils
const db = firebase.firestore();
const auth = firebase.auth();

const currentUser = auth.currentUser
const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
const facebookAuthProvider = new firebase.auth.FacebookAuthProvider();
const twitterAuthProvider = new firebase.auth.TwitterAuthProvider();
const githubAuthProvider = new firebase.auth.GithubAuthProvider();
const messaging = getMessaging(firebaseApp);

let publicKey = '';
if(process.env.REACT_APP_WEBSITE_NAME === 'CARRYOUT'){
	publicKey = 'BP5r2otbXiFCld1OtuSV9SZ8uFuMY3m2DIZ2p5qCWAuH8ZxVAERiWqVdr7R7Hz8qsUSuOBxGTYIlbL1GvZV3bR4';
}

if(process.env.REACT_APP_WEBSITE_NAME === 'FLYORDER'){
	publicKey = 'BNBhQszvDgaum4XgpbSM14uLszcvdWGm5oFQxhAiXmv2TQhEAd8dCEu_FRguaz3pidhSv1fzOJTV0LrWMTpYM9c';
}


const addDeviceToken = () => {
	if(!firebase.messaging){
		setTimeout(() => {addDeviceToken()}, 1000)
		return
	}
	firebase.messaging().getToken({vapidKey: publicKey}).then((currentToken) => {
		if (currentToken) {
			const link = `${interceptor.conf.baseURL}/api/add-device-token`;
			const _token = localStorage.getItem('AUTH_TOKEN');
			return fetch(link, {
				method: 'POST',
				redirect: 'follow',
				body: JSON.stringify({
					'device_token': currentToken,
					'type': 'web',
					'lang': getLangCode()
				}),
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json',
					'Content-Language': getLangCode(),
					'Authorization': `Bearer ${_token}`,
					...interceptor.headers
				}
			})
				.then(response => response.json())
				.then(res => {
					if(res.message === "Unauthenticated."){
						localStorage.clear();
						document.location.href="/";
					}
				})
				.catch((e) => {
				})

		} else {
			console.log(
				"getToken", 'No Instance ID token available. Request permission to generate one.')
		}
	}).catch((e) => {

	})
}

const _token = localStorage.getItem('AUTH_TOKEN');

if(_token){
		addDeviceToken()
}

const onMessageListener = () => {
	return new Promise((resolve, reject) => {
		onMessage(firebase.messaging(), (payload) => {
			resolve(payload)
		});
	})
}


export {
	db,
	auth,
	currentUser,
	googleAuthProvider,
	facebookAuthProvider,
	twitterAuthProvider,
	githubAuthProvider,
	firebaseApp,
	onMessageListener,
	addDeviceToken
};


