import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Link } from 'react-router-dom';
import {Image,Media} from 'react-bootstrap';
import Icofont from 'react-icofont';
import {Trans} from "react-i18next";
import RateOrderModal from "../modals/RateOrderModal";
import {useDispatch, useSelector} from "react-redux";
import {CART} from "../../redux/constants/cart";
import dayjs from "dayjs";
const OrderCard = (props) =>  {
	const [state, setState] = useState({
		showRateOrder: false,
	})
	const settings = useSelector((s) => s.mainInformation.settings.data);
	const dispatch = useDispatch()


	return (
		<div className="bg-white card mb-4 order-list shadow-sm">
			<div className="gold-members p-4">
				<Media>
					<Image className="mr-4" src={props.image} alt={props.imageAlt} />
					<Media.Body>

						{props?.status?.id === 7 ?
							(
								<>
									 <span className="float-right text-success">
										 <Icofont icon="check-circled" className="text-success mx-1" />
										 <Trans>Delivered on</Trans> |
										 <span className={'date-format mx-1'}>
											 {dayjs(props.deliveredDate.created_at).format('YYYY MMMM DD, hh:mm A')}
										 </span>
									</span>
								</>
							)
							:""
						}

						{props.orderDate && props?.status?.id !== 7?
							(
								<>
									<span className="float-right text-info">
										<Icofont icon="hand-up" className="text-info mx-1" />
										<Trans>Order on</Trans> |
										<span className={'date-format mx-1'}>
											 {dayjs(props.orderDate).format('YYYY MMMM DD, hh:mm A')}
										 </span>
									</span>
								</>
							)
							:""
						}
						<h6 className="mb-2">
							<Trans>ORDER</Trans> #{props.orderNumber}
							{
								props.status ? (
									<span className="mr-1 badge" style={{
										backgroundColor: `${props.status.color}`,
										color: `#ffffff`,
										margin: '0 10px'
									}}>{props.status.name}</span>
								) : ('')
							}
						</h6>
						{
							props.address ? (
								<p className="text-gray mb-1">
									<Icofont icon="location-arrow" /> {props.address} {props.address_desc ? ` - ${props.address_desc}` : ''}
								</p>
							) : ('')
						}


						<p className="text-gray mb-3">
							<Icofont icon="list" className="mt-1" /> <span className={'mx-1'}><Trans>ORDER</Trans> #{props.orderNumber}</span>
							<Icofont icon="clock-time" className="ml-2 mr-2 mt-1" />
							<span className={'date-format'}>
								 {dayjs(props.orderDate).format('YYYY MMMM DD, hh:mm A')}
							 </span>
						</p>
						<p className="text-dark">
							{props.orderProducts}
						</p>
						<hr />
						<div className="float-right btn-actions">
							<Link className="btn btn-sm btn-outline-primary mr-1" to={'/contact-us'}><Icofont icon="headphone-alt" /> <Trans>CONTACT US</Trans></Link>

							{
								props?.status?.id === 7 ? (
									<>
										{
											!props.rate ? (
												<Link className="btn btn-sm btn-primary" onClick={() => setState((s) => ({...s, showRateOrder: true}))} ><Icofont icon="star" /> <Trans>RATE</Trans></Link>
											) : (
												<Link className="btn btn-sm btn-primary" onClick={() => {}} ><Icofont icon="star" /> {props.rate}</Link>
											)
										}
									</>
								) : ('')
							}
							<Link className="btn btn-sm btn-primary" to={props.detailLink}><Icofont icon="list" /> <Trans>Detail</Trans></Link>
						</div>
						<p className="mb-0 text-black text-primary pt-2 order-card-total-paid">
							<span className="text-black font-weight-bold"> <Trans>Total Paid</Trans>:</span> {Number(props.orderTotal).toFixed(2)} {settings?.currency_name}
						</p>
					</Media.Body>
				</Media>

				<RateOrderModal show={state.showRateOrder} onHide={() => {
					setState((s) => ({...s, showRateOrder: false}))
					dispatch({type: CART.UPDATE_ORDERS})
				}} id={props.orderNumber} />
			</div>
		</div>
	);
}

OrderCard.propTypes = {
  image: PropTypes.string.isRequired,
  imageAlt: PropTypes.string,
  orderNumber: PropTypes.string.isRequired,
  orderDate: PropTypes.string.isRequired,
  deliveredDate: PropTypes.string,
  orderTitle: PropTypes.string.isRequired,
  address: PropTypes.string.isRequired,
  orderProducts: PropTypes.string.isRequired,
  helpLink: PropTypes.string.isRequired,
  detailLink: PropTypes.string,
  orderTotal: PropTypes.string.isRequired,
};
export default OrderCard;
