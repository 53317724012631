import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {Row, Col, Container, Spinner} from 'react-bootstrap';
import Icofont from 'react-icofont';
import {useSelector} from "react-redux";
import {GoogleApiWrapper} from "google-maps-react";
import {Trans, useTranslation} from "react-i18next";
import ShowMoreText from "react-show-more-text";


const AboutUs = (props) => {
	const settings = useSelector((s) => s.mainInformation.settings.data);
	const {data} = useSelector((s) => s.branches.branches);
	const [state, setState] = useState({
		latitude: null,
		longitude: null,
		data: null
	})
	const {t} = useTranslation();
	const branch_active = localStorage.getItem('branch_active')
	const branch = useSelector((s) => {

		if(branch_active && s.branches.branches?.data?.filter((i) => i?.id == branch_active)?.length > 0){
			return s.branches.branches?.data?.filter((i) => i?.id == branch_active)[0]
		}
		if((!branch_active || !s.branches.branches?.data?.map((i) => i?.id).includes(branch_active)) && s?.branches?.branches?.data?.length > 0){
			return s?.branches?.branches?.data[0]
		}


	});

	useEffect(() => {

		if(data && data.length > 0){
			setState((s) => ({...s, latitude: branch?.latitude, longitude: branch?.longitude, data: branch}))
		}
		return () => {
			setState((s) => ({...s, latitude: null, longitude: null}))
		}
	}, [data, branch])


	const desktopStyle = window.innerWidth > 991 ? {
		top: '110px',
		maxHeight: 'calc(100vh - 110px)',
	} : {
		top: 0,
		position: 'absolute',
		minHeight: '100vh',
	}

	return (
		<section className="section bg-white osahan-track-order-page position-relative" >
			<div className="contact-us-map" id={'branch_map'}
				 style={{

					 height: '100%',
					 width: '100vw',
					 backgroundImage: `${settings ? `url('${window.innerWidth < 991 ? settings.background_mobile : settings.background_web}')` : ''}`,
					 backgroundRepeat: 'no-repeat',
					 backgroundSize: '100% 100%',
					 backgroundPosition: 'center',
					 bottom: '0',
					 right: '0',
					 left: '0',
					 position: 'fixed',
					 ...desktopStyle
			}}>
				{/*{*/}
				{/*	(state.latitude && state.longitude) && (*/}
				{/*		<Map ref={mapRef} google={props.google}*/}
				{/*				 zoom={15}*/}
				{/*				 initialCenter={{lat: state.latitude, lng: (dir === 'rtl' ? state.longitude - 0.009 : state.longitude + 0.009)}}*/}
				{/*				 center={{lat: state.latitude, lng: (dir === 'rtl' ? state.longitude - 0.009 : state.longitude + 0.009)}}*/}
				{/*				 style={{width: '100%', height: '100%', position: 'relative'}}*/}
				{/*		>*/}
				{/*			<Marker*/}
				{/*				position={{lat: state.latitude, lng: state.longitude}} />*/}
				{/*		</Map>*/}
				{/*	)*/}
				{/*}*/}
			</div>
			<Container className="pt-5 pb-5">
				<Row className="d-flex align-items-center">
					<Col md={3} className="text-center pb-4">
						{/*<div className="osahan-point mx-auto"></div>*/}
					</Col>
					<Col md={6}>
						<div className="bg-white p-4 pb-3 shadow-lg mb-2">
							<h3 className="mb-2 text-center">{settings?.app_name}</h3>
							<h6 className="mb-1 mt-5 text-center">
								<ShowMoreText
									lines={3}
									more={t('Show More')}
									less={t('Hide More')}
									className="about-us-text"
									anchorClass=""
									onClick={() => {}}
									expanded={false}
									width={600}
									truncatedEndingComponent={"... "}
								>
									{settings?.about_us}
								</ShowMoreText>
								<div className={'text-center mt-3'}>
									<Link className="font-weight-bold" to="/contact-us"><Icofont icon="headphone-alt"/> HELP</Link>
								</div>
							</h6>
						</div>
						<div className="bg-white p-4 shadow-lg mt-2">
							<Row className="text-center">
								{
									state?.data?.mobile && (
										<Col>
											<Icofont icon="phone" className="icofont-3x text-info"/>
											<p className="mt-1 font-weight-bold text-dark text-center mb-0">{state.data.mobile}</p>
											<small className="text-info mb-0"><Trans>CALL</Trans></small>
										</Col>
									)
								}
								{
									state?.data?.email && (
										<Col>
											<Icofont icon="email" className="icofont-3x text-success"/>
											<p className="mt-1 font-weight-bold text-dark  text-center mb-0">{state.data.email}</p>
											<small className="text-success mb-0"><Trans>E-MAIL</Trans></small>
										</Col>
									)
								}
								{
									state?.data?.address && (
										<Col>
											<Icofont icon="pin" className="icofont-3x text-primary"/>
											<p className="mt-1 font-weight-bold text-dark  text-center mb-0">{state.data.address}</p>
											<small className="text-primary mb-0"><Trans>ADDRESS</Trans></small>
										</Col>
									)
								}
							</Row>
						</div>
					</Col>
					<Col md={3} className="text-center pb-4">
						{/*<div className="osahan-point mx-auto"></div>*/}
					</Col>
				</Row>
			</Container>
		</section>
	);
}


const LoadingContainer = () => (
	<section className="section bg-white osahan-track-order-page position-relative">
		<div className="position-absolute contact-us-map" id={'branch_map'} style={{
			minHeight: '100vh',height: '100%', width: '100vw',
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
		}}>
			<Spinner animation="grow" size="lg" className='mx-1 my-1' />
		</div>
	</section>
)

const debug = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';
let opt = { }

if(process.env.REACT_APP_WEBSITE_NAME === 'CARRYOUT'){
	opt = {
		apiKey: 'AIzaSyCkxkHGSSdW5V6rAuCZynQ3Cst0RF8kgZM'
	}
}

if(process.env.REACT_APP_WEBSITE_NAME === 'FLYORDER'){
	opt = {
		apiKey: 'AIzaSyCkxkHGSSdW5V6rAuCZynQ3Cst0RF8kgZM'
	}
}
export default GoogleApiWrapper(opt)(AboutUs)
