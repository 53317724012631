import React, {useRef, useState} from 'react';
import {Form, Modal, Button, Alert} from 'react-bootstrap';
import {useDispatch, useSelector} from "react-redux";
import interceptor from '../../services/interceptors/default-interceptor';
import {getLangCode} from "../../services/getLangCode";
import {AUTH} from "../../redux/constants/auth";
import axios from "axios";
import {Trans, useTranslation} from "react-i18next";

const EditProfileModal = (props) => {
	const {t} = useTranslation()
	const nameRef = useRef();
	const lastNameRef = useRef(null);
	const emailRef = useRef();
	const user = useSelector((s) => s.auth.user);
	const [state, setState] = useState({
		error: null,
		loading: false
	});
	const dispatch = useDispatch()

	const edit = (event) => {

		event.preventDefault();
		event.stopPropagation();

		setState((s) => ({...s, error: null}))
		if (!nameRef.current.value || nameRef.current.value === '' || !emailRef.current.value || emailRef.current.value === '') {
			setState((s) => ({...s, error: t('All fields are required')}))
			return
		}
		const _token = localStorage.getItem('AUTH_TOKEN');
		const link = `${interceptor.conf.baseURL}/api/profile`;
		setState((s) => ({...s, loading: true}))
		return axios.post(link, {
			name: nameRef.current.value,
			last_name: lastNameRef.current.value,
			email: emailRef.current.value,
		}, {
			headers: {
				'Content-Language': getLangCode(),
				'Authorization': `Bearer ${_token}`,
				...interceptor.headers
			}
		})
			.then(function (response) {
				setState((s) => ({...s, loading: false}))
				dispatch({type: AUTH.UPDATE_PROFILE, payload: response.data})
				props.onHide()
			})
			.catch(function (error) {
				setState((s) => ({...s, error: t('Verify that the data is correct'), loading: false}))
			});
	}

	return (
		<Modal
			show={props.show}
			onHide={props.onHide}
			size="sm"
			centered
		>
			<Modal.Header closeButton={true}>
				<Modal.Title as='h5' id="edit-profile"><Trans>Edit profile</Trans></Modal.Title>
			</Modal.Header>

			<Modal.Body>
				<Form>
					<div className="form-row">
						<Form.Group className="col-md-12">
							<Form.Label><Trans>First Name</Trans></Form.Label>
							<Form.Control type="text" defaultValue={user?.metadata?.name}  placeholder={t('Enter Name')} ref={nameRef}/>
						</Form.Group>

						<Form.Group className="col-md-12">
							<Form.Label><Trans>Last Name</Trans></Form.Label>
							<Form.Control type="text" defaultValue={user?.metadata?.last_name}  placeholder={t('Enter Name')} ref={lastNameRef}/>
						</Form.Group>

						<Form.Group className="col-md-12">
							<Form.Label><Trans>Phone number</Trans></Form.Label>
							<Form.Control type="text" defaultValue={user.phoneNumber} placeholder={t('Enter Phone number')} readOnly={true}/>
						</Form.Group>
						<Form.Group className="col-md-12">
							<Form.Label><Trans>Email</Trans></Form.Label>
							<Form.Control type="email" defaultValue={user?.metadata?.email} placeholder={t('Enter Email')} ref={emailRef}/>
						</Form.Group>
					</div>
				</Form>
				{
					state.error ? (
						<Alert variant="danger" role="alert">
							{state.error}
						</Alert>
					) : <></>
				}
			</Modal.Body>

			<Modal.Footer className={'edit-profile-footer'}>
				<Button type='button' onClick={props.onHide} variant="outline-primary" className="d-flex text-center justify-content-center"><Trans>CANCEL</Trans></Button>
				<Button type='button' variant="primary" onClick={edit} className='d-flex text-center justify-content-center'>
					{
						state.loading ? <Trans>loading</Trans> : <Trans>UPDTAE</Trans>
					}
				</Button>
			</Modal.Footer>
		</Modal>
	);
}
export default EditProfileModal;
