import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import {Image} from 'react-bootstrap';
import {useDispatch, useSelector} from "react-redux";
import {CART} from "../../redux/constants/cart";
import ProductModal from "../modals/ProductModal";


const ProductBox = ({disabled= false, ...props}) => {
  const dispatch = useDispatch();
  const settings = useSelector((s) => s.mainInformation.settings.data);
  const [state, setState] = useState({
    quantity: props.qty || 0,
    show: props.show || true,
    max:props.maxValue || 5,
    min:props.minValue || 0,
    showModal: false,
    error: null
  })

  const openModal = () => {
    if(!disabled){
      setState((s) => ({
        ...s,
        showModal: true
      }));
    }
  }

  const closeModal = () => {
    setState((s) => ({
      ...s,
      showModal: false
    }));
  }

  const addItemToCart = (val) => {
    setState((s) => ({...s,  ...val, error: null}));
    dispatch({type: CART.ADD_TO_CART, payload: val, callback: (res) => {
        closeModal()
        setState((s) => ({...s, error: null}));
      }, callbackError: (err) => {
        setState((s) => ({...s, error: Object.values(err.data.errors).join(', ')}));
      }})
  }


  const content = () => {
    return (
      <>
        <Image src={props.image} className={props.imageClass} alt={props.imageAlt} />
        {props.title?
          <h6 className={!props.is_sale ? 'mt-10' : ''}>{props.title}</h6>
          :""
        }
        {props.counting ?
          <>
            {
              !props.is_sale && (
                    <p>
                      {props.counting} {props.priceUnit}
                    </p>
                )
            }

            {
                props.is_sale && (
                    <p style={(Number(props.sale_price)?.toFixed(2)?.length > 6 || Number(props.price)?.toFixed(2)?.length > 6) ? {flexDirection: 'column'} : {}}
                       data-sale_price={Number(props.sale_price).toFixed(2).length}
                       data-price={Number(props.price).toFixed(2).length}>
                      <span>{`${Number(props.sale_price).toFixed(2)} ${props.priceUnit}`}</span>
                      <span className={'sale-price'} style={{fontSize: '12px'}}>
                        <i />
                        {`${Number(props.price).toFixed(2)} ${props.priceUnit}`}
                      </span>
                    </p>
                )
            }
          </>
          :""
        }
      </>
    )
  }


  return (
    <>
      <div className={props.boxClass}
           style={{cursor: !props.linkUrl && props.linkUrl !== '#' ? 'pointer' : ''}}
           onClick={() => {
             if(!props.linkUrl && props.linkUrl !== '#'){
               openModal()
             }
           }}
      >
        <div style={{backgroundImage: `url(${props.image})`}}/>
        {
          props.linkUrl && props.linkUrl !== '#' ? (
            <Link to={props.linkUrl}>
              {content()}
            </Link>

          ) : content()
        }
      </div>

      {
        !props.linkUrl && props.linkUrl !== '#' && (
          <ProductModal {...props} show={state.showModal} is_open={props.is_open} onHide={() => {
            setState({
              showModal: false
            });
            if(props.callback) {
              props.callback()
            }
          }} addItemToCart={addItemToCart} is_wishlist={props.is_wishlist} available={props.available} error={state.error} />
        )
      }
    </>
  );
}



export default ProductBox;
