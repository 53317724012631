export const AUTH = {
  USER: 'USER',
  SIGNIN: 'SIGNIN',
  SIGNUP: 'SIGNUP',
  CHECK_AUTH: 'CHECK_AUTH',
  SIGNOUT: 'SIGNOUT',
  SHOW_LOADING: 'SHOW_LOADING',
  AUTH_TOKEN: 'AUTH_TOKEN',
  SHOW_AUTH_MESSAGE: 'SHOW_AUTH_MESSAGE',
  HIDE_AUTH_MESSAGE: 'HIDE_AUTH_MESSAGE',
  AUTHENTICATED: 'AUTHENTICATED',
  SIGNOUT_SUCCESS: 'SIGNOUT_SUCCESS',
  SIGNUP_SUCCESS: 'SIGNUP_SUCCESS',
  SIGNIN_WITH_GOOGLE_AUTHENTICATED: 'SIGNIN_WITH_GOOGLE_AUTHENTICATED',
  SIGNIN_WITH_FACEBOOK_AUTHENTICATED: 'SIGNIN_WITH_FACEBOOK_AUTHENTICATED',
  SIGNIN_WITH_FACEBOOK: 'SIGNIN_WITH_FACEBOOK',
  SIGNIN_WITH_GOOGLE: 'SIGNIN_WITH_GOOGLE',
  VERIFICATION: 'VERIFICATION',
  VERIFICATION_CODE: 'VERIFICATION_CODE',
  VERIFICATION_ID: 'VERIFICATION_ID',
  UPDATE_PROFILE: 'UPDATE_PROFILE',
  UPDATE_PROFILE_DATE: 'UPDATE_PROFILE_DATE'
}
