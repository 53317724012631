import { all, takeEvery, put, fork, call, select } from 'redux-saga/effects';
import {AUTH} from '../constants/auth';
import FirebaseService from '../../services/auth/FirebaseService'
import {addDeviceToken} from "../../services/auth/FirebaseAuth";


export function* signUpWithPhone() {
  yield takeEvery(AUTH.SIGNUP, function* ({payload}) {
    const {phoneNumber, appVerifier, callback} = payload;
    try {
      const {confirm, verificationId} = yield call(FirebaseService.signInWithPhoneNumber, phoneNumber, appVerifier);
      yield call(callback, verificationId, confirm)
      localStorage.setItem(AUTH.VERIFICATION_ID, verificationId);
      yield put({
        type: AUTH.AUTHENTICATED,
      });
    } catch (err) {
      yield put({
        type: AUTH.SHOW_AUTH_MESSAGE,
        message: err
      });
    }
  });
}


export function* verification() {
  yield takeEvery(AUTH.VERIFICATION_CODE, function* ({payload}) {
    try {
      localStorage.setItem(AUTH.USER, JSON.stringify(payload));
      localStorage.setItem('AUTH_TOKEN', payload.token);

      yield put({
        type: AUTH.AUTHENTICATED,
        token: payload.token,
        user: payload
      });
      yield call(addDeviceToken)
    } catch (err) {
      yield put({
        type: AUTH.SHOW_AUTH_MESSAGE,
        message: err
      });
    }
  });
}

export function* checkAuth() {
  const user = localStorage.getItem(AUTH.USER);
  const token = localStorage.getItem('AUTH_TOKEN');
  yield takeEvery(AUTH.CHECK_AUTH, function* () {
    try {
      yield put({
        type: AUTH.AUTHENTICATED,
        token: token,
        user: JSON.parse(user)
      });

    } catch (err) {
      yield put({
        type: AUTH.SHOW_AUTH_MESSAGE,
        message: err
      });
    }
  });
}


export function* updateProfile() {
  yield takeEvery(AUTH.UPDATE_PROFILE, function* ({payload}){
    try {
      if(payload.data){
        yield put({type: AUTH.UPDATE_PROFILE_DATE, payload: payload.data})
        const user = yield select(s => s.auth.user);

        localStorage.setItem(AUTH.USER, JSON.stringify(user));
      }
    }catch(e) {
    }
  })
}
export function* signOut() {
  yield takeEvery(AUTH.SIGNOUT, function* () {
    try {
      const signOutUser = yield call(FirebaseService.signOutRequest);

      if (signOutUser === undefined) {
        localStorage.removeItem(AUTH.USER);
        localStorage.removeItem('AUTH_TOKEN');

        yield put({
          type: AUTH.AUTHENTICATED,
          token: null,
          user: {},
        });

        yield put({
          type: AUTH.SIGNOUT_SUCCESS,
          payload: signOutUser
        });

      } else {
        yield put({
          type: AUTH.SHOW_AUTH_MESSAGE,
          message: signOutUser.message
        });

      }
    } catch (err) {
      yield put({
        type: AUTH.SHOW_AUTH_MESSAGE,
        message: err
      });
    }
  });
}


export default function* rootSaga() {
  yield all([
    fork(signUpWithPhone),
    fork(signOut),
    fork(verification),
    fork(checkAuth),
    fork(updateProfile)
  ]);
}
